import {
  RawMaterialCategory,
  WarehouseRawMaterialInventoryReportItemWhereInput,
  useWarehouseRawMaterialInventoryReportItemsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateFilter from '../common/DateFilter';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const WarehouseRawMaterialInventoryReportItemsPage = ({
  title = 'Warehouse Raw Material Inventory Report',
}) => {
  const [searchQueryString, monthYearQueryString] = useGetQueryString([
    'search',
    'monthYear',
  ]);

  const where: WarehouseRawMaterialInventoryReportItemWhereInput =
    Object.assign(
      {},
      typeof monthYearQueryString === 'string'
        ? {
            monthYear: monthYearQueryString,
          }
        : {},

      typeof searchQueryString === 'string'
        ? {
            searchTerm: searchQueryString,
          }
        : {}
    );

  const variables = {
    where,
  };

  const {
    data: { warehouseRawMaterialInventoryReportItems } = {},
    loading,
    error,
  } = useWarehouseRawMaterialInventoryReportItemsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !where?.monthYear,
  });

  const columns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof warehouseRawMaterialInventoryReportItems>
      >
    >
  > = [
    {
      header: 'Category',
      accessor: 'category',
      Cell: (children) => getEnumKeyFromValue(RawMaterialCategory, children),
    },
    {
      header: 'Raw Material',
      accessor: 'rawMaterial',
    },

    {
      header: 'Unit',
      accessor: 'unit',
    },
    {
      header: 'Beginning',
      accessor: 'beginningQuantity',
    },
    {
      header: 'Purchases',
      accessor: 'purchaseQuantity',
    },
    {
      header: 'Transfer In',
      accessor: 'transferInQuantity',
    },
    {
      header: 'Transfer Out',
      accessor: 'transferOutQuantity',
    },
    {
      header: 'Released',
      accessor: 'releasedQuantity',
    },
    {
      header: 'Throw Away',
      accessor: 'throwAwayQuantity',
    },
    {
      header: 'Should Be',
      accessor: 'shouldBeQuantity',
    },
    {
      header: 'Actual Ending',
      accessor: 'actualEndingQuantity',
    },
    {
      header: 'Variance',
      accessor: 'variance',
    },
    {
      header: 'Fin Ending',
      accessor: 'finEndingQuantity',
    },
    {
      header: 'Empties',
      accessor: 'emptiesQuantity',
    },
  ];

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      filters={
        <>
          <DateFilter
            inputFormat="MMM yyyy"
            views={['month', 'year']}
            dateField="monthYear"
            label="Month Year"
            formatString="yyyy-MM"
          />
          <Search />
        </>
      }
      dataTable={
        <DataTable
          data={warehouseRawMaterialInventoryReportItems}
          columns={columns}
        />
      }
    />
  );
};

export default WarehouseRawMaterialInventoryReportItemsPage;
