import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateReceivedSdsDeliveryReceiptDocument,
  PackagingCategory,
  QueryMode,
  RawMaterialCategory,
  ReceivedSdsDeliveryReceiptsDocument,
  ReceivedSdsDeliveryReceiptWhereInput,
  SortOrder,
  useDeleteManyReceivedSdsDeliveryReceiptMutation,
  useReceivedSdsDeliveryReceiptsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const ReceivedSdsDeliveryReceiptsPage = ({
  title = 'Received SDS Delivery Receipts',
}) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: ReceivedSdsDeliveryReceiptWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          dateTime: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              receivedSdsDeliveryReceiptRawMaterialItems: {
                some: {
                  sdsDeliveryReceiptRawMaterialItem: {
                    is: {
                      rawMaterialProduct: {
                        is: {
                          rawMaterial: {
                            is: {
                              name: {
                                contains: searchQueryString,
                                mode: QueryMode.Insensitive,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              receivedSdsDeliveryReceiptPackagingItems: {
                some: {
                  sdsDeliveryReceiptPackagingItem: {
                    is: {
                      packagingProduct: {
                        is: {
                          packaging: {
                            is: {
                              name: {
                                contains: searchQueryString,
                                mode: QueryMode.Insensitive,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              sdsDeliveryReceipt: {
                is: {
                  outlet: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              receivedBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              sdsDeliveryReceipt: {
                is: {
                  controlNumber: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        dateTime: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { receivedSdsDeliveryReceipts } = {},
    loading,
    error,
  } = useReceivedSdsDeliveryReceiptsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyReceivedSdsDeliveryReceipt] =
    useDeleteManyReceivedSdsDeliveryReceiptMutation({
      refetchQueries: [
        {
          query: AggregateReceivedSdsDeliveryReceiptDocument,
          variables: countVariables,
        },
        {
          query: ReceivedSdsDeliveryReceiptsDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Delete' + title.toLowerCase() + ' error! ' + error,
          severity: 'error',
        }),
    });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof receivedSdsDeliveryReceipts>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date Time',
      accessor: 'dateTime',
      Cell: (children) => dateTimeToString(children, 'MM/dd/yyyy h:mm:ss aaa'),
    },
    {
      header: 'Outlet',
      accessor: 'sdsDeliveryReceipt.outlet.name',
    },
    {
      header: 'Control Number',
      accessor: 'sdsDeliveryReceipt.controlNumber',
    },
    {
      header: 'Received By',
      accessor: 'receivedBy',
    },
    {
      header: 'Packaging',
      accessor: 'receivedSdsDeliveryReceiptPackagingItems',
      Cell: (children: {}[], index: number) => {
        const receivedsdsDeliveryReceiptPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof receivedSdsDeliveryReceipts>
              >['receivedSdsDeliveryReceiptPackagingItems']
            >
          >
        > = [
          {
            header: 'Category',
            accessor:
              'sdsDeliveryReceiptPackagingItem.packagingProduct.packaging.packagingCategory',
            Cell: (children) =>
              getEnumKeyFromValue(PackagingCategory, children),
          },
          {
            header: 'Packaging',
            accessor:
              'sdsDeliveryReceiptPackagingItem.packagingProduct.packaging.name',
          },
          {
            header: 'Quantity',
            accessor: 'sdsDeliveryReceiptPackagingItem.quantity',
          },
          {
            header: 'Unit',
            accessor:
              'sdsDeliveryReceiptPackagingItem.packagingProduct.unit.name',
          },
          {
            header: 'Received',
            accessor: 'receivedQuantity',
          },
          {
            header: 'Rejected',
            accessor: 'rejectedQuantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={receivedsdsDeliveryReceiptPackagingItemsColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material',
      accessor: 'receivedSdsDeliveryReceiptRawMaterialItems',
      Cell: (children: {}[], index: number) => {
        const receivedsdsDeliveryReceiptRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof receivedSdsDeliveryReceipts>
              >['receivedSdsDeliveryReceiptRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Category',
            accessor:
              'sdsDeliveryReceiptRawMaterialItem.rawMaterialProduct.rawMaterial.rawMaterialCategory',
            Cell: (children) =>
              getEnumKeyFromValue(RawMaterialCategory, children),
          },
          {
            header: 'Raw Material',
            accessor:
              'sdsDeliveryReceiptRawMaterialItem.rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Quantity',
            accessor: 'sdsDeliveryReceiptRawMaterialItem.quantity',
          },
          {
            header: 'Unit',
            accessor:
              'sdsDeliveryReceiptRawMaterialItem.rawMaterialProduct.unit.name',
          },
          {
            header: 'Received',
            accessor: 'receivedQuantity',
          },
          {
            header: 'Rejected',
            accessor: 'rejectedQuantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={receivedsdsDeliveryReceiptRawMaterialItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyReceivedSdsDeliveryReceipt({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={
        <DataTable data={receivedSdsDeliveryReceipts} columns={columns} />
      }
      pagination={
        <Pagination
          query={AggregateReceivedSdsDeliveryReceiptDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default ReceivedSdsDeliveryReceiptsPage;
