import { z } from 'zod';
import { DeliveryShiftSchedule } from '../../graphql/enums/DeliveryShiftSchedule';


export const schema = z.object({
  date: z.coerce.date(),
  receivedBy: z.string().nullish(),
  deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
  transfer: z.object({ 
    id: z.coerce.number(),
  }).transform((val) => !val ? null : val),
  fromOutlet: z.object({ 
    id: z.coerce.number(),
  }).transform((val) => !val ? null : val).nullish(),
  toOutlet: z.object({ 
    id: z.coerce.number(),
  }).transform((val) => !val ? null : val).nullish(),
  receivedTransferCmlItems: z.array(z.object({
    id: z.coerce.number().optional(),
    transferCmlItem: z.object({ 
      id: z.coerce.number(),
      quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
      cmlProduct: z.object({ 
        id: z.coerce.number(),
        name: z.string().trim().min(1),
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val)
      }).transform((val) => !val ? null : val),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  receivedTransferRawMaterialItems: z.array(z.object({
    id: z.coerce.number().optional(),
    transferRawMaterialItem: z.object({ 
      id: z.coerce.number(),
      quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
      rawMaterialProduct: z.object({ 
        id: z.coerce.number(),
        rawMaterial: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val)
      }).transform((val) => !val ? null : val),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  receivedTransferPackagingItems: z.array(z.object({
    id: z.coerce.number().optional(),
    transferPackagingItem: z.object({ 
      id: z.coerce.number(),
      quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
      packagingProduct: z.object({ 
        id: z.coerce.number(),
        packaging: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val)
      }).transform((val) => !val ? null : val),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  }))
})

export type FormData = z.infer<typeof schema>




