import { eachDayOfInterval, endOfMonth, format, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import {
  DeliveryShiftSchedule,
  DonutEndingConsolidationWhereInput,
  useDonutEndingConsolidationsQuery,
  useOutletsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateFilter from '../common/DateFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import SearchFilter from '../common/SearchFilter';
import ListView from '../templates/ListView';

const DonutEndingConsolidationsPage = ({
  title = 'Donut Ending Consolidation Report',
}) => {
  const [
    monthYearQueryString,
    deliveryShiftScheduleQueryString,
    outletIdQueryString,
  ] = useGetQueryString(['monthYear', 'deliveryShiftSchedule', 'outletId']);

  const where: DonutEndingConsolidationWhereInput = Object.assign(
    {},
    deliveryShiftScheduleQueryString
      ? {
          deliveryShiftSchedule:
            deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
        }
      : {},
    typeof monthYearQueryString === 'string'
      ? {
          monthYear: startOfMonth(new Date(monthYearQueryString)),
        }
      : {},
    typeof outletIdQueryString === 'string'
      ? {
          outletId: +outletIdQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const days = useMemo(() => {
    if (typeof monthYearQueryString === 'string') {
      return eachDayOfInterval({
        start: startOfMonth(new Date(monthYearQueryString)),
        end: endOfMonth(new Date(monthYearQueryString)),
      });
    } else {
      return [];
    }
  }, [monthYearQueryString]);

  const {
    data: { donutEndingConsolidations } = {},
    loading,
    error,
  } = useDonutEndingConsolidationsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip:
      !monthYearQueryString ||
      !deliveryShiftScheduleQueryString ||
      !outletIdQueryString,
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof donutEndingConsolidations>>>
  > = [
    {
      header: 'Breakdown',
      accessor: 'cmlProducts',
      Cell: (children: {}[], index: number) => {
        const cmlProductsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof donutEndingConsolidations>
              >['cmlProducts']
            >
          >
        > = [
          {
            header: 'CML Product',
            accessor: 'cmlProduct',
          },
          // {
          //   header: '',
          //   accessor: 'cmlProduct',
          //   Cell: (children) => (
          //     <Typography sx={{ visibility: 'hidden' }}>CML Product</Typography>
          //   ),
          // },
          ...days.map((day) => {
            return {
              header: format(day, 'yyyy-MM-dd'),
              accessor:
                'perCmlProductDailyEndings' as 'perCmlProductDailyEndings',
              Cell: (children: any) =>
                children?.find(
                  (child: any) => child.name === format(day, 'yyyy-MM-dd')
                )?.quantity,
            };
          }),
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={cmlProductsColumns}
          />
        );
      },
    },
    {
      header: 'Donut Mix and Cut',
      accessor: 'donutMixAndCut',
    },
    ...days.map((day) => {
      return {
        header: format(day, 'yyyy-MM-dd'),
        accessor: 'cmlProducts' as 'cmlProducts',
        Cell: (children: any) =>
          children.reduce((acc: any, cur: any) => {
            return (
              acc +
              cur.perCmlProductDailyEndings.reduce((acc: any, cur: any) => {
                return acc + cur.quantity;
              }, 0)
            );
          }, 0),
      };
    }),
  ];
  const { data: { outlets } = {}, loading: outletsLoading } = useOutletsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <ListView
        loading={loading || outletsLoading}
        error={error}
        title={title}
        filters={
          <>
            <DateFilter
              inputFormat="MMM yyyy"
              views={['month', 'year']}
              dateField="monthYear"
              label="Month Year"
              formatString="yyyy-MM"
            />
            <EnumSearchFilter
              label="Delivery Shift Schedule"
              field="deliveryShiftSchedule"
              enumObj={DeliveryShiftSchedule}
            />
            <SearchFilter
              label="Outlet"
              field="outletId"
              arr={outlets}
              setAccessor={(value) => value.name}
              setValue={(value) => value.id}
            />
          </>
        }
        dataTable={
          <DataTable data={donutEndingConsolidations} columns={columns} />
        }
      />
    </>
  );
};

export default DonutEndingConsolidationsPage;
