import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  usePackagingRequestQuery,
  useUpdatePackagingRequestMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import PackagingRequestForm from './PackagingRequestForm';
import { FormData, schema } from './utils';

const UpdatePackagingRequestPage = ({ title = `Update Packaging Request` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ packagingRequestItems, outlet, ...data }) => {
        const packagingRequestItemsToDelete = getItemsForDelete(
          packagingRequest?.packagingRequestItems,
          packagingRequestItems
        );
        const packagingRequestItemsToUpsert = getItemsForUpsert(
          packagingRequestItems
        );
        await updatePackagingRequest({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              packagingRequestItems: {
                delete: packagingRequestItemsToDelete,
                upsert: packagingRequestItemsToUpsert.map(
                  ({ id, packaging, unit, ...packagingRequestItem }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(packagingRequestItem),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate(packagingRequestItem),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { packagingRequest } = {}, loading } = usePackagingRequestQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    }
  );

  const [updatePackagingRequest, { loading: mutationLoading }] =
    useUpdatePackagingRequestMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (packagingRequest) {
      const { packagingRequestItems, ...data } = packagingRequest;
      methods.reset({
        ...cleanPayload({
          ...data,
          packagingRequestItems: packagingRequestItems.map(
            ({ packagingProduct, ...data }) => {
              return {
                packaging: packagingProduct?.packaging,
                unit: packagingProduct?.unit,
                ...data,
              };
            }
          ),
        }),
      });
    }
  }, [packagingRequest, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <PackagingRequestForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdatePackagingRequestPage;
