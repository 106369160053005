import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useUpdateDonutMixAndCutMutation,
  useDonutMixAndCutQuery,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import DonutMixAndCutForm from './DonutMixAndCutForm';
import { FormData, schema } from './utils';

const UpdateDonutMixAndCutPage = ({ title = `Update Donut Mix and Cut` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async (data) =>
        await updateDonutMixAndCut({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
            },
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { donutMixAndCut } = {}, loading } = useDonutMixAndCutQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateDonutMixAndCut, { loading: mutationLoading }] =
    useUpdateDonutMixAndCutMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (donutMixAndCut) {
      methods.reset({ ...cleanPayload(donutMixAndCut) });
    }
  }, [donutMixAndCut, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <DonutMixAndCutForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateDonutMixAndCutPage;
