import { z } from 'zod';
import { Status } from '../../graphql/generated/types';


export const schema = z.object({
  name: z.string().trim().min(1),
  status: z.nativeEnum(Status),
  unit: z.object({
    id: z.coerce.number()
  }).transform((val) => !val ? null : val),
  rawMaterialProduct: z.object({
    id: z.coerce.number()
  }).transform((val) => !val ? null : val),
  yield: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  ozInMedium:  z.coerce.number().transform((val) => (!val ? null : val)).nullish(),
  ozInLarge:  z.coerce.number().transform((val) => (!val ? null : val)).nullish(),
  ozInXl:  z.coerce.number().transform((val) => (!val ? null : val)).nullish(),
})

export type FormData = z.infer<typeof schema>




