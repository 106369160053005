import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  DeliveryShiftSchedule,
  PackagingCategory,
  RawMaterialCategory,
  SalesCategory,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import { FormData } from './utils';

const CrtForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  const { fields: crtCmlItemsFields } = useFieldArray({
    control,
    name: 'crtCmlItems',
  });

  const { fields: crtRawMaterialItemsFields } = useFieldArray({
    control,
    name: 'crtRawMaterialItems',
  });

  const { fields: crtPackagingItemsFields } = useFieldArray({
    control,
    name: 'crtPackagingItems',
  });

  const { fields: crtBeverageItemsFields } = useFieldArray({
    control,
    name: 'crtBeverageItems',
  });

  const [
    crtCmlItems,
    crtRawMaterialItems,
    crtPackagingItems,
    crtBeverageItems,
  ] = useWatch({
    control,
    name: [
      'crtCmlItems',
      'crtRawMaterialItems',
      'crtPackagingItems',
      'crtBeverageItems',
    ],
  });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Time"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <OutletAutocomplete
            name={`outlet`}
            schema={schema}
            control={control}
            label="Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          CML Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {crtCmlItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Sales Category ${index + 1}`}
                    fullWidth
                    value={
                      getEnumKeyFromValue(
                        SalesCategory,
                        crtCmlItems[index]?.salesCategory
                      ) ?? ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />

                  <Controller
                    name={`crtCmlItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {crtRawMaterialItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Category ${index + 1}`}
                    fullWidth
                    value={
                      getEnumKeyFromValue(
                        RawMaterialCategory,
                        crtRawMaterialItems &&
                          crtRawMaterialItems[index]?.rawMaterialProduct
                            ?.rawMaterial?.rawMaterialCategory
                      ) ?? ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Raw Material ${index + 1}`}
                    fullWidth
                    value={
                      (crtRawMaterialItems &&
                        crtRawMaterialItems[index]?.rawMaterialProduct
                          ?.rawMaterial?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (crtRawMaterialItems &&
                        crtRawMaterialItems[index]?.rawMaterialProduct?.unit
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`crtRawMaterialItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {crtPackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Category ${index + 1}`}
                    fullWidth
                    value={
                      getEnumKeyFromValue(
                        PackagingCategory,
                        crtPackagingItems &&
                          crtPackagingItems[index]?.packagingProduct?.packaging
                            ?.packagingCategory
                      ) ?? ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Packaging ${index + 1}`}
                    fullWidth
                    value={
                      (crtPackagingItems &&
                        crtPackagingItems[index]?.packagingProduct?.packaging
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (crtPackagingItems &&
                        crtPackagingItems[index]?.packagingProduct?.unit
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`crtPackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Beverages
        </Typography>
        <Stack direction="column" spacing={1}>
          {crtBeverageItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Beverage ${index + 1}`}
                    fullWidth
                    value={
                      (crtBeverageItems &&
                        crtBeverageItems[index]?.beverage?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`crtBeverageItems.${index}.cupsSoldMedium`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Cups Sold M"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Controller
                    name={`crtBeverageItems.${index}.cupsSoldLarge`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Cups Sold L"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Controller
                    name={`crtBeverageItems.${index}.cupsSoldXl`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="CupsSold XL"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default CrtForm;
