import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateDonutMixAndCutDocument,
  DonutMixAndCutsDocument,
  DonutMixAndCutWhereInput,
  QueryMode,
  SortOrder,
  useDeleteManyDonutMixAndCutMutation,
  useDonutMixAndCutsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const DonutMixAndCutsPage = ({ title = 'Donut Mix and Cuts' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: DonutMixAndCutWhereInput = Object.assign(
    {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { donutMixAndCuts } = {},
    loading,
    error,
  } = useDonutMixAndCutsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyDonutMixAndCut] = useDeleteManyDonutMixAndCutMutation({
    refetchQueries: [
      { query: AggregateDonutMixAndCutDocument, variables: countVariables },
      {
        query: DonutMixAndCutsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof donutMixAndCuts>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Name',
      accessor: 'name',
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyDonutMixAndCut({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={<Search />}
        dataTable={<DataTable data={donutMixAndCuts} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateDonutMixAndCutDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default DonutMixAndCutsPage;
