import {
  AggregateVarianceReportOnRawMaterialTransferDocument,
  DeliveryShiftSchedule,
  SortOrder,
  VarianceReportOnRawMaterialTransferWhereInput,
  useVarianceReportOnRawMaterialTransfersQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const VarianceReportOnRawMaterialTransfersPage = ({
  title = 'Variance Report On Raw Material Transfers',
}) => {
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: VarianceReportOnRawMaterialTransferWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          startDate: startDateQueryString,
          endDate: endDateQueryString,
        }
      : {},

    typeof searchQueryString === 'string'
      ? {
          searchTerm: searchQueryString,
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { varianceReportOnRawMaterialTransfers } = {},
    loading,
    error,
  } = useVarianceReportOnRawMaterialTransfersQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const columns: Columns<
    Paths<
      ObjectFromArray<NonNullable<typeof varianceReportOnRawMaterialTransfers>>
    >
  > = [
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'From Outlet',
      accessor: 'fromOutlet',
    },
    {
      header: 'To Outlet',
      accessor: 'toOutlet',
    },
    {
      header: 'Delivery Shift Schedule',
      accessor: 'deliveryShiftSchedule',
      Cell: (children) => getEnumKeyFromValue(DeliveryShiftSchedule, children),
    },
    {
      header: 'Transfer No.',
      accessor: 'transferNumber',
    },
    {
      header: 'Received By',
      accessor: 'receivedBy',
    },
    {
      header: 'Category',
      accessor: 'category',
    },
    {
      header: 'Raw Material',
      accessor: 'rawMaterial',
    },
    {
      header: 'Quantity',
      accessor: 'quantity',
    },
    {
      header: 'Unit',
      accessor: 'unit',
    },
    {
      header: 'Received Quantity',
      accessor: 'receivedQuantity',
    },
    {
      header: 'Variance',
      accessor: 'variance',
    },
  ];

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={
        <DataTable
          data={varianceReportOnRawMaterialTransfers}
          columns={columns}
        />
      }
      pagination={
        <Pagination
          query={AggregateVarianceReportOnRawMaterialTransferDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default VarianceReportOnRawMaterialTransfersPage;
