import { endOfDay, startOfDay } from 'date-fns';
import {
  AggregateProjectionDocument,
  DeliveryShiftSchedule,
  ProjectionWhereInput,
  QueryMode,
  SalesCategory,
  SortOrder,
  useProjectionSummaryPerOutletQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import EnumSearchFilter from '../common/EnumSearchFilter';

const ProjectionSummaryPerOutletPage = ({
  title = 'Projection Summary Per Outlet',
}) => {
  const { page, perPage } = usePagination();
  const [
    searchQueryString,
    startDateQueryString,
    endDateQueryString,
    deliveryShiftScheduleQueryString,
  ] = useGetQueryString([
    'search',
    'startDate',
    'endDate',
    'deliveryShiftSchedule',
  ]);

  const where: ProjectionWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          date: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    deliveryShiftScheduleQueryString
      ? {
          deliveryShiftSchedule: {
            equals: deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              projectionItems: {
                some: {
                  cmlProduct: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              createdBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              controlNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              outlet: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { projections } = {},
    loading,
    error,
  } = useProjectionSummaryPerOutletQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof projections>>>
  > = [
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Outlet',
      accessor: 'outlet.name',
    },
    {
      header: 'Delivery Shift Schedule',
      accessor: 'deliveryShiftSchedule',
      Cell: (children) => getEnumKeyFromValue(DeliveryShiftSchedule, children),
    },
    {
      header: 'Control Number',
      accessor: 'controlNumber',
    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Items',
      accessor: 'projectionSummaryItems',
      Cell: (children: {}[], index: number) => {
        const projectionItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof projections>
              >['projectionSummaryItems']
            >
          >
        > = [
          {
            header: 'Sales Category',
            accessor: 'salesCategory',
            Cell: (children) => getEnumKeyFromValue(SalesCategory, children),
          },

          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={projectionItemsColumns}
          />
        );
      },
    },
  ];

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      filters={
        <>
          <DateRangeFilter />
          <EnumSearchFilter
            label="Delivery Shift Schedule"
            field="deliveryShiftSchedule"
            enumObj={DeliveryShiftSchedule}
          />
          <Search />
        </>
      }
      dataTable={<DataTable data={projections} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateProjectionDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default ProjectionSummaryPerOutletPage;
