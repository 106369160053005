import { Box, Button, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Status, useUnitsQuery } from '../../graphql/generated/types';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import Loading from '../common/Loading';
import Select from '../common/Select';
import RawMaterialProductAutocomplete from '../shared/RawMaterialProductAutocomplete';
import { FormData } from './utils';

const BeverageForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control } = useFormContext<FormData>();

  const { data: { units } = {}, loading } = useUnitsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                sx={{ gridColumn: 'span 3' }}
                label="Name"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                disabled={type === 'update'}
              />
            )}
          />
          <EnumSelect
            name="status"
            label="Status"
            schema={schema}
            enumObj={Status}
            control={control}
            defaultValue={'' as unknown as Status}
          />
          <Select
            name="unit"
            label="Unit"
            schema={schema}
            control={control}
            arr={units || []}
            defaultValue={null}
            renderValueAs={(obj) => `${obj.name}`}
          />
          <RawMaterialProductAutocomplete
            name="rawMaterialProduct"
            schema={schema}
            control={control}
            label="Raw Material Product"
            defaultValue={null}
          />
          <Controller
            name="yield"
            control={control}
            defaultValue={undefined}
            render={({ field, fieldState }) => (
              <TextField
                label="Yield"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            )}
          />
          <Controller
            name="ozInMedium"
            control={control}
            defaultValue={undefined}
            render={({ field, fieldState }) => (
              <TextField
                label="Oz in Medium"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            )}
          />
          <Controller
            name="ozInLarge"
            control={control}
            defaultValue={undefined}
            render={({ field, fieldState }) => (
              <TextField
                label="Oz in Large"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            )}
          />
          <Controller
            name="ozInXl"
            control={control}
            defaultValue={undefined}
            render={({ field, fieldState }) => (
              <TextField
                label="Oz in XL"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            )}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default BeverageForm;
