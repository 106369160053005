import { Paper, Typography } from '@mui/material';
import {
  DeliveryShiftSchedule,
  SalesCategory,
  SalesProductControlReportItemWhereInput,
  useBeveragesSalesProductControlReportItemsQuery,
  useCmlProductsSalesProductControlReportItemsQuery,
  useOutletsQuery,
  useRawMaterialsSalesProductControlReportItemsQuery,
} from '../../graphql/generated/types';
import { overflow } from '../../styles/overflow';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateFilter from '../common/DateFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import SearchFilter from '../common/SearchFilter';
import ListView from '../templates/ListView';

const SalesProductControlReportItemsPage = ({
  title = 'Sales Product Control Report',
}) => {
  const [
    deliveryShiftScheduleQueryString,
    dateQueryString,
    outletIdQueryString,
  ] = useGetQueryString(['deliveryShiftSchedule', 'date', 'outletId']);

  const where: SalesProductControlReportItemWhereInput = Object.assign(
    {},
    typeof dateQueryString === 'string'
      ? {
          date: dateQueryString,
        }
      : {},

    deliveryShiftScheduleQueryString
      ? {
          deliveryShiftSchedule:
            deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
        }
      : {},
    typeof outletIdQueryString === 'string'
      ? {
          outletId: +outletIdQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const {
    data: { cmlProductsSalesProductControlReportItems } = {},
    loading: cmlProductsSalesProductControlReportItemsLoading,
    error: cmlProductsSalesProductControlReportItemsError,
  } = useCmlProductsSalesProductControlReportItemsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !where?.date || !where.deliveryShiftSchedule || !where.outletId,
  });

  const {
    data: { rawMaterialsSalesProductControlReportItems } = {},
    loading: rawMaterialsSalesProductControlReportItemsLoading,
    error: rawMaterialsSalesProductControlReportItemsError,
  } = useRawMaterialsSalesProductControlReportItemsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !where?.date || !where.deliveryShiftSchedule || !where.outletId,
  });

  const {
    data: { beveragesSalesProductControlReportItems } = {},
    loading: beveragesSalesProductControlReportItemsLoading,
    error: beveragesSalesProductControlReportItemsError,
  } = useBeveragesSalesProductControlReportItemsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !where?.date || !where.deliveryShiftSchedule || !where.outletId,
  });

  const cmlProductsSalesProductControlReportItemsColumns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof cmlProductsSalesProductControlReportItems>
      >
    >
  > = [
    {
      header: 'Sales Category',
      accessor: 'salesCategory',
      Cell: (children) => getEnumKeyFromValue(SalesCategory, children),
    },

    {
      header: 'Beginning',
      accessor: 'beginningQuantity',
    },
    {
      header: 'Deliveries',
      accessor: 'deliveriesQuantity',
    },
    {
      header: 'Transfer In',
      accessor: 'transferInQuantity',
    },
    {
      header: 'Transfer Out',
      accessor: 'transferOutQuantity',
    },
    {
      header: 'EM',
      accessor: 'emQuantity',
    },
    {
      header: 'SD',
      accessor: 'sdQuantity',
    },
    {
      header: 'TA',
      accessor: 'taQuantity',
    },
    {
      header: 'Ending Inventory',
      accessor: 'endingQuantity',
    },
    {
      header: 'Usage',
      accessor: 'usage',
    },
    {
      header: 'CRT Sold',
      accessor: 'crtCmlItemQuantity',
    },
    {
      header: 'Variance',
      accessor: 'variance',
    },
  ];

  const rawMaterialsSalesProductControlReportItemsColumns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof rawMaterialsSalesProductControlReportItems>
      >
    >
  > = [
    {
      header: 'Raw Material',
      accessor: 'rawMaterial',
    },
    {
      header: 'Unit',
      accessor: 'unit',
    },

    {
      header: 'Beginning',
      accessor: 'beginningQuantity',
    },
    {
      header: 'Deliveries',
      accessor: 'deliveriesQuantity',
    },
    {
      header: 'Transfer In',
      accessor: 'transferInQuantity',
    },
    {
      header: 'Transfer Out',
      accessor: 'transferOutQuantity',
    },
    {
      header: 'EM',
      accessor: 'emQuantity',
    },
    {
      header: 'SD',
      accessor: 'sdQuantity',
    },
    {
      header: 'TA',
      accessor: 'taQuantity',
    },
    {
      header: 'Ending Inventory',
      accessor: 'endingQuantity',
    },
    {
      header: 'Usage',
      accessor: 'usage',
    },
    {
      header: 'CRT Sold',
      accessor: 'crtRawMaterialItemQuantity',
    },
    {
      header: 'Variance',
      accessor: 'variance',
    },
  ];

  const beveragesSalesProductControlReportItemsColumns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof beveragesSalesProductControlReportItems>
      >
    >
  > = [
    {
      header: 'Beverage',
      accessor: 'beverage',
    },
    {
      header: 'Beginning',
      accessor: 'beginningQuantity',
    },
    {
      header: 'Yield (oz)',
      accessor: 'beverageYield',
    },

    {
      header: 'Ending (oz)',
      accessor: 'endingQuantity',
    },
    {
      header: 'EM (oz)',
      accessor: 'emQuantity',
    },
    {
      header: 'SD (oz)',
      accessor: 'sdQuantity',
    },
    {
      header: 'TA (oz)',
      accessor: 'taQuantity',
    },

    {
      header: 'Usage',
      accessor: 'usage',
    },
    {
      header: 'Cups Sold M',
      accessor: 'cupsSoldMediumQuantity',
    },
    {
      header: 'Cups Sold L',
      accessor: 'cupsSoldLargeQuantity',
    },
    {
      header: 'Cups Sold XL',
      accessor: 'cupsSoldXlQuantity',
    },
    {
      header: 'Oz Sold',
      accessor: 'ozSold',
    },
    {
      header: 'Variance',
      accessor: 'variance',
    },
  ];

  const { data: { outlets } = {}, loading: outletsLoading } = useOutletsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <ListView
        loading={
          cmlProductsSalesProductControlReportItemsLoading ||
          rawMaterialsSalesProductControlReportItemsLoading ||
          beveragesSalesProductControlReportItemsLoading ||
          outletsLoading
        }
        error={
          cmlProductsSalesProductControlReportItemsError ||
          rawMaterialsSalesProductControlReportItemsError ||
          beveragesSalesProductControlReportItemsError
        }
        title={title}
        filters={
          <>
            <DateFilter dateField="date" label="Date" />
            <EnumSearchFilter
              label="Delivery Shift Schedule"
              field="deliveryShiftSchedule"
              enumObj={DeliveryShiftSchedule}
            />
            <SearchFilter
              label="Outlet"
              field="outletId"
              arr={outlets}
              setAccessor={(value) => value.name}
              setValue={(value) => value.id}
            />
          </>
        }
        dataTable={<></>}
      />
      <Typography
        variant="h6"
        align="center"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: 'white',
          position: 'sticky',
        }}
      >
        CML PRODUCTS
      </Typography>
      <Paper elevation={0} sx={{ ...overflow, mb: 2 }}>
        <DataTable
          data={cmlProductsSalesProductControlReportItems}
          columns={cmlProductsSalesProductControlReportItemsColumns}
        />
      </Paper>
      <Typography
        variant="h6"
        align="center"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: 'white',
        }}
      >
        RAW MATERIALS
      </Typography>
      <Paper elevation={0} sx={{ ...overflow, mb: 2 }}>
        <DataTable
          data={rawMaterialsSalesProductControlReportItems}
          columns={rawMaterialsSalesProductControlReportItemsColumns}
        />
      </Paper>
      <Typography
        variant="h6"
        align="center"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: 'white',
        }}
      >
        BEVERAGES
      </Typography>
      <Paper elevation={0} sx={{ ...overflow }}>
        <DataTable
          data={beveragesSalesProductControlReportItems}
          columns={beveragesSalesProductControlReportItemsColumns}
        />
      </Paper>
    </>
  );
};

export default SalesProductControlReportItemsPage;
