import { Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { overflow } from '../../styles/overflow';
import { stickyHeader } from '../../styles/stickyHeader';
import Meta from '../page/Meta';
import { ApolloError } from '@apollo/client';
import Loading from '../common/Loading';

interface Props {
  title: string;
  subtitle?: ReactNode;
  miscButtons?: ReactNode;
  actionsMenu?: ReactNode;
  dataTable: ReactNode;
  pagination?: ReactNode;
  filters?: ReactNode;
  loading: boolean;
  error: ApolloError | undefined;
}
const ListView = ({
  title,
  subtitle,
  miscButtons,
  actionsMenu,
  dataTable,
  pagination,
  filters,
  loading,
  error,
}: Props) => {
  if (loading) return <Loading />;
  if (
    error?.graphQLErrors.some(({ message }) =>
      message.includes('Not Authorised')
    )
  )
    return (
      <Typography
        variant="h6"
        align="center"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        Access denied. You have no access to this module
      </Typography>
    );

  return (
    <>
      <>
        <Meta pageTitle={title} />
        <Stack direction="row" spacing={1}>
          {miscButtons}
        </Stack>
        <Stack direction="column" sx={{ ...stickyHeader }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="column">
              <Typography variant="h3">{title}</Typography>
              {subtitle}
            </Stack>
            {actionsMenu}
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={1}
            sx={{
              m: {
                xs: '0px auto 8px auto',
                md: '0px 0 8px auto',
              },
            }}
          >
            {filters}
          </Stack>
        </Stack>
        <Paper elevation={0} sx={{ ...overflow }}>
          {dataTable}
        </Paper>
        {pagination}
      </>
    </>
  );
};

export default ListView;
