// https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
type ReturnMap<T> = {
  [Property in keyof T]: {
    set: T[Property]
  }
}

const datePattern = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/

export const prismaUpdate = <T,>({ __typename, id, ...object }: { __typename?: string, id?: number} & T): ReturnMap<T> => {
  const entries = Object.entries(object).map(([key, value]: [string, any]) => {
    if (datePattern.test(value)) {
      return [key, { set: new Date(value).toISOString() }];
    } else if (timePattern.test(value)) {
        const today = new Date()
        return [key, { set: new Date(today.toDateString() + ' ' + value) }]
    } else if (value == null) {
      return [key, { set: null }];
    } else {
      return [key, { set: value }];
    }
  });

  return Object.fromEntries(entries);
};

export const prismaCreate = <T,>({ __typename, id, ...object }: { __typename?: string, id?: number} & T): T => {
  const entries = Object.entries(object).map(([key, value]: [string, any]) => {
    if (datePattern.test(value)) {
      return [key, new Date(value).toISOString()];
    } else if (timePattern.test(value)) {
      const today = new Date()
      return [key, new Date(today.toDateString() + ' ' + value)];
    } else {
      return [key, value];
    }
  });

  return Object.fromEntries(entries);
};


export const getItemsForDelete = (dbArray: any[] = [], stateArray: any[] = []) => {

  return dbArray.filter((dbItem) => !stateArray.some((item) => item?.id === dbItem?.id)).map(({id}) => {
    return { id }
  }) ||
  [];
}

export const getItemsForUpsert = (stateArray: any[] = []) =>
  stateArray.map(({ id, ...item }) => {
    if (!id) return { id: 0, ...item };
    else return { id, ...item };
  });

export const getUploads = (arr: any[]) => arr?.every((url) => typeof url !== "string")
? arr
: null
