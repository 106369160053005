import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useTransferQuery,
  useUpdateTransferMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import TransferForm from './TransferForm';
import { FormData, schema } from './utils';

const UpdateTransferPage = ({ title = `Update Transfer` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        transferCmlItems,
        transferRawMaterialItems,
        transferPackagingItems,
        fromOutlet,
        toOutlet,
        ...data
      }) => {
        const transferCmlItemsToDelete = getItemsForDelete(
          transfer?.transferCmlItems,
          transferCmlItems
        );
        const transferCmlItemsToUpsert = getItemsForUpsert(transferCmlItems);

        const transferRawMaterialItemsToDelete = getItemsForDelete(
          transfer?.transferRawMaterialItems,
          transferRawMaterialItems
        );
        const transferRawMaterialItemsToUpsert = getItemsForUpsert(
          transferRawMaterialItems
        );

        const transferPackagingItemsToDelete = getItemsForDelete(
          transfer?.transferPackagingItems,
          transferPackagingItems
        );
        const transferPackagingItemsToUpsert = getItemsForUpsert(
          transferPackagingItems
        );

        await updateTransfer({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              fromOutlet: {
                connect: {
                  id: fromOutlet?.id,
                },
              },
              toOutlet: {
                connect: {
                  id: toOutlet?.id,
                },
              },
              transferCmlItems: {
                delete: transferCmlItemsToDelete,
                upsert: transferCmlItemsToUpsert.map(
                  ({ id, cmlProduct, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      },
                    };
                  }
                ),
              },
              transferRawMaterialItems: {
                delete: transferRawMaterialItemsToDelete,
                upsert: transferRawMaterialItemsToUpsert.map(
                  ({ id, rawMaterial, unit, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
              transferPackagingItems: {
                delete: transferPackagingItemsToDelete,
                upsert: transferPackagingItemsToUpsert.map(
                  ({ id, packaging, unit, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { transfer } = {}, loading } = useTransferQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateTransfer, { loading: mutationLoading }] =
    useUpdateTransferMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (transfer) {
      const { transferRawMaterialItems, transferPackagingItems, ...data } =
        transfer;
      if (data?.receivedByDriver) {
        handleSnackbarOpen({
          message: 'This form has been received and can no longer be updated.',
          severity: 'error',
        });
        navigate(-1);
      }
      methods.reset({
        ...cleanPayload({
          ...data,
          transferRawMaterialItems: transferRawMaterialItems.map(
            ({ rawMaterialProduct, ...cmlProductIngredient }) => {
              return {
                rawMaterial: rawMaterialProduct?.rawMaterial,
                unit: rawMaterialProduct?.unit,
                ...cmlProductIngredient,
              };
            }
          ),
          transferPackagingItems: transferPackagingItems.map(
            ({ packagingProduct, ...cmlProductIngredient }) => {
              return {
                packaging: packagingProduct?.packaging,
                unit: packagingProduct?.unit,
                ...cmlProductIngredient,
              };
            }
          ),
        }),
      });
    }
  }, [transfer, methods, handleSnackbarOpen, navigate]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <TransferForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateTransferPage;
