import { zodResolver } from '@hookform/resolvers/zod';
import { Remove } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  PackagingCategory,
  SortOrder,
  Status,
  useCreateManyMonthlyOutletPackagingInventoryMutation,
  usePackagingsAllowQuery,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { prismaCreate } from '../../utils/prisma';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import {
  capitalizeFirstLetter,
  getEnumKeyFromValue,
} from '../../utils/stringFunctions';
import FormContainer from '../common/FormContainer';
import Select from '../common/Select';
import Meta from '../page/Meta';
import OutletAutocomplete from '../shared/OutletAutocomplete';

const schema = z.object({
  date: z.coerce.date(),
  outlet: z
    .object({ id: z.coerce.number() })
    .transform((val) => (!val ? null : val)),
  packagingProducts: z.array(
    z.object({
      id: z.coerce.number().optional(),
      packaging: z
        .object({
          id: z.coerce.number(),
          name: z.string().trim().min(1),
          packagingCategory: z.nativeEnum(PackagingCategory),
          packagingProducts: z.array(
            z.object({
              unit: z
                .object({ id: z.coerce.number() })
                .transform((val) => (!val ? null : val)),
            })
          ),
        })
        .transform((val) => (!val ? null : val)),
      unit: z
        .object({ id: z.coerce.number() })
        .transform((val) => (!val ? null : val)),
      quantity: z.preprocess(
        (val) => (val === '' ? null : Number(val)),
        z.number()
      ),
    })
  ),
});

type FormData = z.infer<typeof schema>;

const CreateManyMonthlyOutletPackagingInventoryPage = ({
  title = `Create Many Monthly Outlet Packaging Inventory`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const { fields, remove } = useFieldArray({
    control: methods.control,
    name: 'packagingProducts',
  });

  const [packagingProducts] = useWatch({
    control: methods.control,
    name: ['packagingProducts'],
  });

  const { data } = usePackagingsAllowQuery({
    fetchPolicy: 'network-only',

    variables: {
      orderBy: [{ packagingCategory: SortOrder.Desc }],
      where: {
        status: {
          equals: Status.Active,
        },
      },
    },
    onCompleted: (data) => {
      methods.setValue(
        'packagingProducts',
        data.packagings.map((packaging) => ({
          packaging,
          unit: null,
          quantity: undefined as unknown as number,
        }))
      );
    },
  });

  const handleRemoveMonthlyOutletPackagingInventory = (index: number) => {
    remove(index);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ packagingProducts, date, outlet }) =>
        await createManyMonthlyOutletPackagingInventory({
          variables: {
            data: packagingProducts.map(
              ({ packaging, unit, ...monthlyOutletPackagingInventory }) => {
                return {
                  ...prismaCreate({ date }),

                  ...monthlyOutletPackagingInventory,
                  packagingProduct: {
                    connect: {
                      unitId_packagingId: {
                        packagingId: packaging?.id || 0,
                        unitId: unit?.id || 0,
                      },
                    },
                  },
                  outlet: {
                    connect: {
                      id: outlet?.id || 0,
                    },
                  },
                };
              }
            ),
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const [
    createManyMonthlyOutletPackagingInventory,
    { loading: mutationLoading },
  ] = useCreateManyMonthlyOutletPackagingInventoryMutation({
    onError: (error) =>
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' success!',
        severity: 'success',
      });
      navigate(location.pathname.replace('/create', ''));
    },
  });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <FormContainer maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
          >
            {title}
          </Typography>

          <Box component="form" onSubmit={onSubmit} noValidate>
            <Box
              component="div"
              sx={{
                display: 'grid',
                gap: 1,
                gridTemplateColumns: 'repeat(3, 1fr)',
              }}
            >
              <Controller
                name="date"
                control={methods.control}
                defaultValue={'' as unknown as Date}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    {...textFieldHelperProps(field, fieldState, schema)}
                    {...field}
                  />
                )}
              />
              <OutletAutocomplete
                name={`outlet`}
                schema={schema}
                control={methods.control}
                label="Outlet"
                defaultValue={null}
              />
            </Box>
            <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
              Packaging Items
            </Typography>

            <Stack direction="column" spacing={1}>
              {fields.map((field, index) => {
                return (
                  <Box
                    key={field.id}
                    sx={{
                      ...nestedGrid,
                    }}
                  >
                    <Box>
                      <TextField
                        label={`Category ${index + 1}`}
                        fullWidth
                        value={
                          (packagingProducts &&
                            getEnumKeyFromValue(
                              PackagingCategory,
                              packagingProducts[index]?.packaging
                                ?.packagingCategory
                            )) ??
                          ''
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <TextField
                        label={`Packaging ${index + 1}`}
                        fullWidth
                        value={
                          (packagingProducts &&
                            packagingProducts[index]?.packaging?.name) ??
                          ''
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />

                      <Controller
                        name={`packagingProducts.${index}.quantity`}
                        control={methods.control}
                        defaultValue={undefined}
                        render={({ field, fieldState }) => (
                          <TextField
                            label={`Quantity ${index + 1}`}
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                            type="number"
                            onWheel={(e) => (e.target as HTMLElement).blur()}
                          />
                        )}
                      />
                      <Select
                        name={`packagingProducts.${index}.unit`}
                        label="Unit"
                        schema={schema}
                        control={methods.control}
                        arr={
                          (packagingProducts &&
                            packagingProducts[
                              index
                            ]?.packaging?.packagingProducts?.map(({ unit }) => {
                              return { ...unit };
                            })) ||
                          []
                        }
                        defaultValue={null}
                        renderValueAs={(obj) => `${obj.name}`}
                      />
                    </Box>

                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="small"
                      onClick={() =>
                        handleRemoveMonthlyOutletPackagingInventory(index)
                      }
                    >
                      <Remove />
                    </IconButton>
                  </Box>
                );
              })}
            </Stack>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
              disabled={mutationLoading}
              fullWidth
            >
              Submit
            </Button>
          </Box>
        </FormContainer>
      </FormProvider>
    </>
  );
};

export default CreateManyMonthlyOutletPackagingInventoryPage;
