import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useRawMaterialFinishingEndingQuery,
  UserGroup,
  useUpdateRawMaterialFinishingEndingMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import RawMaterialFinishingEndingForm from './RawMaterialFinishingEndingForm';

import { FormData, schema } from './utils';
import { useAuthContext } from '../../contexts/authContext';
import { differenceInHours } from 'date-fns';

const UpdateRawMaterialFinishingEndingPage = ({
  title = `Update Raw Material Finishing Ending`,
}) => {
  const { currentUser } = useAuthContext();
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ rawMaterialFinishingEndingItems, outlet, ...data }) => {
        await updateRawMaterialFinishingEnding({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              ...(outlet
                ? {
                    outlet: {
                      connect: {
                        id: outlet?.id,
                      },
                    },
                  }
                : {
                    outlet: {
                      disconnect: true,
                    },
                  }),
              rawMaterialFinishingEndingItems: {
                update: rawMaterialFinishingEndingItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { rawMaterialFinishingEnding } = {}, loading } =
    useRawMaterialFinishingEndingQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    });

  const [updateRawMaterialFinishingEnding, { loading: mutationLoading }] =
    useUpdateRawMaterialFinishingEndingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (rawMaterialFinishingEnding) {
      const { rawMaterialFinishingEndingItems, ...data } =
        rawMaterialFinishingEnding;
      if (
        (currentUser?.userGroups.includes(UserGroup['Store Keeper']) ||
          currentUser?.userGroups.includes(UserGroup['Team Leader'])) &&
        differenceInHours(new Date(), new Date(data.createdAt)) > 1
      ) {
        handleSnackbarOpen({
          message:
            'Time difference between now and created at is greater than an hour.',
          severity: 'error',
        });
        navigate(-1);
      }
      methods.reset({
        ...cleanPayload({
          ...data,
          rawMaterialFinishingEndingItems: rawMaterialFinishingEndingItems.map(
            ({ rawMaterialProduct, ...rawMaterialProductIngredient }) => {
              return {
                rawMaterial: rawMaterialProduct?.rawMaterial,
                unit: rawMaterialProduct?.unit,
                ...rawMaterialProductIngredient,
              };
            }
          ),
        }),
      });
    }
  }, [rawMaterialFinishingEnding, methods, currentUser?.userGroups, navigate]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <RawMaterialFinishingEndingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateRawMaterialFinishingEndingPage;
