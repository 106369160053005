import { Typography } from '@mui/material';
import {
  DeliveryShiftSchedule,
  DonutEndingConsolidationWhereInput,
  RawMaterialCategory,
  useDailyRawMaterialFinishingOutletVarianceReportQuery,
  useFindFirstOutletFinishingQuery,
  useOutletsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateFilter from '../common/DateFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Search from '../common/Search';
import SearchFilter from '../common/SearchFilter';
import ListView from '../templates/ListView';
import { roundFloat } from '../../utils/numberFunctions';

const DailyRawMaterialFinishingOutletVarianceReportPage = ({
  title = 'Daily RM Finishing Outlet Variance Report',
}) => {
  const [
    dateQueryString,
    deliveryShiftScheduleQueryString,
    searchQueryString,
    outletIdQueryString,
  ] = useGetQueryString([
    'date',
    'deliveryShiftSchedule',
    'search',
    'outletId',
  ]);

  const where: DonutEndingConsolidationWhereInput = Object.assign(
    {},
    deliveryShiftScheduleQueryString
      ? {
          deliveryShiftSchedule:
            deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
        }
      : {},
    typeof dateQueryString === 'string'
      ? {
          date: new Date(dateQueryString),
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          searchTerm: searchQueryString,
        }
      : {},
    typeof outletIdQueryString === 'string'
      ? {
          outletId: +outletIdQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const {
    data: { dailyRawMaterialFinishingOutletVarianceReport } = {},
    loading,
    error,
  } = useDailyRawMaterialFinishingOutletVarianceReportQuery({
    fetchPolicy: 'network-only',
    variables,
    skip:
      !dateQueryString ||
      !deliveryShiftScheduleQueryString ||
      !outletIdQueryString,
  });

  const columns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof dailyRawMaterialFinishingOutletVarianceReport>
      >
    >
  > = [
    {
      header: 'Category',
      accessor: 'category',
      Cell: (children) => getEnumKeyFromValue(RawMaterialCategory, children),
    },
    {
      header: 'Raw Material',
      accessor: 'rawMaterial',
    },
    {
      header: 'Unit',
      accessor: 'unit',
    },
    {
      header: 'Beginning',
      accessor: 'beginning',
      Cell: (children) => roundFloat(children),
    },
    {
      header: 'From Store Room',
      accessor: 'fromStoreRoom',
      Cell: (children) => roundFloat(children),
    },
    {
      header: 'Throw Away',
      accessor: 'throwAway',
      Cell: (children) => roundFloat(children),
    },
    {
      header: 'Theoretical',
      accessor: 'theoretical',
      Cell: (children) => roundFloat(children),
    },
    {
      header: 'Should Be',
      accessor: 'shouldBe',
      Cell: (children) => roundFloat(children),
    },
    {
      header: 'Actual Ending',
      accessor: 'actualEnding',
      Cell: (children) => roundFloat(children),
    },
    {
      header: 'Variance',
      accessor: 'variance',
      Cell: (children) => roundFloat(children),
    },
  ];
  const { data: { outlets } = {}, loading: outletsLoading } = useOutletsQuery({
    fetchPolicy: 'network-only',
  });

  const { data } = useFindFirstOutletFinishingQuery({
    fetchPolicy: 'network-only',
    skip:
      !dateQueryString ||
      !deliveryShiftScheduleQueryString ||
      !outletIdQueryString,
    variables: {
      where: Object.assign(
        {},
        deliveryShiftScheduleQueryString
          ? {
              deliveryShiftSchedule: {
                equals:
                  deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
              },
            }
          : {},
        typeof dateQueryString === 'string'
          ? {
              date: {
                equals: new Date(dateQueryString),
              },
            }
          : {},
        // typeof searchQueryString === 'string'
        //   ? {
        //       searchTerm: searchQueryString,
        //     }
        //   : {},
        typeof outletIdQueryString === 'string'
          ? {
              outletId: {
                equals: +outletIdQueryString,
              },
            }
          : {}
      ),
    },
  });

  return (
    <>
      <ListView
        loading={loading || outletsLoading}
        error={error}
        title={title}
        subtitle={
          <>
            <Typography variant="subtitle2">
              Finisher: {data?.findFirstOutletFinishing?.createdBy}
            </Typography>
          </>
        }
        filters={
          <>
            <DateFilter dateField="date" label="Date" />
            <EnumSearchFilter
              label="Delivery Shift Schedule"
              field="deliveryShiftSchedule"
              enumObj={DeliveryShiftSchedule}
            />
            <SearchFilter
              label="Outlet"
              field="outletId"
              arr={outlets}
              setAccessor={(value) => value.name}
              setValue={(value) => value.id}
            />
            <Search />
          </>
        }
        dataTable={
          <DataTable
            data={dailyRawMaterialFinishingOutletVarianceReport}
            columns={columns}
          />
        }
      />
    </>
  );
};

export default DailyRawMaterialFinishingOutletVarianceReportPage;
