export enum RawMaterialCategory {
  "Mix" ="MIX",
  "Sugar and Dip" ="SUGAR_AND_DIP",
  "Frosting" ="FROSTING",
  "Flavor" ="FLAVOR",
  "Syrup" ="SYRUP",
  "Filling" ="FILLING",
  "Kreme" ="KREME",
  "Topping" ="TOPPING",
  "Chocolate Block" ="CHOCOLATE_BLOCK",
  "Bakery" ="BAKERY",
  "Cold Dip Making" ="COLD_DIP_MAKING",
  "Finishing Outlet Raw Materials" ="FINISHING_OUTLET_RAW_MATERIALS",
  "Drink" ="DRINK",
  "Drinks Sales Control" ="DRINKS_SALES_CONTROL",
  "Others" ="OTHERS",
  "Raw Materials Control" = "RAW_MATERIALS_CONTROL"
}