import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregatePackagingDocument,
  PackagingCategory,
  PackagingsDocument,
  PackagingWhereInput,
  QueryMode,
  SortOrder,
  Status,
  useDeleteManyPackagingMutation,
  usePackagingsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { booleanToString } from '../../utils/booleanFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const PackagingsPage = ({ title = 'Packagings' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, statusQueryString, packagingCategoryQueryString] =
    useGetQueryString(['search', 'status', 'packagingCategory']);

  const where: PackagingWhereInput = Object.assign(
    {},
    statusQueryString
      ? {
          status: { equals: statusQueryString as Status },
        }
      : {},
    packagingCategoryQueryString
      ? {
          packagingCategory: {
            equals: packagingCategoryQueryString as PackagingCategory,
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { packagings } = {},
    loading,
    error,
  } = usePackagingsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyPackaging] = useDeleteManyPackagingMutation({
    refetchQueries: [
      { query: AggregatePackagingDocument, variables: countVariables },
      {
        query: PackagingsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof packagings>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Packaging Category',
      accessor: 'packagingCategory',
      Cell: (children) => getEnumKeyFromValue(PackagingCategory, children),
    },
    {
      header: 'Status',
      accessor: 'status',
      Cell: (children) => getEnumKeyFromValue(Status, children),
    },
    {
      header: 'Packaging Products',
      accessor: 'packagingProducts',
      Cell: (children: {}[], index: number) => {
        const packagingProductColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof packagings>
              >['packagingProducts']
            >
          >
        > = [
          {
            header: 'Unit',
            accessor: 'unit.name',
          },
          {
            header: 'Conversion to Base Unit',
            accessor: 'conversionToBaseUnit',
            Cell: (children) => roundFloat(children),
          },
          {
            header: 'Base Unit',
            accessor: 'baseUnit',
            Cell: (children) => booleanToString(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={packagingProductColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyPackaging({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <EnumSearchFilter
            label="Packaging Category"
            field="packagingCategory"
            enumObj={PackagingCategory}
          />
          <EnumSearchFilter label="Status" field="status" enumObj={Status} />
          <Search />
        </>
      }
      dataTable={<DataTable data={packagings} columns={columns} />}
      pagination={
        <Pagination
          query={AggregatePackagingDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default PackagingsPage;
