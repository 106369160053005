import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateOutletFinishingMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import { useAuthContext } from '../../contexts/authContext';
import { FormData, schema } from './utils';
import OutletFinishingForm from './OutletFinishingForm';

const CreateOutletFinishingPage = ({ title = `Create Outlet Finishing` }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({ outletFinishingItems, outlet, ...data }) =>
          await createOutletFinishing({
            variables: {
              data: {
                ...prismaCreate(data),
                controlNumber: '',
                outlet: {
                  connect: {
                    id: outlet?.id,
                  },
                },
                createdBy: currentUser.name,
                outletFinishingItems: {
                  create: outletFinishingItems.map(
                    ({
                      cmlProduct,
                      quantity,
                      ...outletFinishingRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...outletFinishingRawMaterialItem,
                          quantity,
                        }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id || 0,
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createOutletFinishing, { loading: mutationLoading }] =
    useCreateOutletFinishingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <OutletFinishingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateOutletFinishingPage;
