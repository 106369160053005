import { z } from 'zod';
import { DeliveryShiftSchedule } from '../../graphql/enums/DeliveryShiftSchedule';
import { RawMaterialCategory, RawMaterialFinishingEndingLocation } from '../../graphql/generated/types';


export const schema = z.discriminatedUnion("rawMaterialFinishingEndingLocation", [
  z.object({
    date: z.coerce.date(),
    deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
    rawMaterialFinishingEndingLocation: z.literal(RawMaterialFinishingEndingLocation.Outlet),
    outlet: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }),
    controlNumber: z.string().optional(),
    remarks: z.string().optional(),
    createdBy: z.string().optional(),
    createdAt: z.coerce.date().optional(),
    rawMaterialFinishingEndingItems: z.array(z.object({
      id: z.coerce.number().optional(),
      rawMaterial: z.object({ 
        id: z.coerce.number(),
        rawMaterialCategory: z.nativeEnum(RawMaterialCategory),
        name: z.string().trim().min(1),
        rawMaterialProducts: z.array(z.object({
          unit: z.object({ 
            id: z.coerce.number(),
            name: z.string().trim().min(1),
          }).transform((val) => !val ? null : val),
        }))
      }).transform((val) => !val ? null : val),
      unit: z.object({ 
        id: z.coerce.number(),
        name: z.string().trim().min(1),
      }).transform((val) => !val ? null : val),
  
      quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    })),
  
  }),
  z.object({
    date: z.coerce.date(),
    deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
    rawMaterialFinishingEndingLocation: z.literal(RawMaterialFinishingEndingLocation.Warehouse),
    outlet: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).nullish(),
    controlNumber: z.string().optional(),
    remarks: z.string().optional(),
    createdBy: z.string().optional(),
    createdAt: z.coerce.date().optional(),
    rawMaterialFinishingEndingItems: z.array(z.object({
      id: z.coerce.number().optional(),
      rawMaterial: z.object({ 
        id: z.coerce.number(),
        rawMaterialCategory: z.nativeEnum(RawMaterialCategory),
        name: z.string().trim().min(1),
        rawMaterialProducts: z.array(z.object({
          unit: z.object({ 
            id: z.coerce.number(),
            name: z.string().trim().min(1),
          }).transform((val) => !val ? null : val),
        }))
      }).transform((val) => !val ? null : val),
      unit: z.object({ 
        id: z.coerce.number(),
        name: z.string().trim().min(1),
      }).transform((val) => !val ? null : val),
  
      quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    })),
  
  }) 
])

export type FormData = z.infer<typeof schema>




