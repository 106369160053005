import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  PackagingCategory,
  RawMaterialCategory,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import FormContainer from '../common/FormContainer';
import { FormData } from './utils';

const WarehouseInventoryForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  const { fields: warehouseInventoryRawMaterialItemsFields } = useFieldArray({
    control,
    name: 'warehouseInventoryRawMaterialItems',
  });

  const { fields: warehouseInventoryPackagingItemsFields } = useFieldArray({
    control,
    name: 'warehouseInventoryPackagingItems',
  });

  const [
    warehouseInventoryRawMaterialItems,
    warehouseInventoryPackagingItems,
    createdBy,
  ] = useWatch({
    control,
    name: [
      'warehouseInventoryRawMaterialItems',
      'warehouseInventoryPackagingItems',
      'createdBy',
    ],
  });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="dateTime"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Time"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                disabled={type === 'update'}
              />
            )}
          />
          {type === 'update' && (
            <TextField
              label="Created By"
              fullWidth
              value={createdBy ?? ''}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
                inputProps: { tabIndex: -1 },
              }}
            />
          )}
          <Controller
            name="remarks"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                InputLabelProps={{ shrink: true }}
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {warehouseInventoryRawMaterialItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Category ${index + 1}`}
                    fullWidth
                    value={
                      getEnumKeyFromValue(
                        RawMaterialCategory,
                        warehouseInventoryRawMaterialItems &&
                          warehouseInventoryRawMaterialItems[index]
                            ?.rawMaterialProduct?.rawMaterial
                            ?.rawMaterialCategory
                      ) ?? ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Raw Material ${index + 1}`}
                    fullWidth
                    value={
                      (warehouseInventoryRawMaterialItems &&
                        warehouseInventoryRawMaterialItems[index]
                          ?.rawMaterialProduct?.rawMaterial?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (warehouseInventoryRawMaterialItems &&
                        warehouseInventoryRawMaterialItems[index]
                          ?.rawMaterialProduct?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`warehouseInventoryRawMaterialItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {warehouseInventoryPackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Category ${index + 1}`}
                    fullWidth
                    value={
                      getEnumKeyFromValue(
                        PackagingCategory,
                        warehouseInventoryPackagingItems &&
                          warehouseInventoryPackagingItems[index]
                            ?.packagingProduct?.packaging?.packagingCategory
                      ) ?? ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Packaging ${index + 1}`}
                    fullWidth
                    value={
                      (warehouseInventoryPackagingItems &&
                        warehouseInventoryPackagingItems[index]
                          ?.packagingProduct?.packaging?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (warehouseInventoryPackagingItems &&
                        warehouseInventoryPackagingItems[index]
                          ?.packagingProduct?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`warehouseInventoryPackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default WarehouseInventoryForm;
