import { z } from 'zod';
import { DeliveryShiftSchedule } from '../../graphql/enums/DeliveryShiftSchedule';
import { SalesCategory } from '../../graphql/enums/SalesCategory';


export const schema = z.object({
  date: z.coerce.date(),
  controlNumber: z.string().optional(),
  createdBy: z.string().optional(),
  deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
  outlet: z.object({ 
    id: z.coerce.number(),
    name: z.string().trim().min(1),
  }).transform((val) => !val ? null : val),

  projectionItems: z.array(z.object({
    id: z.coerce.number().optional(),
    cmlProduct: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
      salesCategory: z.nativeEnum(SalesCategory),
      unit: z.object({ 
        id: z.coerce.number(),
        name: z.string().trim().min(1),
      }).transform((val) => !val ? null : val)
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),

})

export type FormData = z.infer<typeof schema>




