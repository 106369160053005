import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useUpdateWarehouseInventoryMutation,
  useWarehouseInventoryQuery,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import { FormData, schema } from './utils';
import WarehouseInventoryForm from './WarehouseInventoryForm';

const UpdateWarehouseInventoryPage = ({
  title = `Update Warehouse Inventory`,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        warehouseInventoryRawMaterialItems,
        warehouseInventoryPackagingItems,
        ...data
      }) => {
        await updateWarehouseInventory({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              warehouseInventoryRawMaterialItems: {
                update: warehouseInventoryRawMaterialItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
              warehouseInventoryPackagingItems: {
                update: warehouseInventoryPackagingItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { warehouseInventory } = {}, loading } =
    useWarehouseInventoryQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    });

  const [updateWarehouseInventory, { loading: mutationLoading }] =
    useUpdateWarehouseInventoryMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (warehouseInventory) {
      const {
        warehouseInventoryRawMaterialItems,
        warehouseInventoryPackagingItems,
        dateTime,
        ...data
      } = warehouseInventory;
      methods.reset({
        ...cleanPayload({
          ...data,
          warehouseInventoryRawMaterialItems:
            warehouseInventoryRawMaterialItems.map(
              ({ rawMaterialProduct, ...cmlProductIngredient }) => {
                return {
                  rawMaterialProduct,
                  ...cmlProductIngredient,
                };
              }
            ),
          warehouseInventoryPackagingItems:
            warehouseInventoryPackagingItems.map(
              ({ packagingProduct, ...cmlProductIngredient }) => {
                return {
                  packagingProduct,
                  ...cmlProductIngredient,
                };
              }
            ),
        }),
        dateTime: format(new Date(dateTime), "yyyy-MM-dd'T'HH:mm"),
      });
    }
  }, [warehouseInventory, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <WarehouseInventoryForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateWarehouseInventoryPage;
