import { z } from 'zod';


export const schema = z.object({
  requestDate: z.coerce.date(),
  neededDate: z.coerce.date(),
  controlNumber: z.string().optional(),
  createdBy: z.string().optional(),

  outlet: z.object({ 
    id: z.coerce.number(),
    name: z.string().trim().min(1),
  }).transform((val) => !val ? null : val),

  rawMaterialRequestItems: z.array(z.object({
    id: z.coerce.number().optional(),
    rawMaterial: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
      rawMaterialProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
        baseUnit: z.boolean(),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),

    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),

})

export type FormData = z.infer<typeof schema>




