import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateReceivedSdsDeliveryReceiptMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useAuthContext } from '../../contexts/authContext';
import { FormData, schema } from './utils';

import ReceivedSdsDeliveryReceiptForm from './ReceivedSdsDeliveryReceiptForm';

const CreateReceivedSdsDeliveryReceiptPage = ({
  title = `Create Received SDS Delivery Receipt`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({
          receivedSdsDeliveryReceiptPackagingItems,
          receivedSdsDeliveryReceiptRawMaterialItems,
          sdsDeliveryReceipt,
          outlet,
          ...data
        }) =>
          await createReceivedSdsDeliveryReceipt({
            variables: {
              data: {
                ...prismaCreate(data),
                sdsDeliveryReceipt: {
                  connect: {
                    id: sdsDeliveryReceipt?.id,
                  },
                },
                receivedBy: currentUser.name,
                receivedSdsDeliveryReceiptRawMaterialItems: {
                  create: receivedSdsDeliveryReceiptRawMaterialItems.map(
                    ({
                      sdsDeliveryReceiptRawMaterialItem,
                      ...receivedReceivedSdsDeliveryReceiptRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...receivedReceivedSdsDeliveryReceiptRawMaterialItem,
                        }),
                        sdsDeliveryReceiptRawMaterialItem: {
                          connect: {
                            id: sdsDeliveryReceiptRawMaterialItem?.id,
                          },
                        },
                      };
                    }
                  ),
                },
                receivedSdsDeliveryReceiptPackagingItems: {
                  create: receivedSdsDeliveryReceiptPackagingItems.map(
                    ({
                      sdsDeliveryReceiptPackagingItem,
                      ...receivedReceivedSdsDeliveryReceiptPackagingItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...receivedReceivedSdsDeliveryReceiptPackagingItem,
                        }),
                        sdsDeliveryReceiptPackagingItem: {
                          connect: {
                            id: sdsDeliveryReceiptPackagingItem?.id,
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createReceivedSdsDeliveryReceipt, { loading: mutationLoading }] =
    useCreateReceivedSdsDeliveryReceiptMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ReceivedSdsDeliveryReceiptForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateReceivedSdsDeliveryReceiptPage;
