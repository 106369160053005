import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useRawMaterialProductRecipeQuery,
  useUpdateRawMaterialProductRecipeMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import RawMaterialForm from './RawMaterialProductRecipeForm';
import { FormData, schema } from './utils';

const UpdateRawMaterialProductRecipePage = ({
  title = `Update Raw Material Product Recipe`,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        rawMaterialProduct,
        rawMaterialProductIngredients,
        ...data
      }) => {
        const rawMaterialProductIngredientsToDelete = getItemsForDelete(
          rawMaterialProductRecipe?.rawMaterialProductIngredients,
          rawMaterialProductIngredients
        );
        const rawMaterialProductIngredientsToUpsert = getItemsForUpsert(
          rawMaterialProductIngredients
        );
        await updateRawMaterialProductRecipe({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              rawMaterialProduct: {
                connect: {
                  id: rawMaterialProduct?.id,
                },
              },
              rawMaterialProductIngredients: {
                delete: rawMaterialProductIngredientsToDelete,
                upsert: rawMaterialProductIngredientsToUpsert.map(
                  ({
                    id,
                    rawMaterial,
                    unit,
                    ...rawMaterialProductIngredient
                  }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(rawMaterialProductIngredient),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate(rawMaterialProductIngredient),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { rawMaterialProductRecipe } = {}, loading } =
    useRawMaterialProductRecipeQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    });

  const [updateRawMaterialProductRecipe, { loading: mutationLoading }] =
    useUpdateRawMaterialProductRecipeMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (rawMaterialProductRecipe) {
      methods.reset({
        ...cleanPayload({
          ...rawMaterialProductRecipe,
          rawMaterialProductIngredients:
            rawMaterialProductRecipe.rawMaterialProductIngredients.map(
              ({ rawMaterialProduct, ...rawMaterialProductIngredient }) => {
                return {
                  ...rawMaterialProductIngredient,
                  rawMaterial: rawMaterialProduct?.rawMaterial,
                  unit: rawMaterialProduct?.unit,
                };
              }
            ),
        }),
      });
    }
  }, [rawMaterialProductRecipe, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <RawMaterialForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateRawMaterialProductRecipePage;
