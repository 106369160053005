import { createTheme, responsiveFontSizes } from "@mui/material";
import { grey } from "@mui/material/colors";

let theme = createTheme({
  palette: {
    background: {
      default: grey[50],
    },
    primary: {
      main: '#FF6E0C',
    },
    secondary: {
      main: '#663600',
    },
    tertiary: {
      main: '#F20C90'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '60px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-readOnly fieldset': {
            borderColor: '#F20C90'
          }
        } 
      }
    }
  }
})

theme = responsiveFontSizes(theme);

export default theme;
