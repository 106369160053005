import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateRawMaterialMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import RawMaterialForm from './RawMaterialForm';
import { schema, FormData } from './utils';

const CreateRawMaterialPage = ({ title = `Create Raw Material` }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ rawMaterialProducts, ...data }) =>
        await createRawMaterial({
          variables: {
            data: {
              ...prismaCreate(data),
              rawMaterialProducts: {
                create: rawMaterialProducts?.map(
                  ({ unit, ...rawMaterialProduct }) => {
                    return {
                      unit: {
                        connect: {
                          id: unit?.id,
                        },
                      },
                      ...prismaCreate(rawMaterialProduct),
                    };
                  }
                ),
              },
            },
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const [createRawMaterial, { loading: mutationLoading }] =
    useCreateRawMaterialMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <RawMaterialForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateRawMaterialPage;
