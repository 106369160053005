import { Box, Button, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { UserGroup } from '../../graphql/generated/types';
import { FormProps } from '../../types/FormProps';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import { FormData } from './utils';

const CheatSheetForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
  readOnly,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  return (
    <FormContainer maxWidth="md" readOnly={readOnly}>
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gap: 1,
          }}
        >
          <EnumSelect
            name="userGroup"
            label="User Group"
            schema={schema}
            enumObj={UserGroup}
            control={control}
            defaultValue={'' as unknown as UserGroup}
          />
          <div>
            <Controller
              name="instructions"
              control={control}
              defaultValue={EditorState.createEmpty()}
              render={({ field, fieldState }) => (
                <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  editorState={field.value}
                  onEditorStateChange={field.onChange}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    minHeight: '200px',
                  }}
                />
              )}
            />
          </div>
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default CheatSheetForm;
