import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useCreateRawMaterialReleaseMutation,
  useRawMaterialReleaseForBreakdownReferencesQuery,
  useRawMaterialReleaseItemReferencesQuery,
} from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useAuthContext } from '../../contexts/authContext';
import { FormData, schema } from './utils';

import { useEffect } from 'react';
import RawMaterialReleaseForm from './RawMaterialReleaseForm';

const CreateRawMaterialReleasePage = ({
  title = `Create Raw Material Release`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const [date, deliveryShiftSchedule] = useWatch({
    control: methods.control,
    name: ['date', 'deliveryShiftSchedule'],
  });
  const {
    data: { rawMaterialReleaseForBreakdownReferences } = {},
    // loading: rawMaterialReleaseForBreakdownReferencesLoading,
  } = useRawMaterialReleaseForBreakdownReferencesQuery({
    fetchPolicy: 'network-only',
    skip: !date || !deliveryShiftSchedule,
    variables: {
      data: {
        date,
        deliveryShiftSchedule,
      },
    },
  });
  const {
    data: { rawMaterialReleaseItemReferences } = {},
    // loading: rawMaterialReleaseItemReferencesLoading,
  } = useRawMaterialReleaseItemReferencesQuery({
    fetchPolicy: 'network-only',
    skip: !date || !deliveryShiftSchedule,
    variables: {
      data: {
        date,
        deliveryShiftSchedule,
      },
    },
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({
          rawMaterialReleaseItems,
          rawMaterialReleaseItemsFromBreakdowns,
          rawMaterialReleaseForBreakdowns,
          rawMaterialReleasePackagingItems,
          ...data
        }) =>
          await createRawMaterialRelease({
            variables: {
              data: {
                ...prismaCreate(data),

                rawMaterialReleaseForBreakdowns: {
                  create: rawMaterialReleaseForBreakdowns.map(
                    ({
                      rawMaterial,
                      unit,
                      quantity,

                      ...rawMaterialReleaseItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...rawMaterialReleaseItem,
                          quantity,
                        }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
                rawMaterialReleaseItems: {
                  create: [
                    ...rawMaterialReleaseItems.map(
                      ({
                        rawMaterial,
                        unit,
                        quantity,

                        ...rawMaterialReleaseItem
                      }) => {
                        return {
                          ...prismaCreate({
                            ...rawMaterialReleaseItem,
                            quantity,
                          }),
                          rawMaterialProduct: {
                            connect: {
                              unitId_rawMaterialId: {
                                rawMaterialId: rawMaterial?.id || 0,
                                unitId: unit?.id || 0,
                              },
                            },
                          },
                        };
                      }
                    ),
                    ...rawMaterialReleaseItemsFromBreakdowns.map(
                      ({
                        rawMaterial,
                        unit,
                        quantity,

                        ...rawMaterialReleaseItem
                      }) => {
                        return {
                          ...prismaCreate({
                            ...rawMaterialReleaseItem,
                            quantity,
                            fromBreakdown: true,
                          }),
                          rawMaterialProduct: {
                            connect: {
                              unitId_rawMaterialId: {
                                rawMaterialId: rawMaterial?.id || 0,
                                unitId: unit?.id || 0,
                              },
                            },
                          },
                        };
                      }
                    ),
                  ],
                },

                rawMaterialReleasePackagingItems: {
                  create: rawMaterialReleasePackagingItems.map(
                    ({
                      packaging,
                      unit,
                      quantity,
                      ...rawMaterialReleasePackagingItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...rawMaterialReleasePackagingItem,
                          quantity,
                        }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createRawMaterialRelease, { loading: mutationLoading }] =
    useCreateRawMaterialReleaseMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  useEffect(() => {
    if (rawMaterialReleaseForBreakdownReferences) {
      methods.setValue(
        `rawMaterialReleaseForBreakdowns`,
        rawMaterialReleaseForBreakdownReferences.map(
          (rawMaterialReleaseForBreakdownReference) => {
            return {
              ...rawMaterialReleaseForBreakdownReference,
              referenceUnit:
                rawMaterialReleaseForBreakdownReference.referenceUnit.name,
              referenceQuantity:
                rawMaterialReleaseForBreakdownReference.referenceQuantity,
              unit: rawMaterialReleaseForBreakdownReference.referenceUnit,
              quantity: undefined as unknown as number,
            };
          }
        )
      );
    }
  }, [rawMaterialReleaseForBreakdownReferences, methods]);

  useEffect(() => {
    if (rawMaterialReleaseItemReferences) {
      methods.setValue(
        `rawMaterialReleaseItems`,
        rawMaterialReleaseItemReferences.map(
          (rawMaterialReleaseItemReference) => {
            return {
              ...rawMaterialReleaseItemReference,
              referenceUnit: rawMaterialReleaseItemReference.referenceUnit.name,
              referenceQuantity:
                rawMaterialReleaseItemReference.referenceQuantity,
              unit: rawMaterialReleaseItemReference.referenceUnit,
              quantity: undefined as unknown as number,
            };
          }
        )
      );
    }
  }, [rawMaterialReleaseItemReferences, methods]);

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <RawMaterialReleaseForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateRawMaterialReleasePage;
