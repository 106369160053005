import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  usePurchaseOrderQuery,
  useUpdatePurchaseOrderMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import PurchaseOrderForm from './PurchaseOrderForm';
import { FormData, schema } from './utils';

const UpdatePurchaseOrderPage = ({ title = `Update Purchase Order` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        purchaseOrderRawMaterialItems,
        purchaseOrderPackagingItems,

        ...data
      }) => {
        await updatePurchaseOrder({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),

              purchaseOrderRawMaterialItems: {
                update: purchaseOrderRawMaterialItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
              purchaseOrderPackagingItems: {
                update: purchaseOrderPackagingItems.map(({ id, quantity }) => {
                  return {
                    where: {
                      id,
                    },
                    data: {
                      ...prismaUpdate({ quantity }),
                    },
                  };
                }),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { purchaseOrder } = {}, loading } = usePurchaseOrderQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updatePurchaseOrder, { loading: mutationLoading }] =
    useUpdatePurchaseOrderMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (purchaseOrder) {
      const {
        purchaseOrderRawMaterialItems,
        purchaseOrderPackagingItems,

        ...data
      } = purchaseOrder;
      methods.reset({
        ...cleanPayload({
          ...data,
          purchaseOrderRawMaterialItems: purchaseOrderRawMaterialItems.map(
            ({ rawMaterialProduct, ...data }) => {
              return {
                rawMaterial: rawMaterialProduct?.rawMaterial,
                unit: rawMaterialProduct?.unit,
                ...data,
              };
            }
          ),
          purchaseOrderPackagingItems: purchaseOrderPackagingItems.map(
            ({ packagingProduct, ...data }) => {
              return {
                packaging: packagingProduct?.packaging,
                unit: packagingProduct?.unit,
                ...data,
              };
            }
          ),
        }),
      });
    }
  }, [purchaseOrder, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <PurchaseOrderForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdatePurchaseOrderPage;
