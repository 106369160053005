import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  SortOrder,
  Status,
  useCreateRawMaterialFinishingEndingMutation,
  useRawMaterialsAllowQuery,
} from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useEffect } from 'react';
import { useAuthContext } from '../../contexts/authContext';
import { cleanPayload } from '../../utils/apolloClient';
import Loading from '../common/Loading';

import RawMaterialFinishingEndingForm from './RawMaterialFinishingEndingForm';
import { FormData, schema } from './utils';

const CreateRawMaterialFinishingEndingPage = ({
  title = `Create Raw Material Finishing Ending`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const { data: { rawMaterials } = {}, loading: rawMaterialsLoading } =
    useRawMaterialsAllowQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          status: {
            equals: Status.Active,
          },
        },
        orderBy: [
          { rawMaterialCategory: SortOrder.Asc },
          { name: SortOrder.Asc },
        ],
      },
    });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({ rawMaterialFinishingEndingItems, outlet, ...data }) =>
          await createRawMaterialFinishingEnding({
            variables: {
              data: {
                ...prismaCreate(data),
                createdAt: new Date(),
                controlNumber: '',
                ...(outlet && {
                  outlet: {
                    connect: {
                      id: outlet?.id,
                    },
                  },
                }),
                createdBy: currentUser?.name,
                rawMaterialFinishingEndingItems: {
                  create: rawMaterialFinishingEndingItems.map(
                    ({
                      rawMaterial,
                      unit,
                      quantity,
                      ...rawMaterialFinishingEndingRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...rawMaterialFinishingEndingRawMaterialItem,
                          quantity,
                        }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createRawMaterialFinishingEnding, { loading: mutationLoading }] =
    useCreateRawMaterialFinishingEndingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  useEffect(() => {
    if (rawMaterials) {
      methods.reset({
        ...cleanPayload({
          rawMaterialFinishingEndingItems: rawMaterials.map((rawMaterial) => {
            return {
              rawMaterial,
            };
          }),
        }),
      });
    }
  }, [rawMaterials, methods]);

  if (rawMaterialsLoading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <RawMaterialFinishingEndingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateRawMaterialFinishingEndingPage;
