import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useUnitsQuery } from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import Select from '../common/Select';
import RawMaterialAutocomplete from '../shared/RawMaterialAutocomplete';
import RawMaterialProductAutocomplete from '../shared/RawMaterialProductAutocomplete';
import { FormData } from './utils';

const RawMaterialProductRecipeForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rawMaterialProductIngredients',
  });

  const handleAddRawMaterialProductIngredient = () => {
    append({
      unit: null,
      quantity: undefined as unknown as number,
      rawMaterial: null,
    });
  };

  const handleRemoveRawMaterialProductIngredient = (index: number) => {
    remove(index);
  };

  const [rawMaterialProductIngredients] = useWatch({
    control,
    name: ['rawMaterialProductIngredients'],
  });
  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',

            gap: 1,
          }}
        >
          <RawMaterialProductAutocomplete
            name="rawMaterialProduct"
            schema={schema}
            control={control}
            label="Raw Material Product"
            defaultValue={null}
            disabled={type === 'update'}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Product Ingredients
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <RawMaterialAutocomplete
                    name={`rawMaterialProductIngredients.${index}.rawMaterial`}
                    schema={schema}
                    control={control}
                    label="Raw Material"
                    defaultValue={null}
                  />
                  <Controller
                    name={`rawMaterialProductIngredients.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`rawMaterialProductIngredients.${index}.unit`}
                    label="Unit"
                    schema={schema}
                    control={control}
                    arr={
                      rawMaterialProductIngredients?.[
                        index
                      ]?.rawMaterial?.rawMaterialProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleRemoveRawMaterialProductIngredient(index)
                  }
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddRawMaterialProductIngredient}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default RawMaterialProductRecipeForm;
