import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useOutletFinishingQuery,
  useUpdateOutletFinishingMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import OutletFinishingForm from './OutletFinishingForm';

import { FormData, schema } from './utils';

const UpdateOutletFinishingPage = ({ title = `Update Outlet Finishing` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ outletFinishingItems, outlet, ...data }) => {
        const outletFinishingItemsToDelete = getItemsForDelete(
          outletFinishing?.outletFinishingItems,
          outletFinishingItems
        );
        const outletFinishingItemsToUpsert =
          getItemsForUpsert(outletFinishingItems);
        await updateOutletFinishing({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              outletFinishingItems: {
                delete: outletFinishingItemsToDelete,
                upsert: outletFinishingItemsToUpsert.map(
                  ({ id, cmlProduct, ...outletFinishingItem }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(outletFinishingItem),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id || 0,
                          },
                        },
                      },
                      create: {
                        ...prismaCreate(outletFinishingItem),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id || 0,
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { outletFinishing } = {}, loading } = useOutletFinishingQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateOutletFinishing, { loading: mutationLoading }] =
    useUpdateOutletFinishingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (outletFinishing) {
      const { outletFinishingItems, ...data } = outletFinishing;
      methods.reset({
        ...cleanPayload({
          ...data,
          outletFinishingItems: outletFinishingItems.map(
            ({ cmlProduct, ...data }) => {
              return {
                cmlProduct,
                ...data,
              };
            }
          ),
        }),
      });
    }
  }, [outletFinishing, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <OutletFinishingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateOutletFinishingPage;
