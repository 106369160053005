import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateTransferMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useAuthContext } from '../../contexts/authContext';
import TransferForm from './TransferForm';
import { FormData, schema } from './utils';

const CreateTransferPage = ({ title = `Create Transfer` }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuthContext();
  const { handleSnackbarOpen } = useSnackbarContext();

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser)
      methods.handleSubmit(
        async ({
          transferCmlItems,
          transferRawMaterialItems,
          transferPackagingItems,
          fromOutlet,
          toOutlet,
          ...data
        }) =>
          await createTransfer({
            variables: {
              data: {
                ...prismaCreate(data),
                controlNumber: '',
                createdBy: currentUser.name,
                fromOutlet: {
                  connect: {
                    id: fromOutlet?.id,
                  },
                },
                toOutlet: {
                  connect: {
                    id: toOutlet?.id,
                  },
                },
                transferCmlItems: {
                  create: transferCmlItems.map(
                    ({ cmlProduct, quantity, ...transferCmlItem }) => {
                      return {
                        ...prismaCreate({
                          ...transferCmlItem,
                          quantity,
                        }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      };
                    }
                  ),
                },
                transferRawMaterialItems: {
                  create: transferRawMaterialItems.map(
                    ({
                      rawMaterial,
                      unit,
                      quantity,
                      ...transferRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...transferRawMaterialItem,
                          quantity,
                        }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
                transferPackagingItems: {
                  create: transferPackagingItems.map(
                    ({
                      packaging,
                      unit,
                      quantity,
                      ...transferPackagingItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...transferPackagingItem,
                          quantity,
                        }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
  };

  const [createTransfer, { loading: mutationLoading }] =
    useCreateTransferMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <TransferForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateTransferPage;
