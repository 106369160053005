import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useAuthContext } from '../../contexts/authContext';
import {
  DeliveryShiftSchedule,
  UserGroup,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import { FormData } from './utils';
import { useEffect } from 'react';

const DeliveryReceiptForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { currentUser } = useAuthContext();
  const { control } = useFormContext<FormData>();
  const { fields: deliveryReceiptItemsFields } = useFieldArray({
    control,
    name: 'deliveryReceiptItems',
  });

  const [
    controlNumber,
    deliveryReceiptItems,
    createdBy,
    receivedDeliveryReceipt,
    receivedByDriver,
  ] = useWatch({
    control,
    name: [
      'controlNumber',
      'deliveryReceiptItems',
      'createdBy',
      'receivedDeliveryReceipt',
      'receivedByDriver',
    ],
  });

  useEffect(() => {
    const form = document.querySelector('form') as HTMLFormElement;
    const elements = form.elements as HTMLFormControlsCollection;
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLInputElement;
      if (
        currentUser?.userGroups.includes(UserGroup.Driver) &&
        element.tagName === 'INPUT' &&
        element.name !== 'receivedByDriver'
      ) {
        element.disabled = true;
      }
    }
  }, []);

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      {type === 'update' && (
        <Typography variant="subtitle2">{controlNumber}</Typography>
      )}
      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                disabled={type === 'update'}
              />
            )}
          />
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
            disabled={type === 'update'}
          />
          <OutletAutocomplete
            name={`outlet`}
            schema={schema}
            control={control}
            label="Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />
          <Controller
            name="basketReleasedQuantity"
            control={control}
            defaultValue={undefined}
            render={({ field, fieldState }) => (
              <TextField
                label="Basket Released Qty"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            )}
          />
          <Controller
            name="screenSeparatorReleasedQuantity"
            control={control}
            defaultValue={undefined}
            render={({ field, fieldState }) => (
              <TextField
                label="Screen Separator Qty"
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          {type === 'update' && (
            <>
              <TextField
                label="Created By"
                fullWidth
                value={createdBy ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Received By in Outlet"
                fullWidth
                value={receivedDeliveryReceipt?.receivedBy ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Controller
                name="receivedByDriver"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    label="Received By Driver"
                    {...textFieldHelperProps(field, fieldState, schema)}
                    {...field}
                    disabled={
                      !currentUser?.userGroups?.includes(UserGroup.Superuser) ||
                      receivedDeliveryReceipt?.receivedBy == null
                    }
                  />
                )}
              />
            </>
          )}
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Frying Time
        </Typography>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="ydmFryingTime"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="YDM Frying Time"
                type="time"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="yrcFryingTime"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="YRC Frying Time"
                type="time"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="chmFryingTime"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="CHM Frying Time"
                type="time"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="cdmFryingTime"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="CDM Frying Time"
                type="time"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="pymFryingTime"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="PYM Frying Time"
                type="time"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {deliveryReceiptItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Donut Mix and Cut ${index + 1}`}
                    fullWidth
                    value={
                      (deliveryReceiptItems &&
                        deliveryReceiptItems[index]?.cmlProduct?.donutMixAndCut
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`CML Product ${index + 1}`}
                    fullWidth
                    value={
                      (deliveryReceiptItems &&
                        deliveryReceiptItems[index]?.cmlProduct?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />

                  <Controller
                    name={`deliveryReceiptItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default DeliveryReceiptForm;
