import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useSdsDeliveryReceiptQuery,
  useUpdateSdsDeliveryReceiptMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import SdsDeliveryReceiptForm from './SdsDeliveryReceiptForm';
import { FormData, schema } from './utils';

const UpdateSdsDeliveryReceiptPage = ({
  title = `Update SDS Delivery Receipt`,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        sdsDeliveryReceiptRawMaterialItems,
        sdsDeliveryReceiptPackagingItems,
        outlet,
        packagingRequest,
        rawMaterialRequest,
        ...data
      }) => {
        await updateSdsDeliveryReceipt({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              ...(packagingRequest
                ? {
                    packagingRequest: {
                      connect: {
                        id: packagingRequest?.id,
                      },
                    },
                  }
                : {
                    packagingRequest: {
                      disconnect: true,
                    },
                  }),
              ...(rawMaterialRequest
                ? {
                    rawMaterialRequest: {
                      connect: {
                        id: rawMaterialRequest?.id,
                      },
                    },
                  }
                : {
                    rawMaterialRequest: {
                      disconnect: true,
                    },
                  }),
              sdsDeliveryReceiptRawMaterialItems: {
                update: sdsDeliveryReceiptRawMaterialItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
              sdsDeliveryReceiptPackagingItems: {
                update: sdsDeliveryReceiptPackagingItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { sdsDeliveryReceipt } = {}, loading } =
    useSdsDeliveryReceiptQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    });

  const [updateSdsDeliveryReceipt, { loading: mutationLoading }] =
    useUpdateSdsDeliveryReceiptMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (sdsDeliveryReceipt) {
      const {
        sdsDeliveryReceiptRawMaterialItems,
        sdsDeliveryReceiptPackagingItems,

        ...data
      } = sdsDeliveryReceipt;
      if (data?.deliveredBy) {
        handleSnackbarOpen({
          message: 'This form has been received and can no longer be updated.',
          severity: 'error',
        });
        navigate(-1);
      }
      methods.reset({
        ...cleanPayload({
          ...data,
          sdsDeliveryReceiptRawMaterialItems:
            sdsDeliveryReceiptRawMaterialItems.map(
              ({ rawMaterialProduct, ...data }) => {
                return {
                  rawMaterial: rawMaterialProduct?.rawMaterial,
                  unit: rawMaterialProduct?.unit,
                  ...data,
                };
              }
            ),
          sdsDeliveryReceiptPackagingItems:
            sdsDeliveryReceiptPackagingItems.map(
              ({ packagingProduct, ...data }) => {
                return {
                  packaging: packagingProduct?.packaging,
                  unit: packagingProduct?.unit,
                  ...data,
                };
              }
            ),
        }),
      });
    }
  }, [sdsDeliveryReceipt, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <SdsDeliveryReceiptForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateSdsDeliveryReceiptPage;
