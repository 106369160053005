import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  PackagingCategory,
  PackagingRequestsDocument,
  RawMaterialCategory,
  RawMaterialRequestsDocument,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import Select from '../common/Select';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import PackagingAutocomplete from '../shared/PackagingAutocomplete';
import RawMaterialAutocomplete from '../shared/RawMaterialAutocomplete';
import { FormData } from './utils';

const SdsDeliveryReceiptForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const {
    fields: sdsDeliveryReceiptRawMaterialItemsFields,
    append: appendSdsDeliveryReceiptRawMaterialItems,
    remove: removeSdsDeliveryReceiptRawMaterialItems,
  } = useFieldArray({
    control,
    name: 'sdsDeliveryReceiptRawMaterialItems',
  });

  const {
    fields: sdsDeliveryReceiptPackagingItemsFields,
    append: appendSdsDeliveryReceiptPackagingItems,
    remove: removeSdsDeliveryReceiptPackagingItems,
  } = useFieldArray({
    control,
    name: 'sdsDeliveryReceiptPackagingItems',
  });

  const handleAddSdsDeliveryReceiptPackagingItems = () => {
    appendSdsDeliveryReceiptPackagingItems({
      packaging: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveSdsDeliveryReceiptPackagingItems = (index: number) => {
    removeSdsDeliveryReceiptPackagingItems(index);
  };
  const handleAddSdsDeliveryReceiptRawMaterialItem = () => {
    appendSdsDeliveryReceiptRawMaterialItems({
      rawMaterial: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveSdsDeliveryReceiptRawMaterialItem = (index: number) => {
    removeSdsDeliveryReceiptRawMaterialItems(index);
  };

  const [sdsDeliveryReceiptRawMaterialItems, sdsDeliveryReceiptPackagingItems] =
    useWatch({
      control,
      name: [
        'sdsDeliveryReceiptRawMaterialItems',
        'sdsDeliveryReceiptPackagingItems',
      ],
    });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <OutletAutocomplete
            name={`outlet`}
            schema={schema}
            control={control}
            label="Outlet"
            defaultValue={null}
          />
          <Controller
            name="packagingRequest"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Packaging Request No.',
                }}
                queryOptions={(value) => {
                  return {
                    query: PackagingRequestsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            controlNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.controlNumber}
                value={field.value}
                onChange={(e, value: any) => {
                  console.log(value);
                  setValue(
                    'sdsDeliveryReceiptPackagingItems',
                    value?.packagingRequestItems?.map(
                      (packagingRequestItem: any) => {
                        return {
                          packaging:
                            packagingRequestItem?.packagingProduct?.packaging,
                          referenceQuantity: packagingRequestItem?.quantity,
                          referenceUnit:
                            packagingRequestItem?.packagingProduct?.unit?.name,
                        };
                      }
                    )
                  );
                  field.onChange(value);
                }}
              />
            )}
          />
          <Controller
            name="rawMaterialRequest"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Raw Material Request No.',
                }}
                queryOptions={(value) => {
                  return {
                    query: RawMaterialRequestsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            controlNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.controlNumber}
                value={field.value}
                onChange={(e, value: any) => {
                  setValue(
                    'sdsDeliveryReceiptRawMaterialItems',
                    value?.rawMaterialRequestItems?.map(
                      (rawMaterialRequestItem: any) => {
                        return {
                          rawMaterial:
                            rawMaterialRequestItem?.rawMaterialProduct
                              ?.rawMaterial,
                          referenceQuantity: rawMaterialRequestItem?.quantity,
                          referenceUnit:
                            rawMaterialRequestItem?.rawMaterialProduct?.unit
                              ?.name,
                        };
                      }
                    )
                  );
                  field.onChange(value);
                }}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {sdsDeliveryReceiptPackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <PackagingAutocomplete
                    name={`sdsDeliveryReceiptPackagingItems.${index}.packaging`}
                    schema={schema}
                    control={control}
                    label={`Packaging ${index + 1}`}
                    defaultValue={null}
                  />
                  <TextField
                    label={`Reference Quantity ${index + 1}`}
                    fullWidth
                    value={
                      (sdsDeliveryReceiptPackagingItems &&
                        sdsDeliveryReceiptPackagingItems[index]
                          ?.referenceQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Reference Unit ${index + 1}`}
                    fullWidth
                    value={
                      (sdsDeliveryReceiptPackagingItems &&
                        sdsDeliveryReceiptPackagingItems[index]
                          ?.referenceUnit) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`sdsDeliveryReceiptPackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`sdsDeliveryReceiptPackagingItems.${index}.unit`}
                    label="Unit"
                    schema={schema}
                    control={control}
                    arr={
                      sdsDeliveryReceiptPackagingItems?.[
                        index
                      ]?.packaging?.packagingProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    disabled={
                      !sdsDeliveryReceiptPackagingItems?.[index]?.packaging
                    }
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleRemoveSdsDeliveryReceiptPackagingItems(index)
                  }
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddSdsDeliveryReceiptPackagingItems}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {sdsDeliveryReceiptRawMaterialItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <RawMaterialAutocomplete
                    name={`sdsDeliveryReceiptRawMaterialItems.${index}.rawMaterial`}
                    schema={schema}
                    control={control}
                    label="Raw Material"
                    defaultValue={null}
                  />
                  <TextField
                    label={`Reference Quantity ${index + 1}`}
                    fullWidth
                    value={
                      (sdsDeliveryReceiptRawMaterialItems &&
                        sdsDeliveryReceiptRawMaterialItems[index]
                          ?.referenceQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Reference Unit ${index + 1}`}
                    fullWidth
                    value={
                      (sdsDeliveryReceiptRawMaterialItems &&
                        sdsDeliveryReceiptRawMaterialItems[index]
                          ?.referenceUnit) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />

                  <Controller
                    name={`sdsDeliveryReceiptRawMaterialItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`sdsDeliveryReceiptRawMaterialItems.${index}.unit`}
                    label="Unit"
                    schema={schema}
                    control={control}
                    arr={
                      sdsDeliveryReceiptRawMaterialItems?.[
                        index
                      ]?.rawMaterial?.rawMaterialProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    disabled={
                      !sdsDeliveryReceiptRawMaterialItems?.[index]?.rawMaterial
                    }
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleRemoveSdsDeliveryReceiptRawMaterialItem(index)
                  }
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddSdsDeliveryReceiptRawMaterialItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default SdsDeliveryReceiptForm;
