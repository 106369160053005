import { z } from 'zod';


export const schema = z.object({
  dateArrived: z.coerce.date(),
  salesInvoiceNumber: z.string().trim().min(1),
  purchaseOrderRawMaterialItems: z.array(z.object({
    id: z.coerce.number().optional(),
    referenceQuantity: z.number().nullish(),
    referenceUnit: z.string().nullish(),
    rawMaterial: z.object({ 
      id: z.coerce.number(),

      name: z.string().trim().min(1),
      rawMaterialProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),

    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  purchaseOrderPackagingItems: z.array(z.object({
    id: z.coerce.number().optional(),
    referenceQuantity: z.number().nullish(),
    referenceUnit: z.string().nullish(),
    packaging: z.object({ 
      id: z.coerce.number(),

      name: z.string().trim().min(1),
      packagingProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number())
  }))
})

export type FormData = z.infer<typeof schema>




