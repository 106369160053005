import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateBetweenEntityTransferMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import BetweenEntityTransferForm from './BetweenEntityTransferForm';
import { FormData, schema } from './utils';

const CreateBetweenEntityTransferPage = ({
  title = `Create Between Entity Transfer`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({
          betweenEntityTransferRawMaterialItems,
          betweenEntityTransferPackagingItems,
          ...data
        }) =>
          await createBetweenEntityTransfer({
            variables: {
              data: {
                ...prismaCreate(data),
                controlNumber: '',
                createdBy: currentUser.name,
                betweenEntityTransferRawMaterialItems: {
                  create: betweenEntityTransferRawMaterialItems.map(
                    ({
                      rawMaterial,
                      unit,
                      quantity,

                      ...betweenEntityTransferRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...betweenEntityTransferRawMaterialItem,
                          quantity,
                        }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
                betweenEntityTransferPackagingItems: {
                  create: betweenEntityTransferPackagingItems.map(
                    ({
                      packaging,
                      unit,
                      quantity,

                      ...betweenEntityTransferPackagingItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...betweenEntityTransferPackagingItem,
                          quantity,
                        }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createBetweenEntityTransfer, { loading: mutationLoading }] =
    useCreateBetweenEntityTransferMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <BetweenEntityTransferForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateBetweenEntityTransferPage;
