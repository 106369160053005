import { z } from 'zod';
import { NumberOfShifts, Status } from '../../graphql/generated/types';


export const schema = z.object({
  name: z.string().trim().min(1),
  status: z.nativeEnum(Status),
  numberOfShifts: z.nativeEnum(NumberOfShifts),
}).required();



export type FormData = z.infer<typeof schema>