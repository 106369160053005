import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useEndingSdEmTaQuery,
  useUpdateEndingSdEmTaMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import EndingSdEmTaForm from './EndingSdEmTaForm';
import { FormData, schema } from './utils';

const UpdateEndingSdEmTaPage = ({ title = `Update Ending SD EM TA` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        endingSdEmTaCmlItems,
        endingSdEmTaRawMaterialItems,
        endingSdEmTaPackagingItems,
        endingSdEmTaBeverageItems,
        outlet,
        ...data
      }) => {
        const endingSdEmTaCmlItemsToDelete = getItemsForDelete(
          endingSdEmTa?.endingSdEmTaCmlItems,
          endingSdEmTaCmlItems
        );
        const endingSdEmTaCmlItemsToUpsert =
          getItemsForUpsert(endingSdEmTaCmlItems);

        const endingSdEmTaBeverageItemsToDelete = getItemsForDelete(
          endingSdEmTa?.endingSdEmTaBeverageItems,
          endingSdEmTaBeverageItems
        );
        const endingSdEmTaBeverageItemsToUpsert = getItemsForUpsert(
          endingSdEmTaBeverageItems
        );

        const endingSdEmTaRawMaterialItemsToDelete = getItemsForDelete(
          endingSdEmTa?.endingSdEmTaRawMaterialItems,
          endingSdEmTaRawMaterialItems
        );
        const endingSdEmTaRawMaterialItemsToUpsert = getItemsForUpsert(
          endingSdEmTaRawMaterialItems
        );

        const endingSdEmTaPackagingItemsToDelete = getItemsForDelete(
          endingSdEmTa?.endingSdEmTaPackagingItems,
          endingSdEmTaPackagingItems
        );
        const endingSdEmTaPackagingItemsToUpsert = getItemsForUpsert(
          endingSdEmTaPackagingItems
        );

        await updateEndingSdEmTa({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              endingSdEmTaCmlItems: {
                delete: endingSdEmTaCmlItemsToDelete,
                upsert: endingSdEmTaCmlItemsToUpsert.map(
                  ({ id, cmlProduct, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      },
                    };
                  }
                ),
              },
              endingSdEmTaRawMaterialItems: {
                delete: endingSdEmTaRawMaterialItemsToDelete,
                upsert: endingSdEmTaRawMaterialItemsToUpsert.map(
                  ({ id, rawMaterial, unit, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
              endingSdEmTaPackagingItems: {
                delete: endingSdEmTaPackagingItemsToDelete,
                upsert: endingSdEmTaPackagingItemsToUpsert.map(
                  ({ id, packaging, unit, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
              endingSdEmTaBeverageItems: {
                delete: endingSdEmTaBeverageItemsToDelete,
                upsert: endingSdEmTaBeverageItemsToUpsert.map(
                  ({ id, beverage, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity }),
                        beverage: {
                          connect: {
                            id: beverage?.id,
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity }),
                        beverage: {
                          connect: {
                            id: beverage?.id,
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { endingSdEmTa } = {}, loading } = useEndingSdEmTaQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateEndingSdEmTa, { loading: mutationLoading }] =
    useUpdateEndingSdEmTaMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (endingSdEmTa) {
      const {
        endingSdEmTaRawMaterialItems,
        endingSdEmTaPackagingItems,
        ...data
      } = endingSdEmTa;
      methods.reset({
        ...cleanPayload({
          ...data,
          endingSdEmTaRawMaterialItems: endingSdEmTaRawMaterialItems.map(
            ({ rawMaterialProduct, ...endingSdEmTaRawMaterialItem }) => {
              return {
                rawMaterial: rawMaterialProduct?.rawMaterial,
                unit: rawMaterialProduct?.unit,
                ...endingSdEmTaRawMaterialItem,
              };
            }
          ),
          endingSdEmTaPackagingItems: endingSdEmTaPackagingItems.map(
            ({ packagingProduct, ...endingSdEmTaPackagingItem }) => {
              return {
                packaging: packagingProduct?.packaging,
                unit: packagingProduct?.unit,
                ...endingSdEmTaPackagingItem,
              };
            }
          ),
        }),
      });
    }
  }, [endingSdEmTa, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <EndingSdEmTaForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateEndingSdEmTaPage;
