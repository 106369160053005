import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import Select from '../common/Select';
import PackagingAutocomplete from '../shared/PackagingAutocomplete';
import RawMaterialAutocomplete from '../shared/RawMaterialAutocomplete';
import { FormData } from './utils';

const PurchaseOrderForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const {
    fields: purchaseOrderRawMaterialItemsFields,
    append: appendPurchaseOrderRawMaterialItems,
    remove: removePurchaseOrderRawMaterialItems,
  } = useFieldArray({
    control,
    name: 'purchaseOrderRawMaterialItems',
  });

  const {
    fields: purchaseOrderPackagingItemsFields,
    append: appendPurchaseOrderPackagingItems,
    remove: removePurchaseOrderPackagingItems,
  } = useFieldArray({
    control,
    name: 'purchaseOrderPackagingItems',
  });

  const handleAddPurchaseOrderPackagingItems = () => {
    appendPurchaseOrderPackagingItems({
      packaging: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemovePurchaseOrderPackagingItems = (index: number) => {
    removePurchaseOrderPackagingItems(index);
  };
  const handleAddPurchaseOrderRawMaterialItem = () => {
    appendPurchaseOrderRawMaterialItems({
      rawMaterial: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemovePurchaseOrderRawMaterialItem = (index: number) => {
    removePurchaseOrderRawMaterialItems(index);
  };

  const [purchaseOrderRawMaterialItems, purchaseOrderPackagingItems] = useWatch(
    {
      control,
      name: ['purchaseOrderRawMaterialItems', 'purchaseOrderPackagingItems'],
    }
  );

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="dateArrived"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Arrived"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="salesInvoiceNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Sales Invoice Number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {purchaseOrderPackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <PackagingAutocomplete
                    name={`purchaseOrderPackagingItems.${index}.packaging`}
                    schema={schema}
                    control={control}
                    label={`Packaging ${index + 1}`}
                    defaultValue={null}
                    onChange={(value: any) => {
                      setValue(
                        `purchaseOrderPackagingItems.${index}.unit`,
                        value?.packagingProducts?.find(
                          ({ baseUnit }: { baseUnit: boolean }) =>
                            baseUnit === true
                        )?.unit || null
                      );
                    }}
                  />
                  <Controller
                    name={`purchaseOrderPackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`purchaseOrderPackagingItems.${index}.unit`}
                    label="Unit"
                    schema={schema}
                    control={control}
                    arr={
                      purchaseOrderPackagingItems?.[
                        index
                      ]?.packaging?.packagingProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    disabled={!purchaseOrderPackagingItems?.[index]?.packaging}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemovePurchaseOrderPackagingItems(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddPurchaseOrderPackagingItems}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {purchaseOrderRawMaterialItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <RawMaterialAutocomplete
                    name={`purchaseOrderRawMaterialItems.${index}.rawMaterial`}
                    schema={schema}
                    control={control}
                    label="Raw Material"
                    defaultValue={null}
                    onChange={(value: any) => {
                      setValue(
                        `purchaseOrderRawMaterialItems.${index}.unit`,
                        value?.rawMaterialProducts?.find(
                          ({ baseUnit }: { baseUnit: boolean }) =>
                            baseUnit === true
                        )?.unit || null
                      );
                    }}
                  />

                  <Controller
                    name={`purchaseOrderRawMaterialItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`purchaseOrderRawMaterialItems.${index}.unit`}
                    label="Unit"
                    schema={schema}
                    control={control}
                    arr={
                      purchaseOrderRawMaterialItems?.[
                        index
                      ]?.rawMaterial?.rawMaterialProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    disabled={
                      !purchaseOrderRawMaterialItems?.[index]?.rawMaterial
                    }
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleRemovePurchaseOrderRawMaterialItem(index)
                  }
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddPurchaseOrderRawMaterialItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default PurchaseOrderForm;
