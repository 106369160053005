import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useCrtQuery,
  useUpdateCrtMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import CrtForm from './CrtForm';
import { FormData, schema } from './utils';
import Loading from '../common/Loading';

const UpdateCrtPage = ({ title = `Update Crt` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        crtCmlItems,
        crtRawMaterialItems,
        crtPackagingItems,
        crtBeverageItems,
        outlet,
        ...data
      }) => {
        await updateCrt({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              crtCmlItems: {
                update: crtCmlItems.map(({ id, quantity }) => {
                  return {
                    where: {
                      id,
                    },
                    data: {
                      ...prismaUpdate({ quantity }),
                    },
                  };
                }),
              },
              crtRawMaterialItems: {
                update: crtRawMaterialItems.map(({ id, quantity }) => {
                  return {
                    where: {
                      id,
                    },
                    data: {
                      ...prismaUpdate({ quantity }),
                    },
                  };
                }),
              },
              crtPackagingItems: {
                update: crtPackagingItems.map(({ id, quantity }) => {
                  return {
                    where: {
                      id,
                    },
                    data: {
                      ...prismaUpdate({ quantity }),
                    },
                  };
                }),
              },
              crtBeverageItems: {
                update: crtBeverageItems.map(
                  ({ id, cupsSoldMedium, cupsSoldLarge, cupsSoldXl }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({
                          cupsSoldMedium,
                          cupsSoldLarge,
                          cupsSoldXl,
                        }),
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { crt } = {}, loading } = useCrtQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateCrt, { loading: mutationLoading }] = useUpdateCrtMutation({
    onError: (error) =>
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' success!',
        severity: 'success',
      });
      navigate(-1);
    },
  });

  useEffect(() => {
    if (crt) {
      const { crtRawMaterialItems, crtPackagingItems, ...data } = crt;
      methods.reset({
        ...cleanPayload({
          ...data,
          crtRawMaterialItems: crtRawMaterialItems.map(
            ({ rawMaterialProduct, ...cmlProductIngredient }) => {
              return {
                rawMaterialProduct,
                ...cmlProductIngredient,
              };
            }
          ),
          crtPackagingItems: crtPackagingItems.map(
            ({ packagingProduct, ...cmlProductIngredient }) => {
              return {
                packagingProduct,
                ...cmlProductIngredient,
              };
            }
          ),
        }),
      });
    }
  }, [crt, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <CrtForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateCrtPage;
