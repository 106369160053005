import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { DeliveryReceiptsDropdownDocument } from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import { FormData } from './utils';

const ReceivedDeliveryReceiptForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields: receivedDeliveryReceiptItemsFields } = useFieldArray({
    control,
    name: 'receivedDeliveryReceiptItems',
  });

  const [receivedDeliveryReceiptItems, receivedBy, outlet] = useWatch({
    control,
    name: ['receivedDeliveryReceiptItems', 'receivedBy', 'outlet'],
  });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="dateTime"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Time"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          {type === 'update' && (
            <TextField
              label={`Received By`}
              fullWidth
              value={receivedBy ?? ''}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          <OutletAutocomplete
            name={`outlet`}
            schema={schema}
            control={control}
            label="Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />

          <Controller
            name="deliveryReceipt"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'DR Control No.',
                }}
                disabled={type === 'update' || !outlet}
                queryOptions={(value) => {
                  return {
                    query: DeliveryReceiptsDropdownDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            controlNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                        outlet: {
                          is: {
                            id: {
                              equals: outlet?.id,
                            },
                          },
                        },

                        receivedDeliveryReceipt: {
                          isNot: {
                            id: {
                              gt: 0,
                            },
                          },
                        },
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.controlNumber}
                value={field.value}
                onChange={(e, value: any) => {
                  setValue(
                    `receivedDeliveryReceiptItems`,
                    value?.deliveryReceiptItems.map(
                      (deliveryReceiptItem: any) => {
                        return {
                          deliveryReceiptItem,
                        };
                      }
                    )
                  );

                  field.onChange(value);
                }}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          CML Product Items
        </Typography>

        <Stack direction="column" spacing={1}>
          {receivedDeliveryReceiptItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Donut Mix and Cut ${index + 1}`}
                    fullWidth
                    value={
                      (receivedDeliveryReceiptItems &&
                        receivedDeliveryReceiptItems[index]?.deliveryReceiptItem
                          ?.cmlProduct?.donutMixAndCut?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`CML Product ${index + 1}`}
                    fullWidth
                    value={
                      (receivedDeliveryReceiptItems &&
                        receivedDeliveryReceiptItems[index]?.deliveryReceiptItem
                          ?.cmlProduct?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (receivedDeliveryReceiptItems &&
                        receivedDeliveryReceiptItems[index]?.deliveryReceiptItem
                          ?.cmlProduct?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Quantity ${index + 1}`}
                    fullWidth
                    value={
                      (receivedDeliveryReceiptItems &&
                        receivedDeliveryReceiptItems[index]?.deliveryReceiptItem
                          ?.quantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`receivedDeliveryReceiptItems.${index}.receivedQuantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Received"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Controller
                    name={`receivedDeliveryReceiptItems.${index}.rejectedQuantity`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Rejected"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ReceivedDeliveryReceiptForm;
