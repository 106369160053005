import {
  AggregateVarianceReportOnSdsRawMaterialItemDocument,
  DeliveryShiftSchedule,
  SortOrder,
  VarianceReportOnSdsRawMaterialItemWhereInput,
  useVarianceReportOnSdsRawMaterialItemsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const VarianceReportOnSdsRawMaterialItemsPage = ({
  title = 'Variance Report On Sds Raw Material Items',
}) => {
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: VarianceReportOnSdsRawMaterialItemWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          startDate: startDateQueryString,
          endDate: endDateQueryString,
        }
      : {},

    typeof searchQueryString === 'string'
      ? {
          searchTerm: searchQueryString,
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { varianceReportOnSdsRawMaterialItems } = {},
    loading,
    error,
  } = useVarianceReportOnSdsRawMaterialItemsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const columns: Columns<
    Paths<
      ObjectFromArray<NonNullable<typeof varianceReportOnSdsRawMaterialItems>>
    >
  > = [
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Outlet',
      accessor: 'outlet',
    },
    {
      header: 'Delivery Shift Schedule',
      accessor: 'deliveryShiftSchedule',
      Cell: (children) => getEnumKeyFromValue(DeliveryShiftSchedule, children),
    },
    {
      header: 'Delivery Receipt No.',
      accessor: 'deliveryReceiptNumber',
    },
    {
      header: 'Received By',
      accessor: 'receivedBy',
    },
    {
      header: 'Category',
      accessor: 'category',
    },
    {
      header: 'Raw Material',
      accessor: 'rawMaterial',
    },
    {
      header: 'Quantity',
      accessor: 'quantity',
    },
    {
      header: 'Unit',
      accessor: 'unit',
    },
    {
      header: 'Received',
      accessor: 'received',
    },
    {
      header: 'Rejected',
      accessor: 'rejected',
    },
    {
      header: 'Variance',
      accessor: 'variance',
    },
  ];

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={
        <DataTable
          data={varianceReportOnSdsRawMaterialItems}
          columns={columns}
        />
      }
      pagination={
        <Pagination
          query={AggregateVarianceReportOnSdsRawMaterialItemDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default VarianceReportOnSdsRawMaterialItemsPage;
