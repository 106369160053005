import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  DeliveryShiftSchedule,
  PackagingCategory,
  RawMaterialCategory,
  SdsDeliveryReceiptsDropdownDocument,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import { FormData } from './utils';

const ReceivedSdsDeliveryReceiptForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields: receivedSdsDeliveryReceiptRawMaterialItemsFields } =
    useFieldArray({
      control,
      name: 'receivedSdsDeliveryReceiptRawMaterialItems',
    });

  const { fields: receivedSdsDeliveryReceiptPackagingItemsFields } =
    useFieldArray({
      control,
      name: 'receivedSdsDeliveryReceiptPackagingItems',
    });

  const [
    receivedSdsDeliveryReceiptRawMaterialItems,
    receivedSdsDeliveryReceiptPackagingItems,
    receivedBy,
    outlet,
  ] = useWatch({
    control,
    name: [
      'receivedSdsDeliveryReceiptRawMaterialItems',
      'receivedSdsDeliveryReceiptPackagingItems',
      'receivedBy',
      'outlet',
    ],
  });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="dateTime"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Time"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          {type === 'update' && (
            <TextField
              label={`Received By`}
              fullWidth
              value={receivedBy ?? ''}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          <OutletAutocomplete
            name={`outlet`}
            schema={schema}
            control={control}
            label="Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
            disabled={type === 'update'}
          />
          <Controller
            name="sdsDeliveryReceipt"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'SDS DR Control No.',
                }}
                disabled={type === 'update' || !outlet}
                queryOptions={(value) => {
                  return {
                    query: SdsDeliveryReceiptsDropdownDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            controlNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                        outlet: {
                          is: {
                            id: {
                              equals: outlet?.id,
                            },
                          },
                        },
                        receivedSdsDeliveryReceipt: {
                          isNot: {
                            id: {
                              gt: 0,
                            },
                          },
                        },
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.controlNumber}
                value={field.value}
                onChange={(e, value: any) => {
                  setValue(
                    `receivedSdsDeliveryReceiptRawMaterialItems`,
                    value?.sdsDeliveryReceiptRawMaterialItems.map(
                      (sdsDeliveryReceiptRawMaterialItem: any) => {
                        return {
                          sdsDeliveryReceiptRawMaterialItem,
                        };
                      }
                    )
                  );
                  setValue(
                    `receivedSdsDeliveryReceiptPackagingItems`,
                    value?.sdsDeliveryReceiptPackagingItems.map(
                      (sdsDeliveryReceiptPackagingItem: any) => {
                        return {
                          sdsDeliveryReceiptPackagingItem,
                        };
                      }
                    )
                  );
                  field.onChange(value);
                }}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {receivedSdsDeliveryReceiptRawMaterialItemsFields.map(
            (field, index) => {
              return (
                <Box
                  key={field.id}
                  sx={{
                    ...nestedGrid,
                  }}
                >
                  <Box>
                    <TextField
                      label={`Category ${index + 1}`}
                      fullWidth
                      value={
                        getEnumKeyFromValue(
                          RawMaterialCategory,
                          receivedSdsDeliveryReceiptRawMaterialItems &&
                            receivedSdsDeliveryReceiptRawMaterialItems[index]
                              ?.sdsDeliveryReceiptRawMaterialItem
                              ?.rawMaterialProduct?.rawMaterial
                              ?.rawMaterialCategory
                        ) ?? ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label={`Raw Material ${index + 1}`}
                      fullWidth
                      value={
                        (receivedSdsDeliveryReceiptRawMaterialItems &&
                          receivedSdsDeliveryReceiptRawMaterialItems[index]
                            ?.sdsDeliveryReceiptRawMaterialItem
                            ?.rawMaterialProduct?.rawMaterial?.name) ??
                        ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label={`Unit ${index + 1}`}
                      fullWidth
                      value={
                        (receivedSdsDeliveryReceiptRawMaterialItems &&
                          receivedSdsDeliveryReceiptRawMaterialItems[index]
                            ?.sdsDeliveryReceiptRawMaterialItem
                            ?.rawMaterialProduct?.unit?.name) ??
                        ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label={`Quantity ${index + 1}`}
                      fullWidth
                      value={
                        (receivedSdsDeliveryReceiptRawMaterialItems &&
                          receivedSdsDeliveryReceiptRawMaterialItems[index]
                            ?.sdsDeliveryReceiptRawMaterialItem?.quantity) ??
                        ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Controller
                      name={`receivedSdsDeliveryReceiptRawMaterialItems.${index}.receivedQuantity`}
                      control={control}
                      defaultValue={undefined}
                      render={({ field, fieldState }) => (
                        <TextField
                          label="Received"
                          {...textFieldHelperProps(field, fieldState, schema)}
                          {...field}
                          type="number"
                          onWheel={(e) => (e.target as HTMLElement).blur()}
                        />
                      )}
                    />
                    <Controller
                      name={`receivedSdsDeliveryReceiptRawMaterialItems.${index}.rejectedQuantity`}
                      control={control}
                      defaultValue={0}
                      render={({ field, fieldState }) => (
                        <TextField
                          label="Rejected"
                          {...textFieldHelperProps(field, fieldState, schema)}
                          {...field}
                          type="number"
                          onWheel={(e) => (e.target as HTMLElement).blur()}
                        />
                      )}
                    />
                  </Box>
                </Box>
              );
            }
          )}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {receivedSdsDeliveryReceiptPackagingItemsFields.map(
            (field, index) => {
              return (
                <Box
                  key={field.id}
                  sx={{
                    ...nestedGrid,
                  }}
                >
                  <Box>
                    <TextField
                      label={`Category ${index + 1}`}
                      fullWidth
                      value={
                        getEnumKeyFromValue(
                          PackagingCategory,
                          receivedSdsDeliveryReceiptPackagingItems &&
                            receivedSdsDeliveryReceiptPackagingItems[index]
                              ?.sdsDeliveryReceiptPackagingItem
                              ?.packagingProduct?.packaging?.packagingCategory
                        ) ?? ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label={`Packaging ${index + 1}`}
                      fullWidth
                      value={
                        (receivedSdsDeliveryReceiptPackagingItems &&
                          receivedSdsDeliveryReceiptPackagingItems[index]
                            ?.sdsDeliveryReceiptPackagingItem?.packagingProduct
                            ?.packaging?.name) ??
                        ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label={`Unit ${index + 1}`}
                      fullWidth
                      value={
                        (receivedSdsDeliveryReceiptPackagingItems &&
                          receivedSdsDeliveryReceiptPackagingItems[index]
                            ?.sdsDeliveryReceiptPackagingItem?.packagingProduct
                            ?.unit?.name) ??
                        ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label={`Quantity ${index + 1}`}
                      fullWidth
                      value={
                        (receivedSdsDeliveryReceiptPackagingItems &&
                          receivedSdsDeliveryReceiptPackagingItems[index]
                            ?.sdsDeliveryReceiptPackagingItem?.quantity) ??
                        ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Controller
                      name={`receivedSdsDeliveryReceiptPackagingItems.${index}.receivedQuantity`}
                      control={control}
                      defaultValue={undefined}
                      render={({ field, fieldState }) => (
                        <TextField
                          label="Received"
                          {...textFieldHelperProps(field, fieldState, schema)}
                          {...field}
                          type="number"
                          onWheel={(e) => (e.target as HTMLElement).blur()}
                        />
                      )}
                    />
                    <Controller
                      name={`receivedSdsDeliveryReceiptPackagingItems.${index}.rejectedQuantity`}
                      control={control}
                      defaultValue={0}
                      render={({ field, fieldState }) => (
                        <TextField
                          label="Rejected"
                          {...textFieldHelperProps(field, fieldState, schema)}
                          {...field}
                          type="number"
                          onWheel={(e) => (e.target as HTMLElement).blur()}
                        />
                      )}
                    />
                  </Box>
                </Box>
              );
            }
          )}
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ReceivedSdsDeliveryReceiptForm;
