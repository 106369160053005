import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateRawMaterialProductRecipeDocument,
  QueryMode,
  RawMaterialProductRecipesDocument,
  RawMaterialProductRecipeWhereInput,
  SortOrder,
  useDeleteManyRawMaterialProductRecipeMutation,
  useRawMaterialProductRecipesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { roundFloat } from '../../utils/numberFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const RawMaterialProductRecipesPage = ({
  title = 'Raw Material Product Recipes',
}) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: RawMaterialProductRecipeWhereInput = Object.assign(
    {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              rawMaterialProduct: {
                is: {
                  rawMaterial: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              rawMaterialProductIngredients: {
                some: {
                  rawMaterialProduct: {
                    is: {
                      rawMaterial: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        rawMaterialProduct: {
          rawMaterial: {
            name: SortOrder.Asc,
          },
        },
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { rawMaterialProductRecipes } = {},
    loading,
    error,
  } = useRawMaterialProductRecipesQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyRawMaterialProductRecipe] =
    useDeleteManyRawMaterialProductRecipeMutation({
      refetchQueries: [
        {
          query: AggregateRawMaterialProductRecipeDocument,
          variables: countVariables,
        },
        {
          query: RawMaterialProductRecipesDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Delete' + title.toLowerCase() + ' error! ' + error,
          severity: 'error',
        }),
    });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof rawMaterialProductRecipes>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Raw Material',
      accessor: 'rawMaterialProduct.rawMaterial.name',
    },
    {
      header: 'Unit',
      accessor: 'rawMaterialProduct.unit.name',
    },

    {
      header: 'Raw Material Product Ingredients',
      accessor: 'rawMaterialProductIngredients',
      Cell: (children: {}[], index: number) => {
        const rawMaterialProductColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof rawMaterialProductRecipes>
              >['rawMaterialProductIngredients']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={rawMaterialProductColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyRawMaterialProductRecipe({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <Search />
        </>
      }
      dataTable={
        <DataTable data={rawMaterialProductRecipes} columns={columns} />
      }
      pagination={
        <Pagination
          query={AggregateRawMaterialProductRecipeDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default RawMaterialProductRecipesPage;
