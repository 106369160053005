import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  SortOrder,
  useCreateWarehouseInventoryMutation,
  usePackagingProductsAllowedQuery,
  useRawMaterialProductsAllowedQuery,
} from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { schema, FormData } from './utils';
import { useAuthContext } from '../../contexts/authContext';
import WarehouseInventoryForm from './WarehouseInventoryForm';
import { useEffect } from 'react';
import { cleanPayload } from '../../utils/apolloClient';
import Loading from '../common/Loading';

const CreateWarehouseInventoryPage = ({
  title = `Create Warehouse Inventory`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const {
    data: { rawMaterialProducts } = {},
    loading: rawMaterialProductsLoading,
  } = useRawMaterialProductsAllowedQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        baseUnit: {
          equals: true,
        },
      },
      orderBy: [
        { rawMaterial: { rawMaterialCategory: SortOrder.Asc } },
        { rawMaterial: { name: SortOrder.Asc } },
      ],
    },
  });

  const {
    data: { packagingProducts } = {},
    loading: packagingProductsLoading,
  } = usePackagingProductsAllowedQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        baseUnit: {
          equals: true,
        },
      },
      orderBy: [
        { packaging: { packagingCategory: SortOrder.Asc } },
        { packaging: { name: SortOrder.Asc } },
      ],
    },
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({
          warehouseInventoryRawMaterialItems,
          warehouseInventoryPackagingItems,
          ...data
        }) =>
          await createWarehouseInventory({
            variables: {
              data: {
                ...prismaCreate(data),
                createdBy: currentUser.name,
                warehouseInventoryRawMaterialItems: {
                  create: warehouseInventoryRawMaterialItems.map(
                    ({
                      rawMaterialProduct,
                      quantity,
                      ...warehouseInventoryRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...warehouseInventoryRawMaterialItem,
                          quantity,
                        }),
                        rawMaterialProduct: {
                          connect: {
                            id: rawMaterialProduct?.id,
                          },
                        },
                      };
                    }
                  ),
                },
                warehouseInventoryPackagingItems: {
                  create: warehouseInventoryPackagingItems.map(
                    ({
                      packagingProduct,
                      quantity,
                      ...warehouseInventoryPackagingItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...warehouseInventoryPackagingItem,
                          quantity,
                        }),
                        packagingProduct: {
                          connect: {
                            id: packagingProduct?.id,
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createWarehouseInventory, { loading: mutationLoading }] =
    useCreateWarehouseInventoryMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  useEffect(() => {
    if (rawMaterialProducts && packagingProducts) {
      methods.reset({
        ...cleanPayload({
          warehouseInventoryRawMaterialItems: rawMaterialProducts.map(
            (rawMaterialProduct) => {
              return {
                rawMaterialProduct,
              };
            }
          ),
          warehouseInventoryPackagingItems: packagingProducts.map(
            (packagingProduct) => {
              return {
                packagingProduct,
              };
            }
          ),
        }),
      });
    }
  }, [rawMaterialProducts, packagingProducts, methods]);

  if (rawMaterialProductsLoading || packagingProductsLoading)
    return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <WarehouseInventoryForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateWarehouseInventoryPage;
