import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { OutletsDocument, UserGroup } from '../../graphql/generated/types';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import { FormData } from './utils';
import { useAuthContext } from '../../contexts/authContext';

const UserForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { control } = useFormContext<FormData>();
  const { currentUser } = useAuthContext();

  return (
    <FormContainer maxWidth="xs">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gap: 1,
          }}
        >
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Username"
                {...textFieldHelperProps(field, fieldState, schema)}
                InputProps={{
                  readOnly: type === 'update' || type === 'change password',
                }}
                {...field}
              />
            )}
          />

          {(type === 'create' || type === 'change password') && (
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...textFieldHelperProps(field, fieldState, schema)}
                  {...field}
                />
              )}
            />
          )}

          {type !== 'change password' && (
            <>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    label="Name"
                    {...textFieldHelperProps(field, fieldState, schema)}
                    {...field}
                  />
                )}
              />
              <Controller
                name="outlets"
                control={control}
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <SearchAsYouTypeAutocomplete
                    multiple
                    textFieldProps={{
                      ...textFieldHelperProps(field, fieldState, schema),
                      label: 'Outlets',
                    }}
                    queryOptions={(value) => {
                      return {
                        query: OutletsDocument,
                        variables: {
                          where: {
                            OR: [
                              {
                                name: {
                                  contains: value,
                                  mode: 'insensitive',
                                },
                              },
                            ],
                          },
                        },
                      };
                    }}
                    getOptionLabel={(option: any) => option?.name}
                    value={field.value as any}
                    onChange={(e, value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
              <EnumSelect
                multiple={true}
                name="userGroups"
                label="User Groups"
                schema={schema}
                enumObj={
                  currentUser?.userGroups?.includes(UserGroup.Superuser)
                    ? UserGroup
                    : Object.keys(UserGroup).reduce((acc: any, cur: any) => {
                        if (
                          !['Accounting', 'Management', 'Superuser'].includes(
                            cur
                          )
                        ) {
                          //@ts-ignore
                          acc[cur] = UserGroup[cur];
                        }
                        return acc;
                      }, {} as any)
                }
                control={control}
                defaultValue={[]}
              />
            </>
          )}
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default UserForm;
