import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { TransferDirection } from '../../graphql/enums/TransferDirection';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import Select from '../common/Select';
import PackagingAutocomplete from '../shared/PackagingAutocomplete';
import RawMaterialAutocomplete from '../shared/RawMaterialAutocomplete';
import { FormData } from './utils';

const BetweenEntityTransferForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
  readOnly,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const {
    fields: betweenEntityTransferRawMaterialItemsFields,
    append: appendBetweenEntityTransferRawMaterialItems,
    remove: removeBetweenEntityTransferRawMaterialItems,
  } = useFieldArray({
    control,
    name: 'betweenEntityTransferRawMaterialItems',
  });

  const {
    fields: betweenEntityTransferPackagingItemsFields,
    append: appendBetweenEntityTransferPackagingItems,
    remove: removeBetweenEntityTransferPackagingItems,
  } = useFieldArray({
    control,
    name: 'betweenEntityTransferPackagingItems',
  });

  const handleAddBetweenEntityTransferPackagingItems = () => {
    appendBetweenEntityTransferPackagingItems({
      packaging: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveBetweenEntityTransferPackagingItems = (index: number) => {
    removeBetweenEntityTransferPackagingItems(index);
  };
  const handleAddBetweenEntityTransferRawMaterialItem = () => {
    appendBetweenEntityTransferRawMaterialItems({
      rawMaterial: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveBetweenEntityTransferRawMaterialItem = (index: number) => {
    removeBetweenEntityTransferRawMaterialItems(index);
  };

  const [
    betweenEntityTransferRawMaterialItems,
    betweenEntityTransferPackagingItems,
  ] = useWatch({
    control,
    name: [
      'betweenEntityTransferRawMaterialItems',
      'betweenEntityTransferPackagingItems',
    ],
  });

  return (
    <FormContainer maxWidth="md" readOnly={readOnly}>
      {readOnly && (
        <>
          <Typography
            variant="h4"
            color="primary"
            sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
          >
            Dunkin' Donuts Tacloban
          </Typography>
          <Typography
            variant="h6"
            color="secondary"
            sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
          >
            Owned and Operated By: Rise and Shine Food Corp
          </Typography>
        </>
      )}
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="transferDirection"
            label="Transfer Direction"
            schema={schema}
            enumObj={TransferDirection}
            control={control}
            defaultValue={'' as unknown as TransferDirection}
          />
          <Controller
            name="origin"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Origin"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="destination"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Destination"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="receivedBy"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Received By"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {betweenEntityTransferPackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <PackagingAutocomplete
                    name={`betweenEntityTransferPackagingItems.${index}.packaging`}
                    schema={schema}
                    control={control}
                    label={`Packaging ${index + 1}`}
                    defaultValue={null}
                    onChange={(value: any) => {
                      setValue(
                        `betweenEntityTransferPackagingItems.${index}.unit`,
                        value?.packagingProducts?.find(
                          ({ baseUnit }: { baseUnit: boolean }) =>
                            baseUnit === true
                        )?.unit || null
                      );
                    }}
                  />
                  <Controller
                    name={`betweenEntityTransferPackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`betweenEntityTransferPackagingItems.${index}.unit`}
                    label="Unit"
                    schema={schema}
                    control={control}
                    arr={
                      betweenEntityTransferPackagingItems?.[
                        index
                      ]?.packaging?.packagingProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    disabled={
                      !betweenEntityTransferPackagingItems?.[index]?.packaging
                    }
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleRemoveBetweenEntityTransferPackagingItems(index)
                  }
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddBetweenEntityTransferPackagingItems}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {betweenEntityTransferRawMaterialItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <RawMaterialAutocomplete
                    name={`betweenEntityTransferRawMaterialItems.${index}.rawMaterial`}
                    schema={schema}
                    control={control}
                    label="Raw Material"
                    defaultValue={null}
                    onChange={(value: any) => {
                      setValue(
                        `betweenEntityTransferRawMaterialItems.${index}.unit`,
                        value?.rawMaterialProducts?.find(
                          ({ baseUnit }: { baseUnit: boolean }) =>
                            baseUnit === true
                        )?.unit || null
                      );
                    }}
                  />

                  <Controller
                    name={`betweenEntityTransferRawMaterialItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`betweenEntityTransferRawMaterialItems.${index}.unit`}
                    label="Unit"
                    schema={schema}
                    control={control}
                    arr={
                      betweenEntityTransferRawMaterialItems?.[
                        index
                      ]?.rawMaterial?.rawMaterialProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    disabled={
                      !betweenEntityTransferRawMaterialItems?.[index]
                        ?.rawMaterial
                    }
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleRemoveBetweenEntityTransferRawMaterialItem(index)
                  }
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddBetweenEntityTransferRawMaterialItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default BetweenEntityTransferForm;
