import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  SortOrder,
  Status,
  useCmlProductsAllowedQuery,
  useCreateDeliveryReceiptMutation,
} from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useEffect } from 'react';
import { useAuthContext } from '../../contexts/authContext';
import { cleanPayload } from '../../utils/apolloClient';
import Loading from '../common/Loading';
import DeliveryReceiptForm from './DeliveryReceiptForm';
import { FormData, schema } from './utils';

const CreateDeliveryReceiptPage = ({ title = `Create Delivery Receipt` }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const { data: { cmlProducts } = {}, loading: cmlProductsLoading } =
    useCmlProductsAllowedQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          status: {
            equals: Status.Active,
          },
        },
        orderBy: [
          { donutMixAndCut: { name: SortOrder.Asc } },
          { name: SortOrder.Asc },
        ],
      },
    });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({
          deliveryReceiptItems,
          outlet,
          receivedDeliveryReceipt,
          ...data
        }) =>
          await createDeliveryReceipt({
            variables: {
              data: {
                ...prismaCreate(data),
                controlNumber: '',
                outlet: {
                  connect: {
                    id: outlet?.id,
                  },
                },
                createdBy: currentUser.name,
                deliveryReceiptItems: {
                  create: deliveryReceiptItems.map(
                    ({
                      cmlProduct,
                      quantity,
                      ...deliveryReceiptRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...deliveryReceiptRawMaterialItem,
                          quantity,
                        }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createDeliveryReceipt, { loading: mutationLoading }] =
    useCreateDeliveryReceiptMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  useEffect(() => {
    if (cmlProducts) {
      methods.reset({
        ...cleanPayload({
          deliveryReceiptItems: cmlProducts.map((cmlProduct) => {
            return {
              cmlProduct,
            };
          }),
        }),
      });
    }
  }, [cmlProducts, methods]);

  if (cmlProductsLoading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <DeliveryReceiptForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateDeliveryReceiptPage;
