import { TextField } from '@mui/material';
import {
  CalendarPickerView,
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parse } from 'date-fns';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  dateField: string;
  label: string;
  formatString?: string;
  views?: CalendarPickerView[];
  inputFormat?: string;
}
const DateFilter = ({
  dateField = 'date',
  formatString = 'yyyy-MM-dd',
  label = 'Date',
  views = ['month', 'day', 'year'],
  inputFormat = 'MM/dd/yyyy',
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const dateQueryStringValue = parsed[dateField];
  const [dateValue, setDateValue] = useState(
    typeof dateQueryStringValue === 'string'
      ? parse(dateQueryStringValue, formatString, new Date())
      : null
  );

  const handleChangeDateValue = (value: Date | null) => {
    setDateValue(value);
    if (value) {
      delete parsed['page'];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({
          ...parsed,
          [dateField]: format(value, formatString),
        }),
      });
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          views={views}
          value={dateValue ?? null}
          onChange={handleChangeDateValue}
          inputFormat={inputFormat}
          renderInput={(params: any) => (
            <TextField
              {...params}
              onKeyDown={(e) => e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateFilter;
