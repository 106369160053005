import { z } from 'zod';
import { ProductCategory, SalesCategory, Status } from '../../graphql/generated/types';


export const schema = z.object({
  name: z.string().trim().min(1),
  status: z.nativeEnum(Status),
  productCategory: z.nativeEnum(ProductCategory),
  salesCategory: z.nativeEnum(SalesCategory),
  unit: z.object({
    id: z.coerce.number()
  }).transform((val) => !val ? null : val),
  donutMixAndCut: z.object({
    id: z.coerce.number()
  }).transform((val) => !val ? null : val),
})

export type FormData = z.infer<typeof schema>




