import { format, startOfMonth } from 'date-fns';
import {
  ConsolidatedThrowAwayReportItemWhereInput,
  RawMaterialCategory,
  SalesCategory,
  useBeveragesConsolidatedThrowAwayReportItemsQuery,
  useBeveragesAllowedQuery,
  useDonutsConsolidatedThrowAwayReportItemsQuery,
  useOutletsQuery,
  useRawMaterialsConsolidatedThrowAwayReportItemsQuery,
  useRawMaterialsAllowQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import DataTable from '../common/DataTable';
import DateFilter from '../common/DateFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import SearchFilter from '../common/SearchFilter';
import ListView from '../templates/ListView';
import { getEnumKeyFromValue, getEnumValue } from '../../utils/stringFunctions';
import { TableCell, TableRow } from '@mui/material';
import { Typography } from '@mui/material';
import DownloadCsvButton from '../common/DownloadCsvButton';

enum Product {
  'Beverages' = 'BEVERAGES',
  'Donuts' = 'DONUTS',
  'Raw Materials' = 'RAW_MATERIALS',
}

enum Location {
  'Outlet' = 'OUTLET',
  'Production' = 'PRODUCTION',
}
const ConsolidatedThrowAwayReportItemsPage = ({
  title = 'Consolidated Throw Away Report',
}) => {
  const [
    monthYearQueryString,
    productQueryString,
    locationQueryString,
    outletIdQueryString,
  ] = useGetQueryString(['monthYear', 'product', 'location', 'outletId']);

  const { data: { beverages } = {}, loading: beveragesLoading } =
    useBeveragesAllowedQuery({
      fetchPolicy: 'network-only',
    });
  const { data: { rawMaterials } = {}, loading: rawMaterialsLoading } =
    useRawMaterialsAllowQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          rawMaterialCategory: {
            equals: 'RAW_MATERIALS_CONTROL' as RawMaterialCategory,
          },
        },
      },
    });
  const { data: { outlets } = {}, loading: outletsLoading } = useOutletsQuery({
    fetchPolicy: 'network-only',
  });

  const where: ConsolidatedThrowAwayReportItemWhereInput = Object.assign(
    {},

    typeof locationQueryString === 'string'
      ? {
          location: locationQueryString,
        }
      : {},
    typeof monthYearQueryString === 'string'
      ? {
          monthYear: startOfMonth(new Date(monthYearQueryString)),
        }
      : {},
    typeof outletIdQueryString === 'string'
      ? {
          outletId: +outletIdQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const {
    data: { beveragesConsolidatedThrowAwayReportItems } = {},
    loading: beveragesConsolidatedThrowAwayReportItemsLoading,
    error: beveragesConsolidatedThrowAwayReportItemsError,
  } = useBeveragesConsolidatedThrowAwayReportItemsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !monthYearQueryString || productQueryString !== 'BEVERAGES',
  });
  const {
    data: { donutsConsolidatedThrowAwayReportItems } = {},
    loading: donutsConsolidatedThrowAwayReportItemsLoading,
    error: donutsConsolidatedThrowAwayReportItemsError,
  } = useDonutsConsolidatedThrowAwayReportItemsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !monthYearQueryString || productQueryString !== 'DONUTS',
  });

  const {
    data: { rawMaterialsConsolidatedThrowAwayReportItems } = {},
    loading: rawMaterialsConsolidatedThrowAwayReportItemsLoading,
    error: rawMaterialsConsolidatedThrowAwayReportItemsError,
  } = useRawMaterialsConsolidatedThrowAwayReportItemsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !monthYearQueryString || productQueryString !== 'RAW_MATERIALS',
  });

  const beveragesColumns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof beveragesConsolidatedThrowAwayReportItems>
      >
    >
  > = [
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => format(new Date(children), 'E, d MMM yyyy'),
    },

    ...(beverages || [])?.map((beverage) => {
      return {
        header: beverage.name,
        accessor: 'beverages' as 'beverages',
        Cell: (children: any) => {
          return children?.find(
            ({ name }: { name: any }) => name === beverage.name
          )?.quantity;
        },
      };
    }),
  ];

  const donutsColumns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof donutsConsolidatedThrowAwayReportItems>
      >
    >
  > = [
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => format(new Date(children), 'E, d MMM yyyy'),
    },

    ...Object.values(SalesCategory)?.map((salesCategory) => {
      return {
        header: getEnumKeyFromValue(SalesCategory, salesCategory),
        accessor: 'donuts' as 'donuts',
        Cell: (children: any) => {
          return children?.find(
            ({ name }: { name: any }) => name === salesCategory
          )?.quantity;
        },
      };
    }),
  ];

  const rawMaterialsColumns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof rawMaterialsConsolidatedThrowAwayReportItems>
      >
    >
  > = [
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => format(new Date(children), 'E, d MMM yyyy'),
    },
    //@ts-ignore
    ...(rawMaterials || [])?.map((rawMaterial, idx) => {
      return {
        header: rawMaterial.name,
        accessor: 'rawMaterials',
        Cell: (children: any) => {
          const index =
            children?.findIndex(
              ({ name }: { name: any }) => name === rawMaterial.name
            ) || 0;
          return children[index].quantity;
        },
      };
    }),
  ];

  const data =
    productQueryString === 'BEVERAGES'
      ? beveragesConsolidatedThrowAwayReportItems
      : productQueryString === 'DONUTS'
      ? donutsConsolidatedThrowAwayReportItems
      : productQueryString === 'RAW_MATERIALS'
      ? rawMaterialsConsolidatedThrowAwayReportItems
      : [];
  const columns =
    productQueryString === 'BEVERAGES'
      ? beveragesColumns
      : productQueryString === 'DONUTS'
      ? donutsColumns
      : productQueryString === 'RAW_MATERIALS'
      ? rawMaterialsColumns
      : [];
  return (
    <>
      <ListView
        loading={
          beveragesConsolidatedThrowAwayReportItemsLoading ||
          donutsConsolidatedThrowAwayReportItemsLoading ||
          rawMaterialsConsolidatedThrowAwayReportItemsLoading ||
          outletsLoading ||
          beveragesLoading ||
          rawMaterialsLoading
        }
        error={
          beveragesConsolidatedThrowAwayReportItemsError ||
          donutsConsolidatedThrowAwayReportItemsError ||
          rawMaterialsConsolidatedThrowAwayReportItemsError
        }
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={data}
            getDownloadData={() => {}}
            downloadDataLoading={false}
            columns={columns.map(({ header, accessor }) => {
              const data =
                productQueryString === 'BEVERAGES'
                  ? beverages
                  : productQueryString === 'DONUTS'
                  ? Object.values(SalesCategory)
                  : productQueryString === 'RAW_MATERIALS'
                  ? rawMaterials
                  : [];
              const keyString =
                productQueryString === 'BEVERAGES'
                  ? 'beverages'
                  : productQueryString === 'DONUTS'
                  ? 'donuts'
                  : productQueryString === 'RAW_MATERIALS'
                  ? 'rawMaterials'
                  : '';
              const index =
                productQueryString === 'BEVERAGES'
                  ? beverages?.findIndex(({ name }) => name === header)
                  : productQueryString === 'DONUTS'
                  ? Object.values(SalesCategory).findIndex(
                      (value) => value === header
                    )
                  : productQueryString === 'RAW_MATERIALS'
                  ? rawMaterials?.findIndex(({ name }) => name === header)
                  : 0;
              if (header !== 'Date') {
                return {
                  header,
                  accessor: `${keyString}[${index}].quantity`,
                };
              } else {
                return {
                  header,
                  accessor,
                };
              }
            })}
          />
        }
        filters={
          <>
            <DateFilter
              inputFormat="MMM yyyy"
              views={['month', 'year']}
              dateField="monthYear"
              label="Month Year"
              formatString="yyyy-MM"
            />
            <EnumSearchFilter
              label="Product"
              field="product"
              enumObj={Product}
            />
            <EnumSearchFilter
              label="Location"
              field="location"
              enumObj={Location}
            />
            <SearchFilter
              label="Outlet"
              field="outletId"
              arr={outlets}
              setAccessor={(value) => value.name}
              setValue={(value) => value.id}
            />
          </>
        }
        dataTable={
          <DataTable
            data={data}
            columns={columns}
            tableSummary={
              productQueryString === 'BEVERAGES' ? (
                <TableRow>
                  {beveragesColumns.map((column) => {
                    if (column.header === 'Date') {
                      return (
                        <>
                          <TableCell colSpan={1} align="center">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Total:
                            </Typography>
                          </TableCell>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <TableCell align="center">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {beveragesConsolidatedThrowAwayReportItems?.reduce(
                                (acc, cur) =>
                                  acc +
                                  (cur['beverages']?.find(
                                    (beverage) =>
                                      beverage.name === column.header
                                  )?.quantity || 0),
                                0
                              )}
                            </Typography>
                          </TableCell>
                        </>
                      );
                    }
                  })}
                </TableRow>
              ) : productQueryString === 'DONUTS' ? (
                <TableRow>
                  {donutsColumns.map((column) => {
                    if (column.header === 'Date') {
                      return (
                        <>
                          <TableCell colSpan={1} align="center">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Total:
                            </Typography>
                          </TableCell>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <TableCell align="center">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {donutsConsolidatedThrowAwayReportItems?.reduce(
                                (acc, cur) => {
                                  return (
                                    acc +
                                    (cur['donuts']?.find(
                                      (donut) =>
                                        donut.name ===
                                        getEnumValue(
                                          SalesCategory,
                                          column.header as any
                                        )
                                    )?.quantity || 0)
                                  );
                                },
                                0
                              )}
                            </Typography>
                          </TableCell>
                        </>
                      );
                    }
                  })}
                </TableRow>
              ) : productQueryString === 'RAW_MATERIALS' ? (
                <TableRow>
                  {rawMaterialsColumns.map((column) => {
                    if (column.header === 'Date') {
                      return (
                        <>
                          <TableCell colSpan={1} align="center">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Total:
                            </Typography>
                          </TableCell>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <TableCell align="center">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {rawMaterialsConsolidatedThrowAwayReportItems?.reduce(
                                (acc, cur) =>
                                  acc +
                                  (cur['rawMaterials']?.find(
                                    (rawMaterial) =>
                                      rawMaterial.name === column.header
                                  )?.quantity || 0),
                                0
                              )}
                            </Typography>
                          </TableCell>
                        </>
                      );
                    }
                  })}
                </TableRow>
              ) : null
            }
          />
        }
      />
    </>
  );
};

export default ConsolidatedThrowAwayReportItemsPage;
