import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useAuthContext } from '../../contexts/authContext';
import {
  CmlThrowAwayLocation,
  DeliveryShiftSchedule,
  UserGroup,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import Select from '../common/Select';
import CmlProductAutocomplete from '../shared/CmlProductAutocomplete';
import PackagingAutocomplete from '../shared/PackagingAutocomplete';
import RawMaterialAutocomplete from '../shared/RawMaterialAutocomplete';
import { FormData } from './utils';

const CmlThrowAwayForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { currentUser } = useAuthContext();
  const { control } = useFormContext<FormData>();
  const {
    fields: cmlThrowAwayCmlItemsFields,
    remove: removeCmlThrowAwayCmlItem,
    append: appendCmlThrowAwayCmlItem,
  } = useFieldArray({
    control,
    name: 'cmlThrowAwayCmlItems',
  });
  const handleAddCmlThrowAwayCmlItem = () => {
    appendCmlThrowAwayCmlItem({
      cmlProduct: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveCmlThrowAwayCmlItem = (index: number) => {
    removeCmlThrowAwayCmlItem(index);
  };

  const {
    fields: cmlThrowAwayRawMaterialItemsFields,
    remove: removeCmlThrowAwayRawMaterialItem,
    append: appendCmlThrowAwayRawMaterialItem,
  } = useFieldArray({
    control,
    name: 'cmlThrowAwayRawMaterialItems',
  });
  const handleAddCmlThrowAwayRawMaterialItem = () => {
    appendCmlThrowAwayRawMaterialItem({
      unit: null,
      rawMaterial: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveCmlThrowAwayRawMaterialItem = (index: number) => {
    removeCmlThrowAwayRawMaterialItem(index);
  };

  const {
    fields: cmlThrowAwayPackagingItemsFields,
    remove: removeCmlThrowAwayPackagingItem,
    append: appendCmlThrowAwayPackagingItem,
  } = useFieldArray({
    control,
    name: 'cmlThrowAwayPackagingItems',
  });
  const handleAddCmlThrowAwayPackagingItem = () => {
    appendCmlThrowAwayPackagingItem({
      unit: null,
      packaging: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveCmlThrowAwayPackagingItem = (index: number) => {
    removeCmlThrowAwayPackagingItem(index);
  };

  const [
    cmlThrowAwayLocation,
    controlNumber,
    createdBy,
    checkedBy,
    cmlThrowAwayRawMaterialItems,
    cmlThrowAwayPackagingItems,
  ] = useWatch({
    control,
    name: [
      'cmlThrowAwayLocation',
      'controlNumber',
      'createdBy',
      'checkedBy',
      'cmlThrowAwayRawMaterialItems',
      'cmlThrowAwayPackagingItems',
    ],
  });

  const showCheckedQuantity =
    type === 'update' &&
    (currentUser?.userGroups?.includes(UserGroup.Superuser) ||
      currentUser?.userGroups?.includes(UserGroup.Accounting));

  // useEffect(() => {
  //   if (showCheckedQuantity && cmlThrowAwayCmlItems?.length > 0 && !checkedBy) {
  //     setValue(
  //       'cmlThrowAwayCmlItems',
  //       cmlThrowAwayCmlItems.map((cmlThrowAwayCmlItem) => {
  //         return {
  //           ...cmlThrowAwayCmlItem,
  //           checkedQuantity: cmlThrowAwayCmlItem.quantity,
  //         };
  //       })
  //     );
  //   }
  // }, [setValue, showCheckedQuantity, cmlThrowAwayCmlItems, checkedBy]);

  // useEffect(() => {
  //   if (
  //     showCheckedQuantity &&
  //     cmlThrowAwayRawMaterialItems?.length > 0 &&
  //     !checkedBy
  //   ) {
  //     setValue(
  //       'cmlThrowAwayRawMaterialItems',
  //       cmlThrowAwayRawMaterialItems.map((cmlThrowAwayRawMaterialItem) => {
  //         return {
  //           ...cmlThrowAwayRawMaterialItem,
  //           checkedQuantity: cmlThrowAwayRawMaterialItem.quantity,
  //         };
  //       })
  //     );
  //   }
  // }, [setValue, showCheckedQuantity, cmlThrowAwayRawMaterialItems, checkedBy]);

  // useEffect(() => {
  //   if (
  //     showCheckedQuantity &&
  //     cmlThrowAwayPackagingItems?.length > 0 &&
  //     !checkedBy
  //   ) {
  //     setValue(
  //       'cmlThrowAwayPackagingItems',
  //       cmlThrowAwayPackagingItems.map((cmlThrowAwayPackagingItem) => {
  //         return {
  //           ...cmlThrowAwayPackagingItem,
  //           checkedQuantity: cmlThrowAwayPackagingItem.quantity,
  //         };
  //       })
  //     );
  //   }
  // }, [setValue, showCheckedQuantity, cmlThrowAwayPackagingItems, checkedBy]);

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      {type === 'update' && (
        <Typography variant="subtitle2">{controlNumber}</Typography>
      )}
      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Time"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
          />
          <EnumSelect
            name="cmlThrowAwayLocation"
            label="Location"
            schema={schema}
            enumObj={CmlThrowAwayLocation}
            control={control}
            defaultValue={'' as unknown as CmlThrowAwayLocation}
            disabled={type === 'update'}
          />
          {type === 'update' && (
            <>
              <TextField
                label="Created By"
                fullWidth
                value={createdBy ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Checked By"
                fullWidth
                value={checkedBy ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </>
          )}
        </Box>
        {cmlThrowAwayLocation === 'PRODUCTION' && (
          <>
            <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
              CML Items
            </Typography>
            <Stack direction="column" spacing={1}>
              {cmlThrowAwayCmlItemsFields.map((field, index) => {
                return (
                  <Box
                    key={field.id}
                    sx={{
                      ...nestedGrid,
                    }}
                  >
                    <Box>
                      <CmlProductAutocomplete
                        name={`cmlThrowAwayCmlItems.${index}.cmlProduct`}
                        schema={schema}
                        control={control}
                        label="CML Product"
                        defaultValue={null}
                      />
                      <Controller
                        name={`cmlThrowAwayCmlItems.${index}.quantity`}
                        control={control}
                        defaultValue={undefined}
                        render={({ field, fieldState }) => (
                          <TextField
                            label="Quantity"
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                            type="number"
                            onWheel={(e) => (e.target as HTMLElement).blur()}
                          />
                        )}
                      />
                      {showCheckedQuantity && (
                        <Controller
                          name={`cmlThrowAwayCmlItems.${index}.checkedQuantity`}
                          control={control}
                          defaultValue={undefined}
                          render={({ field, fieldState }) => (
                            <TextField
                              label="Checked Quantity"
                              {...textFieldHelperProps(
                                field,
                                fieldState,
                                schema
                              )}
                              {...field}
                              type="number"
                              onWheel={(e) => (e.target as HTMLElement).blur()}
                            />
                          )}
                        />
                      )}
                    </Box>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="small"
                      onClick={() => handleRemoveCmlThrowAwayCmlItem(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                sx={{ my: 1 }}
                type="button"
                variant="contained"
                color="primary"
                onClick={handleAddCmlThrowAwayCmlItem}
                size="small"
              >
                Add
              </Button>
            </Stack>
          </>
        )}
        {(cmlThrowAwayLocation === 'PRODUCTION' ||
          cmlThrowAwayLocation === 'WAREHOUSE') && (
          <>
            {' '}
            <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
              Raw Material Items
            </Typography>
            <Stack direction="column" spacing={1}>
              {cmlThrowAwayRawMaterialItemsFields.map((field, index) => {
                return (
                  <Box
                    key={field.id}
                    sx={{
                      ...nestedGrid,
                    }}
                  >
                    <Box>
                      <RawMaterialAutocomplete
                        name={`cmlThrowAwayRawMaterialItems.${index}.rawMaterial`}
                        schema={schema}
                        control={control}
                        label="Raw Material"
                        defaultValue={null}
                      />
                      <Select
                        name={`cmlThrowAwayRawMaterialItems.${index}.unit`}
                        label="Unit"
                        schema={schema}
                        control={control}
                        arr={
                          cmlThrowAwayRawMaterialItems?.[
                            index
                          ]?.rawMaterial?.rawMaterialProducts?.map(
                            ({ unit }) => unit
                          ) || []
                        }
                        defaultValue={null}
                        disabled={
                          !cmlThrowAwayRawMaterialItems?.[index]?.rawMaterial
                        }
                        renderValueAs={(obj) => `${obj.name}`}
                      />
                      <Controller
                        name={`cmlThrowAwayRawMaterialItems.${index}.quantity`}
                        control={control}
                        defaultValue={undefined}
                        render={({ field, fieldState }) => (
                          <TextField
                            label="Quantity"
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                            type="number"
                            onWheel={(e) => (e.target as HTMLElement).blur()}
                          />
                        )}
                      />
                      {showCheckedQuantity && (
                        <Controller
                          name={`cmlThrowAwayRawMaterialItems.${index}.checkedQuantity`}
                          control={control}
                          defaultValue={undefined}
                          render={({ field, fieldState }) => (
                            <TextField
                              label="Checked Quantity"
                              {...textFieldHelperProps(
                                field,
                                fieldState,
                                schema
                              )}
                              {...field}
                              type="number"
                              onWheel={(e) => (e.target as HTMLElement).blur()}
                            />
                          )}
                        />
                      )}
                    </Box>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="small"
                      onClick={() =>
                        handleRemoveCmlThrowAwayRawMaterialItem(index)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                sx={{ my: 1 }}
                type="button"
                variant="contained"
                color="primary"
                onClick={handleAddCmlThrowAwayRawMaterialItem}
                size="small"
              >
                Add
              </Button>
            </Stack>
          </>
        )}
        {cmlThrowAwayLocation === 'WAREHOUSE' && (
          <>
            {' '}
            <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
              Packaging Items
            </Typography>
            <Stack direction="column" spacing={1}>
              {cmlThrowAwayPackagingItemsFields.map((field, index) => {
                return (
                  <Box
                    key={field.id}
                    sx={{
                      ...nestedGrid,
                    }}
                  >
                    <Box>
                      <PackagingAutocomplete
                        name={`cmlThrowAwayPackagingItems.${index}.packaging`}
                        schema={schema}
                        control={control}
                        label={`Packaging ${index + 1}`}
                        defaultValue={null}
                      />
                      <Select
                        name={`cmlThrowAwayPackagingItems.${index}.unit`}
                        label="Unit"
                        schema={schema}
                        control={control}
                        arr={
                          cmlThrowAwayPackagingItems?.[
                            index
                          ]?.packaging?.packagingProducts?.map(
                            ({ unit }) => unit
                          ) || []
                        }
                        defaultValue={null}
                        disabled={
                          !cmlThrowAwayPackagingItems?.[index]?.packaging
                        }
                        renderValueAs={(obj) => `${obj.name}`}
                      />
                      <Controller
                        name={`cmlThrowAwayPackagingItems.${index}.quantity`}
                        control={control}
                        defaultValue={undefined}
                        render={({ field, fieldState }) => (
                          <TextField
                            label="Quantity"
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                            type="number"
                            onWheel={(e) => (e.target as HTMLElement).blur()}
                          />
                        )}
                      />
                      {showCheckedQuantity && (
                        <Controller
                          name={`cmlThrowAwayPackagingItems.${index}.checkedQuantity`}
                          control={control}
                          defaultValue={undefined}
                          render={({ field, fieldState }) => (
                            <TextField
                              label="Checked Quantity"
                              {...textFieldHelperProps(
                                field,
                                fieldState,
                                schema
                              )}
                              {...field}
                              type="number"
                              onWheel={(e) => (e.target as HTMLElement).blur()}
                            />
                          )}
                        />
                      )}
                    </Box>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="small"
                      onClick={() =>
                        handleRemoveCmlThrowAwayPackagingItem(index)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                sx={{ my: 1 }}
                type="button"
                variant="contained"
                color="primary"
                onClick={handleAddCmlThrowAwayPackagingItem}
                size="small"
              >
                Add
              </Button>
            </Stack>
          </>
        )}
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default CmlThrowAwayForm;
