import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from '@mui/material';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { z } from 'zod';
import { isFieldRequired } from '../../utils/zod';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  schema: z.ZodTypeAny;
  arr: any[];
  renderValueAs: (obj: Record<string, any>) => string;
  uniqueIdentifier?: string;
}
const Select = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  schema,
  control,
  name,
  defaultValue,
  label,
  arr,
  renderValueAs,
  ...props
}: Props<TFieldValues, TName> & SelectProps) => {
  const { field, fieldState } = useController({ name, control, defaultValue });
  const labelId = `${name}-label`;

  return (
    <>
      <FormControl
        fullWidth
        error={!!fieldState.error?.message}
        required={isFieldRequired(schema, field.name)}
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <MuiSelect
          labelId={labelId}
          label={label}
          {...field}
          value={arr?.find((obj) => obj?.id === field?.value?.id) ?? ''}
          {...props}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {arr?.map((obj, idx) => {
            return (
              <MenuItem key={idx} value={obj}>
                {obj && renderValueAs(obj)}
              </MenuItem>
            );
          })}
        </MuiSelect>
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      </FormControl>
    </>
  );
};

export default Select;
