import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  handleDelete?: (ids: number[]) => void;
  showUpdate?: boolean;
}

const ActionsMenu = ({
  children,
  selected,
  setSelected,
  handleDelete,
  showUpdate = true,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreate = () => {
    navigate(`${location.pathname}/create`);
  };

  const handleUpdate = (id: number) => {
    navigate(`${location.pathname}/${id}/update`);
  };

  return (
    <>
      <Button
        color="secondary"
        id="actions-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        endIcon={<KeyboardArrowDownIcon />}
        disableElevation
        disableFocusRipple
        disableRipple
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // onClick={handleReset}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
              fontSize: 18,
              color: (theme) => theme.palette.text.secondary,
              marginRight: (theme) => theme.spacing(1.5),
            },
          },
        }}
      >
        {selected.length === 0 && (
          <MenuItem onClick={() => handleCreate()}>
            <AddIcon />
            Create
          </MenuItem>
        )}
        {selected.length === 1 && showUpdate && (
          <MenuItem onClick={() => handleUpdate(selected[0])}>
            <EditIcon />
            Update
          </MenuItem>
        )}
        {selected.length > 0 && handleDelete && (
          <MenuItem
            onClick={() => {
              handleDelete(selected);
              handleClose();
              setSelected([]);
            }}
          >
            <DeleteIcon />
            Delete
          </MenuItem>
        )}
        {children}
      </Menu>
    </>
  );
};

export default ActionsMenu;
