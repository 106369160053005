import { Box, Button, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  DonutMixAndCutsDocument,
  ProductCategory,
  SalesCategory,
  Status,
  useUnitsQuery,
} from '../../graphql/generated/types';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import Loading from '../common/Loading';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import Select from '../common/Select';
import { FormData } from './utils';

const CmlProductForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control } = useFormContext<FormData>();

  const { data: { units } = {}, loading } = useUnitsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                sx={{ gridColumn: 'span 3' }}
                label="Name"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
                disabled={type === 'update'}
              />
            )}
          />
          <EnumSelect
            name="productCategory"
            label="Product Category"
            schema={schema}
            enumObj={ProductCategory}
            control={control}
            defaultValue={'' as unknown as ProductCategory}
          />
          <Controller
            name="donutMixAndCut"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Donut Mix and Cut',
                }}
                sx={{ gridColumn: 'span 2' }}
                queryOptions={(value) => {
                  return {
                    query: DonutMixAndCutsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            name: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.name}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <EnumSelect
            name="salesCategory"
            label="Sales Category"
            schema={schema}
            enumObj={SalesCategory}
            control={control}
            defaultValue={'' as unknown as SalesCategory}
          />
          <Select
            name="unit"
            label="Unit"
            schema={schema}
            control={control}
            arr={units || []}
            defaultValue={null}
            renderValueAs={(obj) => `${obj.name}`}
          />
          <EnumSelect
            name="status"
            label="Status"
            schema={schema}
            enumObj={Status}
            control={control}
            defaultValue={'' as unknown as Status}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default CmlProductForm;
