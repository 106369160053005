import { z } from 'zod';
import { RawMaterialCategory, Status } from '../../graphql/generated/types';






export const schema = z.object({
  name: z.string().trim().min(1),
  status: z.nativeEnum(Status),
  rawMaterialCategory: z.nativeEnum(RawMaterialCategory),
  rawMaterialProducts: z.array(z.object({
    id: z.coerce.number(),
    unit: z.object({ id: z.coerce.number() }).transform((val) => !val ? null : val),
    conversionToBaseUnit:  z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    baseUnit: z.boolean()
  }))
})

export type FormData = z.infer<typeof schema>




