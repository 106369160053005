import theme from "./theme";

export const stickyHeader = {
  mb: 1,
  position: 'sticky',
  top: 60,
  backgroundColor: theme.palette.background.default,
  border: `5px solid ${theme.palette.background.default}`,  
  zIndex: 1000,
  gap: 1
}