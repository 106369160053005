import { Breakpoint, Container, Paper, SxProps } from '@mui/material';
import React from 'react';
import DrawerHeader from '../common/DrawerHeader';

interface Props {
  children: React.ReactNode;
  maxWidth: Breakpoint | false;
  sx?: SxProps;
  readOnly?: boolean;
}
const FormContainer = ({
  children,
  maxWidth = 'lg',
  sx,
  readOnly = false,
}: Props) => {
  return (
    <>
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: 'auto',
          p: {
            xs: 0,
          },
          ...(readOnly && {
            pointerEvents: 'none',
            '* > button': {
              display: 'none',
            },
          }),
        }}
      >
        <Paper
          sx={{ my: { xs: 3, md: 6 }, p: 1, overflow: 'auto', ...sx }}
          variant="outlined"
          square
        >
          {children}
        </Paper>
      </Container>
      <DrawerHeader />
    </>
  );
};

export default FormContainer;
