export const formatMoney = function (amount: number, currency = "PHP") {
  const options = {
    style: "currency",
    currency,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 2,
  };

  // if (amount % 1 === 0) options.minimumFractionDigits = 0;
  const formatter = new Intl.NumberFormat("en-PH", options);
  return formatter.format(amount);
};


export const roundFloat = (num?: number, decimals: number = 2) => 
  num != null ? 
  (+(Math.round(num + "e+" + decimals as unknown as number)  + "e-" + decimals)).toFixed(decimals) 
  : "";