import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useRawMaterialRequestQuery,
  useUpdateRawMaterialRequestMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import RawMaterialRequestForm from './RawMaterialRequestForm';

import { FormData, schema } from './utils';

const UpdateRawMaterialRequestPage = ({
  title = `Update Raw Material Request`,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ rawMaterialRequestItems, outlet, ...data }) => {
        const rawMaterialRequestItemsToDelete = getItemsForDelete(
          rawMaterialRequest?.rawMaterialRequestItems,
          rawMaterialRequestItems
        );
        const rawMaterialRequestItemsToUpsert = getItemsForUpsert(
          rawMaterialRequestItems
        );
        await updateRawMaterialRequest({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              rawMaterialRequestItems: {
                delete: rawMaterialRequestItemsToDelete,
                upsert: rawMaterialRequestItemsToUpsert.map(
                  ({ id, rawMaterial, unit, ...rawMaterialRequestItem }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(rawMaterialRequestItem),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate(rawMaterialRequestItem),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { rawMaterialRequest } = {}, loading } =
    useRawMaterialRequestQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    });

  const [updateRawMaterialRequest, { loading: mutationLoading }] =
    useUpdateRawMaterialRequestMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (rawMaterialRequest) {
      const { rawMaterialRequestItems, ...data } = rawMaterialRequest;
      methods.reset({
        ...cleanPayload({
          ...data,
          rawMaterialRequestItems: rawMaterialRequestItems.map(
            ({ rawMaterialProduct, ...data }) => {
              return {
                rawMaterial: rawMaterialProduct?.rawMaterial,
                unit: rawMaterialProduct?.unit,
                ...data,
              };
            }
          ),
        }),
      });
    }
  }, [rawMaterialRequest, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <RawMaterialRequestForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateRawMaterialRequestPage;
