import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateReceivedTransferMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useAuthContext } from '../../contexts/authContext';
import { FormData, schema } from './utils';

import ReceivedTransferForm from './ReceivedTransferForm';

const CreateReceivedTransferPage = ({ title = `Create Received Transfer` }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({
          receivedTransferPackagingItems,
          receivedTransferRawMaterialItems,
          receivedTransferCmlItems,
          transfer,
          fromOutlet,
          toOutlet,
          ...data
        }) => {
          await createReceivedTransfer({
            variables: {
              data: {
                ...prismaCreate(data),
                transfer: {
                  connect: {
                    id: transfer?.id,
                  },
                },
                receivedBy: currentUser.name,
                receivedTransferCmlItems: {
                  create: receivedTransferCmlItems.map(
                    ({
                      transferCmlItem,
                      ...receivedReceivedTransferCmlItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...receivedReceivedTransferCmlItem,
                        }),
                        transferCmlItem: {
                          connect: {
                            id: transferCmlItem?.id,
                          },
                        },
                      };
                    }
                  ),
                },
                receivedTransferRawMaterialItems: {
                  create: receivedTransferRawMaterialItems.map(
                    ({
                      transferRawMaterialItem,
                      ...receivedReceivedTransferRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...receivedReceivedTransferRawMaterialItem,
                        }),
                        transferRawMaterialItem: {
                          connect: {
                            id: transferRawMaterialItem?.id,
                          },
                        },
                      };
                    }
                  ),
                },
                receivedTransferPackagingItems: {
                  create: receivedTransferPackagingItems.map(
                    ({
                      transferPackagingItem,
                      ...receivedReceivedTransferPackagingItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...receivedReceivedTransferPackagingItem,
                        }),
                        transferPackagingItem: {
                          connect: {
                            id: transferPackagingItem?.id,
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          });
        },
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createReceivedTransfer, { loading: mutationLoading }] =
    useCreateReceivedTransferMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ReceivedTransferForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateReceivedTransferPage;
