import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateCmlProductRecipeMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import CmlProductRecipeForm from './CmlProductRecipeForm';

import { schema, FormData } from './utils';

const CreateCmlProductRecipePage = ({
  title = `Create CML Product Recipe`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ cmlProduct, cmlProductIngredients, ...data }) =>
        await createCmlProductRecipe({
          variables: {
            data: {
              ...prismaCreate(data),
              cmlProduct: {
                connect: {
                  id: cmlProduct?.id,
                },
              },
              cmlProductIngredients: {
                create: cmlProductIngredients.map(
                  ({ rawMaterial, unit, ...cmlProductIngredient }) => {
                    return {
                      ...prismaCreate(cmlProductIngredient),
                      rawMaterialProduct: {
                        connect: {
                          unitId_rawMaterialId: {
                            unitId: unit?.id || 0,
                            rawMaterialId: rawMaterial?.id || 0,
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const [createCmlProductRecipe, { loading: mutationLoading }] =
    useCreateCmlProductRecipeMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <CmlProductRecipeForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateCmlProductRecipePage;
