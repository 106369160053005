import { Navigate, Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import BetweenEntityTransfersPage from '../betweenEntityTransfers/BetweenEntityTransfersPage';
import CreateBetweenEntityTransferPage from '../betweenEntityTransfers/CreateBetweenEntityTransferPage';
import UpdateBetweenEntityTransferPage from '../betweenEntityTransfers/UpdateBetweenEntityTransferPage';
import BeveragesPage from '../beverages/BeveragesPage';
import CreateBeveragePage from '../beverages/CreateBeveragePage';
import UpdateBeveragePage from '../beverages/UpdateBeveragePage';
import CheatSheetsPage from '../cheatSheets/CheatSheetsPage';
import CreateCheatSheetPage from '../cheatSheets/CreateCheatSheetPage';
import UpdateCheatSheetPage from '../cheatSheets/UpdateCheatSheetPage';
import CmlProductRecipesPage from '../cmlProductRecipes/CmlProductRecipesPage';
import CreateCmlProductRecipePage from '../cmlProductRecipes/CreateCmlProductRecipePage';
import UpdateCmlProductRecipePage from '../cmlProductRecipes/UpdateCmlProductRecipePage';
import CmlProductsPage from '../cmlProducts/CmlProductsPage';
import CreateCmlProductPage from '../cmlProducts/CreateCmlProductPage';
import UpdateCmlProductPage from '../cmlProducts/UpdateCmlProductPage';
import CmlThrowAwaysPage from '../cmlThrowAways/CmlThrowAwaysPage';
import CreateCmlThrowAwayPage from '../cmlThrowAways/CreateCmlThrowAwayPage';
import UpdateCmlThrowAwayPage from '../cmlThrowAways/UpdateCmlThrowAwayPage';
import CreateCrtPage from '../crts/CreateCrtPage';
import CrtsPage from '../crts/CrtsPage';
import UpdateCrtPage from '../crts/UpdateCrtPage';
import CreateDeliveryReceiptPage from '../deliveryReceipts/CreateDeliveryReceiptPage';
import DeliveryReceiptsPage from '../deliveryReceipts/DeliveryReceiptsPage';
import UpdateDeliveryReceiptPage from '../deliveryReceipts/UpdateDeliveryReceiptPage';
import CreateDonutMixAndCutPage from '../donutMixAndCuts/CreateDonutMixAndCutPage';

import DonutMixAndCutsPage from '../donutMixAndCuts/DonutMixAndCutsPage';
import UpdateDonutMixAndCutPage from '../donutMixAndCuts/UpdateDonutMixAndCutPage';
import CreateEndingSdEmTaPage from '../endingSdEmTa/CreateEndingSdEmTaPage';
import EndingSdEmTasPage from '../endingSdEmTa/EndingSdEmTasPage';
import UpdateEndingSdEmTaPage from '../endingSdEmTa/UpdateEndingSdEmTaPage';
import CreateManyMonthlyOutletPackagingInventoryPage from '../monthlyOutletPackagingInventories/CreateManyMonthlyOutletPackagingInventoryPage';
import MonthlyOutletPackagingInventoriesPage from '../monthlyOutletPackagingInventories/MonthlyOutletPackagingInventoriesPage';
import UpdateMonthlyOutletPackagingInventoryPage from '../monthlyOutletPackagingInventories/UpdateMonthlyOutletPackagingInventoryPage';
import CreateOutletFinishingPage from '../outletFinishings/CreateOutletFinishingPage';
import OutletFinishingsPage from '../outletFinishings/OutletFinishingsPage';
import UpdateOutletFinishingPage from '../outletFinishings/UpdateOutletFinishingPage';
import CreateOutletPage from '../outlets/CreateOutletPage';
import OutletsPage from '../outlets/OutletsPage';
import UpdateOutletPage from '../outlets/UpdateOutletPage';
import CreatePackagingRequestPage from '../packagingRequests/CreatePackagingRequestPage';
import PackagingRequestsPage from '../packagingRequests/PackagingRequestsPage';
import UpdatePackagingRequestPage from '../packagingRequests/UpdatePackagingRequestPage';
import CreatePackagingPage from '../packagings/CreatePackagingPage';
import PackagingsPage from '../packagings/PackagingsPage';
import UpdatePackagingPage from '../packagings/UpdatePackagingPage';
import CreateProjectionPage from '../projections/CreateProjectionPage';
import ProjectionsPage from '../projections/ProjectionsPage';
import UpdateProjectionPage from '../projections/UpdateProjectionPage';
import CreatePurchaseOrderPage from '../purchaseOrders/CreatePurchaseOrderPage';
import PurchaseOrdersPage from '../purchaseOrders/PurchaseOrdersPage';
import UpdatePurchaseOrderPage from '../purchaseOrders/UpdatePurchaseOrderPage';
import CreateRawMaterialFinishingEndingPage from '../rawMaterialFinishingEndings/CreateRawMaterialFinishingEndingPage';
import RawMaterialFinishingEndingsPage from '../rawMaterialFinishingEndings/RawMaterialFinishingEndingsPage';
import UpdateRawMaterialFinishingEndingPage from '../rawMaterialFinishingEndings/UpdateRawMaterialFinishingEndingPage';
import CreateRawMaterialProductRecipePage from '../rawMaterialProductRecipes/CreateRawMaterialProductRecipePage';
import RawMaterialProductRecipesPage from '../rawMaterialProductRecipes/RawMaterialProductRecipesPage';
import UpdateRawMaterialProductRecipePage from '../rawMaterialProductRecipes/UpdateRawMaterialProductRecipePage';
import CreateRawMaterialReleasePage from '../rawMaterialReleases/CreateRawMaterialReleasePage';
import RawMaterialReleasesPage from '../rawMaterialReleases/RawMaterialReleasesPage';
import UpdateRawMaterialReleasePage from '../rawMaterialReleases/UpdateRawMaterialReleasePage';
import CreateRawMaterialRequestPage from '../rawMaterialRequests/CreateRawMaterialRequestPage';
import RawMaterialRequestsPage from '../rawMaterialRequests/RawMaterialRequestsPage';
import UpdateRawMaterialRequestPage from '../rawMaterialRequests/UpdateRawMaterialRequestPage';
import CreateRawMaterialPage from '../rawMaterials/CreateRawMaterialPage';
import RawMaterialsPage from '../rawMaterials/RawMaterialsPage';
import UpdateRawMaterialPage from '../rawMaterials/UpdateRawMaterialPage';
import CreateReceivedDeliveryReceiptPage from '../receivedDeliveryReceipts/CreateReceivedDeliveryReceiptPage';
import ReceivedDeliveryReceiptsPage from '../receivedDeliveryReceipts/ReceivedDeliveryReceiptsPage';
import UpdateReceivedDeliveryReceiptPage from '../receivedDeliveryReceipts/UpdateReceivedDeliveryReceiptPage';
import CreateReceivedSdsDeliveryReceiptPage from '../receivedSdsDeliveryReceipts/CreateReceivedSdsDeliveryReceiptPage';
import ReceivedSdsDeliveryReceiptsPage from '../receivedSdsDeliveryReceipts/ReceivedSdsDeliveryReceiptsPage';
import UpdateReceivedSdsDeliveryReceiptPage from '../receivedSdsDeliveryReceipts/UpdateReceivedSdsDeliveryReceiptPage';
import CreateReceivedTransferPage from '../receivedTransfers/CreateReceivedTransferPage';
import ReceivedTransfersPage from '../receivedTransfers/ReceivedTransfersPage';
import UpdateReceivedTransferPage from '../receivedTransfers/UpdateReceivedTransferPage';
import AuditTrailsPage from '../reports/AuditTrailsPage';
import CreateSdsDeliveryReceiptPage from '../sdsDeliveryReceipts/CreateSdsDeliveryReceiptPage';
import SdsDeliveryReceiptsPage from '../sdsDeliveryReceipts/SdsDeliveryReceiptsPage';
import UpdateSdsDeliveryReceiptPage from '../sdsDeliveryReceipts/UpdateSdsDeliveryReceiptPage';
import CreateTransferPage from '../transfers/CreateTransferPage';
import TransfersPage from '../transfers/TransfersPage';
import UpdateTransferPage from '../transfers/UpdateTransferPage';
import CreateUnitPage from '../units/CreateUnitPage';
import UnitsPage from '../units/UnitsPage';
import UpdateUnitPage from '../units/UpdateUnitPage';
import ChangePasswordPage from '../users/ChangePasswordPage';
import CreateUserPage from '../users/CreateUserPage';
import UpdateUserPage from '../users/UpdateUserPage';
import UsersPage from '../users/UsersPage';
import CreateWarehouseInventoryPage from '../warehouseInventories/CreateWarehouseInventoryPage';
import UpdateWarehouseInventoryPage from '../warehouseInventories/UpdateWarehouseInventoryPage';
import WarehouseInventoriesPage from '../warehouseInventories/WarehouseInventoriesPage';
import HomePage from './HomePage';
import ProtectedRoute from './ProtectedRoute';
import DonutEndingConsolidationsPage from '../reports/DonutEndingConsolidationsPage';
import ProjectionSummaryPerOutletPage from '../reports/ProjectionSummaryPerOutletPage';
import VarianceReportOnDeliveryReceiptItemsPage from '../reports/VarianceReportOnDeliveryReceiptItemsPage';
import VarianceReportOnSdsRawMaterialItemsPage from '../reports/VarianceReportOnSdsRawMaterialItemsPage';
import VarianceReportOnSdsPackagingItemsPage from '../reports/VarianceReportOnSdsPackagingItemsPage';
import VarianceReportOnCmlTransfersPage from '../reports/VarianceReportOnCmlTransfersPage';
import VarianceReportOnRawMaterialTransfersPage from '../reports/VarianceReportOnRawMaterialTransfersPage';
import VarianceReportOnPackagingTransfersPage from '../reports/VarianceReportOnPackagingTransfersPage';
import DailyRawMaterialKitchenVarianceReportPage from '../reports/DailyRawMaterialKitchenVarianceReportPage';
import TotalDeliveryReceiptSummaryPage from '../reports/TotalDeliveryReceiptSummaryPage';
import DonutDistributionPerOutletPage from '../reports/DonutDistributionPerOutletPage';
import WarehouseRawMaterialInventoryReportItemsPage from '../reports/WarehouseRawMaterialInventoryReportItemsPage';
import WarehousePackagingInventoryReportItemsPage from '../reports/WarehousePackagingInventoryReportItemsPage';
import OutletPackagingEndingConsolidationsPage from '../reports/OutletPackagingEndingConsolidationsPage';
import SalesPackagingControlReportItemsPage from '../reports/SalesPackagingControlReportItemsPage';
import SalesProductControlReportItemsPage from '../reports/SalesProductControlReportItemsPage';
import ConsolidatedThrowAwayReportItemsPage from '../reports/ConsolidatedThrowAwayReportItemsPage';
import ProjectionToRawMaterialsPage from '../reports/ProjectionToRawMaterialsPage';
import DailyRawMaterialFinishingOutletVarianceReportPage from '../reports/DailyRawMaterialFinishingOutletVarianceReportPage';

const Routes = () => {
  const { currentUser } = useAuthContext();
  return (
    <ReactRouterRoutes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/">
        <Route element={<ProtectedRoute user={currentUser} />}>
          <Route index={true} element={<HomePage />} />
          <Route path="users">
            <Route index={true} element={<UsersPage />} />
            <Route path="create" element={<CreateUserPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateUserPage />} />
              <Route path="change-password" element={<ChangePasswordPage />} />
            </Route>
          </Route>
          <Route path="cheat-sheets">
            <Route index={true} element={<CheatSheetsPage />} />
            <Route path="create" element={<CreateCheatSheetPage />} />
            <Route path=":id">
              <Route
                index={true}
                element={
                  <UpdateCheatSheetPage
                    title="View Cheat Sheet"
                    readOnly={true}
                  />
                }
              />
              <Route path="update" element={<UpdateCheatSheetPage />} />
            </Route>
          </Route>
          <Route path="outlets">
            <Route index={true} element={<OutletsPage />} />
            <Route path="create" element={<CreateOutletPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateOutletPage />} />
            </Route>
          </Route>
          <Route path="donut-mix-and-cuts">
            <Route index={true} element={<DonutMixAndCutsPage />} />{' '}
            <Route path="create" element={<CreateDonutMixAndCutPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateDonutMixAndCutPage />} />
            </Route>
          </Route>
          <Route path="units">
            <Route index={true} element={<UnitsPage />} />
            <Route path="create" element={<CreateUnitPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateUnitPage />} />
            </Route>
          </Route>
          <Route path="raw-materials">
            <Route index={true} element={<RawMaterialsPage />} />
            <Route path="create" element={<CreateRawMaterialPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateRawMaterialPage />} />
            </Route>
          </Route>
          <Route path="raw-material-product-recipes">
            <Route index={true} element={<RawMaterialProductRecipesPage />} />
            <Route
              path="create"
              element={<CreateRawMaterialProductRecipePage />}
            />
            <Route path=":id">
              <Route
                path="update"
                element={<UpdateRawMaterialProductRecipePage />}
              />
            </Route>
          </Route>
          <Route path="packagings">
            <Route index={true} element={<PackagingsPage />} />
            <Route path="create" element={<CreatePackagingPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdatePackagingPage />} />
            </Route>
          </Route>
          <Route path="cml-products">
            <Route index={true} element={<CmlProductsPage />} />
            <Route path="create" element={<CreateCmlProductPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateCmlProductPage />} />
            </Route>
          </Route>
          <Route path="cml-product-recipes">
            <Route index={true} element={<CmlProductRecipesPage />} />
            <Route path="create" element={<CreateCmlProductRecipePage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateCmlProductRecipePage />} />
            </Route>
          </Route>
          <Route path="beverages">
            <Route index={true} element={<BeveragesPage />} />
            <Route path="create" element={<CreateBeveragePage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateBeveragePage />} />
            </Route>
          </Route>
          <Route path="warehouse-inventories">
            <Route index={true} element={<WarehouseInventoriesPage />} />
            <Route path="create" element={<CreateWarehouseInventoryPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateWarehouseInventoryPage />} />
            </Route>
          </Route>
          <Route path="crt-inputs">
            <Route index={true} element={<CrtsPage />} />
            <Route path="create" element={<CreateCrtPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateCrtPage />} />
            </Route>
          </Route>

          <Route path="delivery-receipts">
            <Route index={true} element={<DeliveryReceiptsPage />} />
            <Route path="create" element={<CreateDeliveryReceiptPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateDeliveryReceiptPage />} />
            </Route>
          </Route>
          <Route path="cml-throw-aways">
            <Route index={true} element={<CmlThrowAwaysPage />} />
            <Route path="create" element={<CreateCmlThrowAwayPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateCmlThrowAwayPage />} />
            </Route>
          </Route>

          <Route path="raw-material-finishing-endings">
            <Route index={true} element={<RawMaterialFinishingEndingsPage />} />
            <Route
              path="create"
              element={<CreateRawMaterialFinishingEndingPage />}
            />
            <Route path=":id">
              <Route
                path="update"
                element={<UpdateRawMaterialFinishingEndingPage />}
              />
            </Route>
          </Route>
          <Route path="raw-material-releases">
            <Route index={true} element={<RawMaterialReleasesPage />} />
            <Route path="create" element={<CreateRawMaterialReleasePage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateRawMaterialReleasePage />} />
            </Route>
          </Route>
          <Route path="sds-delivery-receipts">
            <Route index={true} element={<SdsDeliveryReceiptsPage />} />
            <Route path="create" element={<CreateSdsDeliveryReceiptPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateSdsDeliveryReceiptPage />} />
            </Route>
          </Route>
          <Route path="purchase-orders">
            <Route index={true} element={<PurchaseOrdersPage />} />
            <Route path="create" element={<CreatePurchaseOrderPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdatePurchaseOrderPage />} />
            </Route>
          </Route>
          <Route path="between-entity-transfers">
            <Route index={true} element={<BetweenEntityTransfersPage />} />
            <Route
              path="create"
              element={<CreateBetweenEntityTransferPage />}
            />
            <Route path=":id">
              <Route
                path="update"
                element={<UpdateBetweenEntityTransferPage />}
              />
              <Route
                path="print"
                element={
                  <UpdateBetweenEntityTransferPage
                    title="Between Entity Transfers"
                    readOnly={true}
                  />
                }
              />
            </Route>
          </Route>
          <Route path="projection-to-raw-materials">
            <Route index={true} element={<ProjectionToRawMaterialsPage />} />
          </Route>
          <Route path="projections">
            <Route index={true} element={<ProjectionsPage />} />
            <Route path="create" element={<CreateProjectionPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateProjectionPage />} />
            </Route>
          </Route>

          <Route path="packaging-requests">
            <Route index={true} element={<PackagingRequestsPage />} />
            <Route path="create" element={<CreatePackagingRequestPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdatePackagingRequestPage />} />
            </Route>
          </Route>

          <Route path="raw-material-requests">
            <Route index={true} element={<RawMaterialRequestsPage />} />
            <Route path="create" element={<CreateRawMaterialRequestPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateRawMaterialRequestPage />} />
            </Route>
          </Route>

          <Route path="received-sds-delivery-receipts">
            <Route index={true} element={<ReceivedSdsDeliveryReceiptsPage />} />
            <Route
              path="create"
              element={<CreateReceivedSdsDeliveryReceiptPage />}
            />
            <Route path=":id">
              <Route
                path="update"
                element={<UpdateReceivedSdsDeliveryReceiptPage />}
              />
            </Route>
          </Route>

          <Route path="received-delivery-receipts">
            <Route index={true} element={<ReceivedDeliveryReceiptsPage />} />
            <Route
              path="create"
              element={<CreateReceivedDeliveryReceiptPage />}
            />
            <Route path=":id">
              <Route
                path="update"
                element={<UpdateReceivedDeliveryReceiptPage />}
              />
            </Route>
          </Route>

          <Route path="ending-sd-em-tas">
            <Route index={true} element={<EndingSdEmTasPage />} />
            <Route path="create" element={<CreateEndingSdEmTaPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateEndingSdEmTaPage />} />
            </Route>
          </Route>

          <Route path="transfers">
            <Route index={true} element={<TransfersPage />} />
            <Route path="create" element={<CreateTransferPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateTransferPage />} />
            </Route>
          </Route>

          <Route path="received-transfers">
            <Route index={true} element={<ReceivedTransfersPage />} />
            <Route path="create" element={<CreateReceivedTransferPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateReceivedTransferPage />} />
            </Route>
          </Route>

          <Route path="outlet-finishings">
            <Route index={true} element={<OutletFinishingsPage />} />
            <Route path="create" element={<CreateOutletFinishingPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateOutletFinishingPage />} />
            </Route>
          </Route>

          <Route path="monthly-outlet-packaging-inventories">
            <Route
              index={true}
              element={<MonthlyOutletPackagingInventoriesPage />}
            />
            <Route
              path="create"
              element={<CreateManyMonthlyOutletPackagingInventoryPage />}
            />
            <Route path=":id">
              <Route
                path="update"
                element={<UpdateMonthlyOutletPackagingInventoryPage />}
              />
            </Route>
          </Route>

          <Route path="audit-trails">
            <Route index={true} element={<AuditTrailsPage />} />
          </Route>
          <Route path="projection-summary-per-outlet">
            <Route index={true} element={<ProjectionSummaryPerOutletPage />} />
          </Route>
          <Route path="donut-ending-consolidation-report">
            <Route index={true} element={<DonutEndingConsolidationsPage />} />
          </Route>
          <Route path="consolidated-throw-away-report">
            <Route
              index={true}
              element={<ConsolidatedThrowAwayReportItemsPage />}
            />
          </Route>
          <Route path="warehouse-packaging-inventory-report">
            <Route
              index={true}
              element={<WarehousePackagingInventoryReportItemsPage />}
            />
          </Route>
          <Route path="sales-product-control-report">
            <Route
              index={true}
              element={<SalesProductControlReportItemsPage />}
            />
          </Route>
          <Route path="sales-packaging-control-report">
            <Route
              index={true}
              element={<SalesPackagingControlReportItemsPage />}
            />
          </Route>
          <Route path="outlet-packaging-ending-consolidation-report">
            <Route
              index={true}
              element={<OutletPackagingEndingConsolidationsPage />}
            />
          </Route>
          <Route path="warehouse-raw-material-inventory-report">
            <Route
              index={true}
              element={<WarehouseRawMaterialInventoryReportItemsPage />}
            />
          </Route>
          <Route path="variance-report-on-delivery-receipt-items">
            <Route
              index={true}
              element={<VarianceReportOnDeliveryReceiptItemsPage />}
            />
          </Route>
          <Route path="variance-report-on-sds-raw-material-items">
            <Route
              index={true}
              element={<VarianceReportOnSdsRawMaterialItemsPage />}
            />
          </Route>
          <Route path="variance-report-on-sds-packaging-items">
            <Route
              index={true}
              element={<VarianceReportOnSdsPackagingItemsPage />}
            />
          </Route>
          <Route path="variance-report-on-cml-transfers">
            <Route
              index={true}
              element={<VarianceReportOnCmlTransfersPage />}
            />
          </Route>
          <Route path="variance-report-on-raw-material-transfers">
            <Route
              index={true}
              element={<VarianceReportOnRawMaterialTransfersPage />}
            />
          </Route>
          <Route path="variance-report-on-packaging-transfers">
            <Route
              index={true}
              element={<VarianceReportOnPackagingTransfersPage />}
            />
          </Route>
          <Route path="daily-rm-kitchen-variance-report">
            <Route
              index={true}
              element={<DailyRawMaterialKitchenVarianceReportPage />}
            />
          </Route>
          <Route path="daily-rm-finishing-outlet-variance-report">
            <Route
              index={true}
              element={<DailyRawMaterialFinishingOutletVarianceReportPage />}
            />
          </Route>
          <Route path="total-delivery-receipt-summary">
            <Route index={true} element={<TotalDeliveryReceiptSummaryPage />} />
          </Route>
          <Route path="donut-distribution-per-outlet">
            <Route index={true} element={<DonutDistributionPerOutletPage />} />
          </Route>
        </Route>
      </Route>
    </ReactRouterRoutes>
  );
};

export default Routes;
