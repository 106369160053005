import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  PackagingCategory,
  useMonthlyOutletPackagingInventoryQuery,
  useUpdateMonthlyOutletPackagingInventoryMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';

import { Box, Button, TextField, Typography } from '@mui/material';
import { z } from 'zod';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import Select from '../common/Select';
import OutletAutocomplete from '../shared/OutletAutocomplete';

const schema = z.object({
  date: z.coerce.date(),
  outlet: z
    .object({ id: z.coerce.number() })
    .transform((val) => (!val ? null : val)),
  packagingProduct: z.object({
    id: z.coerce.number().optional(),
    packaging: z
      .object({
        id: z.coerce.number(),
        name: z.string().trim().min(1),
        packagingCategory: z.nativeEnum(PackagingCategory),
        packagingProducts: z.array(
          z.object({
            unit: z
              .object({ id: z.coerce.number() })
              .transform((val) => (!val ? null : val)),
          })
        ),
      })
      .transform((val) => (!val ? null : val)),
    unit: z
      .object({ id: z.coerce.number() })
      .transform((val) => (!val ? null : val)),
  }),
  unit: z
    .object({ id: z.coerce.number() })
    .transform((val) => (!val ? null : val)),
  quantity: z.preprocess(
    (val) => (val === '' ? null : Number(val)),
    z.number()
  ),
});

type FormData = z.infer<typeof schema>;

const UpdateMonthlyOutletPackagingInventoryPage = ({
  title = `Update Monthly Outlet Packaging Inventory`,
  readOnly = false,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const [packagingProduct] = useWatch({
    control: methods.control,
    name: ['packagingProduct'],
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ packagingProduct, outlet, unit, ...data }) => {
        await updateMonthlyOutletPackagingInventory({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate({
                ...data,
              }),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              packagingProduct: {
                connect: {
                  unitId_packagingId: {
                    packagingId: packagingProduct?.packaging?.id || 0,
                    unitId: packagingProduct?.unit?.id || 0,
                  },
                },
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { monthlyOutletPackagingInventory } = {}, loading } =
    useMonthlyOutletPackagingInventoryQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    });

  const [updateMonthlyOutletPackagingInventory, { loading: mutationLoading }] =
    useUpdateMonthlyOutletPackagingInventoryMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (monthlyOutletPackagingInventory) {
      methods.reset({
        ...cleanPayload({
          ...monthlyOutletPackagingInventory,
          unit: monthlyOutletPackagingInventory?.packagingProduct?.unit,
        }),
      });
    }
  }, [monthlyOutletPackagingInventory, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <FormContainer maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
          >
            {title}
          </Typography>
          <Box component="form" onSubmit={onSubmit} noValidate>
            <Box
              component="div"
              sx={{
                display: 'grid',
                gap: 1,
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <Controller
                name="date"
                control={methods.control}
                defaultValue={'' as unknown as Date}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    {...textFieldHelperProps(field, fieldState, schema)}
                    {...field}
                  />
                )}
              />
              <OutletAutocomplete
                name={`outlet`}
                schema={schema}
                control={methods.control}
                label="Outlet"
                defaultValue={null}
                disabled={true}
              />
              <TextField
                label={`Category`}
                fullWidth
                value={packagingProduct?.packaging?.packagingCategory ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label={`Packaging`}
                fullWidth
                value={packagingProduct?.packaging?.name ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Controller
                name={`quantity`}
                control={methods.control}
                defaultValue={0}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Quantity"
                    {...textFieldHelperProps(field, fieldState, schema)}
                    {...field}
                    type="number"
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                  />
                )}
              />
              <Select
                name={`unit`}
                label="Unit"
                schema={schema}
                control={methods.control}
                arr={
                  (packagingProduct &&
                    packagingProduct.packaging?.packagingProducts?.map(
                      ({ unit }) => {
                        return { ...unit };
                      }
                    )) ||
                  []
                }
                defaultValue={null}
                renderValueAs={(obj) => `${obj.name}`}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
              disabled={mutationLoading}
              fullWidth
            >
              Submit
            </Button>
          </Box>
        </FormContainer>
      </FormProvider>
    </>
  );
};

export default UpdateMonthlyOutletPackagingInventoryPage;
