import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useCmlThrowAwayQuery,
  UserGroup,
  useUpdateCmlThrowAwayMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import CmlThrowAwayForm from './CmlThrowAwayForm';
import { FormData, schema } from './utils';
import Loading from '../common/Loading';

const UpdateCmlThrowAwayPage = ({ title = `Update Cml Throw Away` }) => {
  const { currentUser } = useAuthContext();
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        cmlThrowAwayCmlItems,
        cmlThrowAwayRawMaterialItems,
        cmlThrowAwayPackagingItems,
        ...data
      }) => {
        const checkedQuantityExists = [
          ...cmlThrowAwayCmlItems,
          ...cmlThrowAwayRawMaterialItems,
          ...cmlThrowAwayPackagingItems,
        ].some(({ checkedQuantity }) => checkedQuantity != null);

        const cmlThrowAwayCmlItemsToDelete = getItemsForDelete(
          cmlThrowAway?.cmlThrowAwayCmlItems,
          cmlThrowAwayCmlItems
        );
        const cmlThrowAwayCmlItemsToUpsert =
          getItemsForUpsert(cmlThrowAwayCmlItems);

        const cmlThrowAwayRawMaterialItemsToDelete = getItemsForDelete(
          cmlThrowAway?.cmlThrowAwayRawMaterialItems,
          cmlThrowAwayRawMaterialItems
        );
        const cmlThrowAwayRawMaterialItemsToUpsert = getItemsForUpsert(
          cmlThrowAwayRawMaterialItems
        );

        const cmlThrowAwayPackagingItemsToDelete = getItemsForDelete(
          cmlThrowAway?.cmlThrowAwayPackagingItems,
          cmlThrowAwayPackagingItems
        );
        const cmlThrowAwayPackagingItemsToUpsert = getItemsForUpsert(
          cmlThrowAwayPackagingItems
        );

        await updateCmlThrowAway({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate({
                ...data,
                ...(checkedQuantityExists && { checkedBy: currentUser?.name }),
              }),

              cmlThrowAwayCmlItems: {
                delete: cmlThrowAwayCmlItemsToDelete,
                upsert: cmlThrowAwayCmlItemsToUpsert.map(
                  ({ id, cmlProduct, quantity, checkedQuantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity, checkedQuantity }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity, checkedQuantity }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      },
                    };
                  }
                ),
              },

              cmlThrowAwayRawMaterialItems: {
                delete: cmlThrowAwayRawMaterialItemsToDelete,
                upsert: cmlThrowAwayRawMaterialItemsToUpsert.map(
                  ({ id, rawMaterial, unit, quantity, checkedQuantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity, checkedQuantity }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity, checkedQuantity }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },

              cmlThrowAwayPackagingItems: {
                delete: cmlThrowAwayPackagingItemsToDelete,
                upsert: cmlThrowAwayPackagingItemsToUpsert.map(
                  ({ id, packaging, unit, quantity, checkedQuantity }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate({ quantity, checkedQuantity }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate({ quantity, checkedQuantity }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { cmlThrowAway } = {}, loading } = useCmlThrowAwayQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateCmlThrowAway, { loading: mutationLoading }] =
    useUpdateCmlThrowAwayMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (cmlThrowAway) {
      const {
        cmlThrowAwayRawMaterialItems,
        cmlThrowAwayPackagingItems,
        cmlThrowAwayCmlItems,
        checkedBy,
        ...data
      } = cmlThrowAway;
      if (checkedBy) {
        handleSnackbarOpen({
          message: 'This form has been checked and can no longer be updated.',
          severity: 'error',
        });
        navigate(-1);
      }
      const setCheckedQuantity =
        currentUser?.userGroups?.includes(UserGroup.Superuser) ||
        currentUser?.userGroups?.includes(UserGroup.Accounting);

      methods.reset({
        ...cleanPayload({
          ...data,
          cmlThrowAwayCmlItems: cmlThrowAwayCmlItems.map(
            ({ cmlProduct, ...cmlThrowAwayCmlItem }) => {
              return {
                ...cmlThrowAwayCmlItem,
                ...(setCheckedQuantity &&
                  !checkedBy && {
                    checkedQuantity: cmlThrowAwayCmlItem.quantity,
                  }),
              };
            }
          ),
          cmlThrowAwayRawMaterialItems: cmlThrowAwayRawMaterialItems.map(
            ({ rawMaterialProduct, ...cmlThrowAwayRawMaterialItem }) => {
              return {
                rawMaterial: rawMaterialProduct?.rawMaterial,
                unit: rawMaterialProduct?.unit,
                ...cmlThrowAwayRawMaterialItem,
                ...(setCheckedQuantity &&
                  !checkedBy && {
                    checkedQuantity: cmlThrowAwayRawMaterialItem.quantity,
                  }),
              };
            }
          ),
          cmlThrowAwayPackagingItems: cmlThrowAwayPackagingItems.map(
            ({ packagingProduct, ...cmlThrowAwayPackagingItem }) => {
              return {
                packaging: packagingProduct?.packaging,
                unit: packagingProduct?.unit,
                ...cmlThrowAwayPackagingItem,
                ...(setCheckedQuantity &&
                  !checkedBy && {
                    checkedQuantity: cmlThrowAwayPackagingItem.quantity,
                  }),
              };
            }
          ),
        }),
      });
    }
  }, [cmlThrowAway, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <CmlThrowAwayForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateCmlThrowAwayPage;
