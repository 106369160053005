import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useDialogContext } from '../../contexts/dialogContext';
import {
  BeveragesDropdownDocument,
  DeliveryShiftSchedule,
  FormType,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import AlertDialog from '../common/AlertDialog';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import Select from '../common/Select';
import CmlProductAutocomplete from '../shared/CmlProductAutocomplete';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import PackagingAutocomplete from '../shared/PackagingAutocomplete';
import RawMaterialAutocomplete from '../shared/RawMaterialAutocomplete';
import { FormData } from './utils';

const EndingSdEmTaForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const { control } = useFormContext<FormData>();
  const {
    fields: endingSdEmTaCmlItemsFields,
    append: appendSdEmTaCmlItems,
    remove: removeSdEmTaCmlItems,
  } = useFieldArray({
    control,
    name: 'endingSdEmTaCmlItems',
  });

  const {
    fields: endingSdEmTaRawMaterialItemsFields,
    append: appendSdEmTaRawMaterialItems,
    remove: removeSdEmTaRawMaterialItems,
  } = useFieldArray({
    control,
    name: 'endingSdEmTaRawMaterialItems',
  });

  const {
    fields: endingSdEmTaPackagingItemsFields,
    append: appendSdEmTaPackagingItems,
    remove: removeSdEmTaPackagingItems,
  } = useFieldArray({
    control,
    name: 'endingSdEmTaPackagingItems',
  });

  const {
    fields: endingSdEmTaBeverageItemsFields,
    append: appendSdEmTaBeverageItems,
    remove: removeSdEmTaBeverageItems,
  } = useFieldArray({
    control,
    name: 'endingSdEmTaBeverageItems',
  });

  const handleAddEndingSdEmTaCmlItem = () => {
    appendSdEmTaCmlItems({
      cmlProduct: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveEndingSdEmTaCmlItem = (index: number) => {
    removeSdEmTaCmlItems(index);
  };

  const handleRemoveEndingSdEmTaRawMaterialItem = (index: number) => {
    removeSdEmTaRawMaterialItems(index);
  };
  const handleAddEndingSdEmTaRawMaterialItem = () => {
    appendSdEmTaRawMaterialItems({
      rawMaterial: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };

  const handleRemoveEndingSdEmTaPackagingItem = (index: number) => {
    removeSdEmTaPackagingItems(index);
  };
  const handleAddEndingSdEmTaPackagingItem = () => {
    appendSdEmTaPackagingItems({
      packaging: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };

  const handleAddEndingSdEmTaBeverageItem = () => {
    appendSdEmTaBeverageItems({
      beverage: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveEndingSdEmTaBeverageItem = (index: number) => {
    removeSdEmTaBeverageItems(index);
  };

  const [
    endingSdEmTaCmlItems,
    endingSdEmTaBeverageItems,
    endingSdEmTaRawMaterialItems,
    endingSdEmTaPackagingItems,
    formType,
    controlNumber,
    createdBy,
  ] = useWatch({
    control,
    name: [
      'endingSdEmTaCmlItems',
      'endingSdEmTaBeverageItems',
      'endingSdEmTaRawMaterialItems',
      'endingSdEmTaPackagingItems',
      'formType',
      'controlNumber',
      'createdBy',
    ],
  });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      {type === 'update' && (
        <Typography variant="subtitle2">{controlNumber}</Typography>
      )}
      <Box
        component="form"
        sx={{ mt: 2 }}
        onSubmit={(e) => {
          e.preventDefault();
          if (type === 'create') {
            handleDialogOpen(
              <AlertDialog
                handleClose={handleDialogClose}
                callback={() => {
                  onSubmit(e);
                }}
                dialogContentText={`Are you sure you are creating a ${
                  formType
                    ? getEnumKeyFromValue(FormType, formType)
                    : '<no selected form type yet>'
                } form?`}
              />
            );
          } else {
            onSubmit && onSubmit(e);
          }
        }}
        noValidate
      >
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Time"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="formType"
            label="Form Type"
            schema={schema}
            enumObj={FormType}
            control={control}
            defaultValue={'' as unknown as FormType}
            disabled={type === 'update'}
          />
          <OutletAutocomplete
            name={`outlet`}
            schema={schema}
            control={control}
            label="Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
          />
          <Controller
            name="remarks"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="receivedBy"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="Received By"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          {type === 'update' && (
            <>
              <TextField
                label="Created By"
                fullWidth
                value={createdBy ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </>
          )}
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          CML Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {endingSdEmTaCmlItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <CmlProductAutocomplete
                    name={`endingSdEmTaCmlItems.${index}.cmlProduct`}
                    schema={schema}
                    control={control}
                    label="CML Product"
                    defaultValue={null}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (endingSdEmTaCmlItems &&
                        endingSdEmTaCmlItems[index]?.cmlProduct?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`endingSdEmTaCmlItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveEndingSdEmTaCmlItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddEndingSdEmTaCmlItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {endingSdEmTaRawMaterialItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <RawMaterialAutocomplete
                    name={`endingSdEmTaRawMaterialItems.${index}.rawMaterial`}
                    schema={schema}
                    control={control}
                    label={`Raw Material ${index + 1}`}
                    defaultValue={null}
                  />

                  <Select
                    name={`endingSdEmTaRawMaterialItems.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={
                      endingSdEmTaRawMaterialItems?.[
                        index
                      ]?.rawMaterial?.rawMaterialProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                  <Controller
                    name={`endingSdEmTaRawMaterialItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Quantity ${index + 1}`}
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveEndingSdEmTaRawMaterialItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddEndingSdEmTaRawMaterialItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {endingSdEmTaPackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <PackagingAutocomplete
                    name={`endingSdEmTaPackagingItems.${index}.packaging`}
                    schema={schema}
                    control={control}
                    label={`Packaging ${index + 1}`}
                    defaultValue={null}
                  />
                  <Select
                    name={`endingSdEmTaPackagingItems.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={
                      endingSdEmTaPackagingItems?.[
                        index
                      ]?.packaging?.packagingProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                  <Controller
                    name={`endingSdEmTaPackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Quantity ${index + 1}`}
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveEndingSdEmTaPackagingItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddEndingSdEmTaPackagingItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Beverages
        </Typography>
        <Stack direction="column" spacing={1}>
          {endingSdEmTaBeverageItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <Controller
                    name={`endingSdEmTaBeverageItems.${index}.beverage`}
                    control={control}
                    defaultValue={null}
                    render={({ field, fieldState }) => (
                      <SearchAsYouTypeAutocomplete
                        textFieldProps={{
                          ...textFieldHelperProps(field, fieldState, schema),
                          label: `Beverage ${index + 1}`,
                        }}
                        queryOptions={(value) => {
                          return {
                            query: BeveragesDropdownDocument,
                            variables: {
                              where: {
                                OR: [
                                  {
                                    name: {
                                      contains: value,
                                      mode: 'insensitive',
                                    },
                                  },
                                ],
                              },
                            },
                          };
                        }}
                        getOptionLabel={(option: any) => option?.name}
                        value={field.value}
                        onChange={(e, value: any) => {
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (endingSdEmTaBeverageItems &&
                        endingSdEmTaBeverageItems[index]?.beverage?.unit
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`endingSdEmTaBeverageItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Quantity ${index + 1}`}
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveEndingSdEmTaBeverageItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddEndingSdEmTaBeverageItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default EndingSdEmTaForm;
