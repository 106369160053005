import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { stringToUrl } from '../../utils/stringFunctions';
import { useAuthContext } from '../../contexts/authContext';
import { UserGroup } from '../../graphql/enums/UserGroup';

interface Props {
  handleDrawerClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const navLinks = [
  {
    groupName: 'General',
    routes: [
      {
        name: 'Users',
      },
      {
        name: 'Cheat Sheets',
      },
    ],
  },
  {
    groupName: 'Accounting',
    routes: [
      {
        name: 'Audit Trails',
      },
      {
        name: 'Outlets',
      },
      {
        name: 'Donut Mix and Cuts',
      },
      {
        name: 'Units',
      },
      {
        name: 'Raw Materials',
      },
      {
        name: 'Raw Material Product Recipes',
      },
      {
        name: 'Packagings',
      },
      {
        name: 'CML Products',
      },
      {
        name: 'CML Product Recipes',
      },
      {
        name: 'Beverages',
      },
      {
        name: 'Warehouse Inventories',
      },
      {
        name: 'CRT Inputs',
      },
      {
        name: 'Daily RM Kitchen Variance Report',
      },
      {
        name: 'Daily RM Finishing Outlet Variance Report',
      },
      {
        name: 'Sales Product Control Report',
      },
      {
        name: 'Sales Packaging Control Report',
      },
      {
        name: 'Consolidated Throw Away Report',
      },
      {
        name: 'Warehouse Packaging Inventory Report',
      },
      {
        name: 'Outlet Packaging Ending Consolidation Report',
      },
      {
        name: 'Warehouse Raw Material Inventory Report',
      },
      {
        name: 'Variance Report on Delivery Receipt Items',
      },
      {
        name: 'Variance Report on SDS Raw Material Items',
      },
      {
        name: 'Variance Report on SDS Packaging Items',
      },
      {
        name: 'Variance Report on CML Transfers',
      },
      {
        name: 'Variance Report on Raw Material Transfers',
      },
      {
        name: 'Variance Report on Packaging Transfers',
      },
    ],
  },
  {
    groupName: 'Production',
    routes: [
      {
        name: 'Delivery Receipts',
      },
      {
        name: 'CML Throw Aways',
      },
      {
        name: 'Donut Distribution Per Outlet',
      },
      {
        name: 'Total Delivery Receipt Summary',
      },
    ],
  },
  {
    groupName: 'Warehouse',
    routes: [
      {
        name: 'Raw Material Finishing Endings',
        path: {
          pathname: 'raw-material-finishing-endings',
          search: queryString.stringify({
            rawMaterialFinishingEndingLocation: 'WAREHOUSE',
          }),
        },
      },
      {
        name: 'Raw Material Releases',
      },
      {
        name: 'SDS Delivery Receipts',
      },
      {
        name: 'Purchase Orders',
      },
      {
        name: 'Between Entity Transfers',
      },
      {
        name: 'Projection to Raw Materials',
      },
    ],
  },
  {
    groupName: 'Outlet',
    routes: [
      {
        name: 'Projections',
      },
      {
        name: 'Packaging Requests',
      },
      {
        name: 'Raw Material Requests',
      },
      {
        name: 'Raw Material Finishing Endings',
        path: {
          pathname: 'raw-material-finishing-endings',
          search: queryString.stringify({
            rawMaterialFinishingEndingLocation: 'OUTLET',
          }),
        },
      },
      {
        name: 'Received SDS Delivery Receipts',
      },
      {
        name: 'Received Delivery Receipts',
      },
      {
        name: 'Ending/SD/EM/TAs',
        path: 'ending-sd-em-tas',
      },
      {
        name: 'Transfers',
      },
      {
        name: 'Received Transfers',
      },
      {
        name: 'Outlet Finishings',
      },
      {
        name: 'Monthly Outlet Packaging Inventory Inputs',
        path: 'monthly-outlet-packaging-inventories',
      },
      {
        name: 'Projection Summary per Outlet',
      },
      {
        name: 'Donut Ending Consolidation Report',
      },
    ],
  },
];

const driverNavLinks = [
  {
    groupName: 'Driver',
    routes: [
      {
        name: 'Cheat Sheets',
      },
      {
        name: 'Delivery Receipts',
      },
      {
        name: 'SDS Delivery Receipts',
      },
      {
        name: 'Ending/SD/EM/TAs',
        path: 'ending-sd-em-tas',
      },
      {
        name: 'Transfers',
      },
    ],
  },
];

const Nav = ({ handleDrawerClose }: Props) => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const links = currentUser?.userGroups.includes(UserGroup.Driver)
    ? driverNavLinks
    : navLinks;
  return (
    <>
      <Box onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
        <List component="nav">
          {links.map((item, idx) => (
            <div key={idx}>
              <ListSubheader
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.common.white,
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                {item.groupName}
              </ListSubheader>
              {item.routes.map((route, idx) => (
                <ListItemButton
                  key={idx}
                  onClick={() =>
                    route?.path
                      ? navigate(route.path)
                      : navigate(stringToUrl(route.name))
                  }
                >
                  <ListItemText disableTypography inset={false}>
                    {route.name}
                  </ListItemText>
                </ListItemButton>
              ))}
            </div>
          ))}
        </List>
      </Box>
    </>
  );
};

export default Nav;
