import { z } from 'zod';
import { DeliveryShiftSchedule } from '../../graphql/enums/DeliveryShiftSchedule';
import { PackagingCategory } from '../../graphql/enums/PackagingCategory';
import { RawMaterialCategory } from '../../graphql/enums/RawMaterialCategory';


export const schema = z.object({
  dateTime: z.coerce.date(),
  receivedBy: z.string().nullish(),
  deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
  sdsDeliveryReceipt: z.object({ 
    id: z.coerce.number(),
  }).transform((val) => !val ? null : val),
  outlet: z.object({ 
    id: z.coerce.number(),
  }).transform((val) => !val ? null : val).nullish(),
  receivedSdsDeliveryReceiptRawMaterialItems: z.array(z.object({
    id: z.coerce.number().optional(),
    sdsDeliveryReceiptRawMaterialItem: z.object({ 
      id: z.coerce.number(),
      quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
      rawMaterialProduct: z.object({ 
        id: z.coerce.number(),
        rawMaterial: z.object({ 
          id: z.coerce.number(),
          rawMaterialCategory: z.nativeEnum(RawMaterialCategory),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val)
      }).transform((val) => !val ? null : val),
    }).transform((val) => !val ? null : val),
    receivedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    rejectedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  receivedSdsDeliveryReceiptPackagingItems: z.array(z.object({
    id: z.coerce.number().optional(),
    sdsDeliveryReceiptPackagingItem: z.object({ 
      id: z.coerce.number(),
      quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
      packagingProduct: z.object({ 
        id: z.coerce.number(),
        packaging: z.object({ 
          id: z.coerce.number(),
          packagingCategory: z.nativeEnum(PackagingCategory),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val)
      }).transform((val) => !val ? null : val),
    }).transform((val) => !val ? null : val),
    receivedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    rejectedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  }))
})

export type FormData = z.infer<typeof schema>




