import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateCmlThrowAwayMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import CmlThrowAwayForm from './CmlThrowAwayForm';
import { FormData, schema } from './utils';

const CreateCmlThrowAwayPage = ({ title = `Create CML Throw Away` }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  // const cmlThrowAwaylocation = useWatch({
  //   control: methods.control,
  //   name: 'cmlThrowAwayLocation',
  // });

  // const { data: { cmlProducts } = {}, loading: cmlProductsLoading } =
  //   useCmlProductsQuery({
  //     fetchPolicy: 'network-only',
  //     variables: {
  //       where: {
  //         status: {
  //           equals: Status.Active,
  //         },
  //       },
  //       orderBy: [{ name: SortOrder.Asc }],
  //     },
  //   });

  // const {
  //   data: { rawMaterialProducts } = {},
  //   loading: rawMaterialProductsLoading,
  // } = useRawMaterialProductsQuery({
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     where: {
  //       rawMaterial: {
  //         is: {
  //           status: {
  //             equals: Status.Active,
  //           },
  //         },
  //       },
  //     },
  //     orderBy: [
  //       { rawMaterial: { rawMaterialCategory: SortOrder.Asc } },
  //       { rawMaterial: { name: SortOrder.Asc } },
  //     ],
  //   },
  // });

  // const {
  //   data: { packagingProducts } = {},
  //   loading: packagingProductsLoading,
  // } = usePackagingProductsQuery({
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     where: {
  //       packaging: {
  //         is: {
  //           status: {
  //             equals: Status.Active,
  //           },
  //         },
  //       },
  //     },
  //     orderBy: [
  //       { packaging: { packagingCategory: SortOrder.Asc } },
  //       { packaging: { name: SortOrder.Asc } },
  //     ],
  //   },
  // });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({
          cmlThrowAwayCmlItems,
          cmlThrowAwayRawMaterialItems,
          cmlThrowAwayPackagingItems,
          ...data
        }) =>
          await createCmlThrowAway({
            variables: {
              data: {
                ...prismaCreate(data),
                controlNumber: '',
                createdBy: currentUser.name,
                cmlThrowAwayCmlItems: {
                  create: cmlThrowAwayCmlItems.map(
                    ({ cmlProduct, quantity, ...cmlThrowAwayCmlItem }) => {
                      return {
                        ...prismaCreate({
                          ...cmlThrowAwayCmlItem,
                          quantity,
                        }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      };
                    }
                  ),
                },
                cmlThrowAwayRawMaterialItems: {
                  create: cmlThrowAwayRawMaterialItems.map(
                    ({
                      rawMaterial,
                      unit,
                      quantity,
                      ...cmlThrowAwayRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...cmlThrowAwayRawMaterialItem,
                          quantity,
                        }),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              rawMaterialId: rawMaterial?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
                cmlThrowAwayPackagingItems: {
                  create: cmlThrowAwayPackagingItems.map(
                    ({
                      packaging,
                      unit,
                      quantity,
                      ...cmlThrowAwayPackagingItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...cmlThrowAwayPackagingItem,
                          quantity,
                        }),
                        packagingProduct: {
                          connect: {
                            unitId_packagingId: {
                              packagingId: packaging?.id || 0,
                              unitId: unit?.id || 0,
                            },
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createCmlThrowAway, { loading: mutationLoading }] =
    useCreateCmlThrowAwayMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  // useEffect(() => {
  //   if (
  //     cmlThrowAwaylocation === 'PRODUCTION' &&
  //     rawMaterialProducts &&
  //     cmlProducts
  //   ) {
  //     methods.reset((values) => {
  //       return {
  //         ...cleanPayload({
  //           ...values,
  //           cmlThrowAwayPackagingItems: [],
  //           cmlThrowAwayCmlItems: cmlProducts?.map((cmlProduct) => {
  //             return {
  //               cmlProduct,
  //             };
  //           }),
  //           cmlThrowAwayRawMaterialItems: rawMaterialProducts.map(
  //             (rawMaterialProduct) => {
  //               return {
  //                 rawMaterialProduct,
  //               };
  //             }
  //           ),
  //         }),
  //       };
  //     });
  //   } else if (
  //     cmlThrowAwaylocation === 'WAREHOUSE' &&
  //     packagingProducts &&
  //     rawMaterialProducts
  //   ) {
  //     methods.reset((values) => {
  //       return {
  //         ...cleanPayload({
  //           ...values,
  //           cmlThrowAwayRawMaterialItems: rawMaterialProducts.map(
  //             (rawMaterialProduct) => {
  //               return {
  //                 rawMaterialProduct,
  //               };
  //             }
  //           ),
  //           cmlThrowAwayCmlItems: [],
  //           cmlThrowAwayPackagingItems: packagingProducts.map(
  //             (packagingProduct) => {
  //               return {
  //                 packagingProduct,
  //               };
  //             }
  //           ),
  //         }),
  //       };
  //     });
  //   }
  // }, [
  //   cmlThrowAwaylocation,
  //   rawMaterialProducts,
  //   packagingProducts,
  //   cmlProducts,
  //   methods,
  // ]);

  // if (
  //   rawMaterialProductsLoading ||
  //   packagingProductsLoading ||
  //   cmlProductsLoading
  // )
  //   return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <CmlThrowAwayForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateCmlThrowAwayPage;
