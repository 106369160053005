import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import Select from '../common/Select';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import PackagingAutocomplete from '../shared/PackagingAutocomplete';
import { FormData } from './utils';

const PackagingRequestForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packagingRequestItems',
  });
  const [createdBy, controlNumber, packagingRequestItems] = useWatch({
    control,
    name: ['createdBy', 'controlNumber', 'packagingRequestItems'],
  });

  const handleAddPackagingRequestItem = () => {
    append({
      id: 0,
      unit: null,
      quantity: undefined as unknown as number,
      packaging: null,
    });
  };

  const handleRemovePackagingRequestItem = (index: number) => {
    remove(index);
  };

  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      {type === 'update' && (
        <Typography variant="subtitle2">{controlNumber}</Typography>
      )}
      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="requestDate"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Request Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="neededDate"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Needed Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <OutletAutocomplete
            name={`outlet`}
            schema={schema}
            control={control}
            label="Outlet"
            defaultValue={null}
          />

          {type === 'update' && (
            <TextField
              label="Created By"
              fullWidth
              value={createdBy ?? ''}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
                inputProps: { tabIndex: -1 },
              }}
            />
          )}
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <PackagingAutocomplete
                    name={`packagingRequestItems.${index}.packaging`}
                    schema={schema}
                    control={control}
                    label="Packaging"
                    defaultValue={null}
                    onChange={(value: any) => {
                      setValue(
                        `packagingRequestItems.${index}.unit`,
                        value?.packagingProducts?.find(
                          ({ baseUnit }: { baseUnit: boolean }) =>
                            baseUnit === true
                        )?.unit || null
                      );
                    }}
                  />
                  {/* <Controller
                    name={`packagingRequestItems.${index}.packaging`}
                    control={control}
                    defaultValue={null}
                    render={({ field, fieldState }) => (
                      <SearchAsYouTypeAutocomplete
                        textFieldProps={{
                          ...textFieldHelperProps(field, fieldState, schema),
                          label: 'Packaging',
                        }}
                        queryOptions={(value) => {
                          return {
                            query: PackagingsDocument,
                            variables: {
                              where: {
                                OR: [
                                  {
                                    name: {
                                      contains: value,
                                      mode: 'insensitive',
                                    },
                                  },
                                ],
                              },
                            },
                          };
                        }}
                        getOptionLabel={(option: any) => option?.name}
                        value={field.value}
                        onChange={(e, value: any) => {
                          setValue(
                            `packagingRequestItems.${index}.unit`,
                            value?.packagingProducts?.find(
                              ({ baseUnit }: { baseUnit: boolean }) =>
                                baseUnit === true
                            )?.unit || null
                          );
                          field.onChange(value);
                        }}
                      />
                    )}
                  /> */}
                  <Select
                    name={`packagingRequestItems.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={
                      (packagingRequestItems &&
                        packagingRequestItems?.[
                          index
                        ]?.packaging?.packagingProducts?.map(
                          ({ unit }) => unit
                        )) ||
                      []
                    }
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                    disabled={!packagingRequestItems?.[index]?.packaging}
                  />
                  <Controller
                    name={`packagingRequestItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>

                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemovePackagingRequestItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddPackagingRequestItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default PackagingRequestForm;
