import { format } from "date-fns";


export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() +
  string.slice(1).toLowerCase()
}

export const stringToUrl = (string: string, prepend: string = "", append: string = "") => 
  prepend +
  "/" +
  string
    .toLowerCase()
    .replace(/[^a-zA-Z0-9_-\s]/g, "")
    .replace(/\s+/g, "-") +
  append

export const getEnumValue = <T,>(enumObj: T, key?: keyof T) => key ? enumObj[key] : "";

export const getEnumKeyFromValue = <T extends Record<any,any>>( enumObj: T, value: string | null | undefined): string => {
  const indexOfValue = Object.values(enumObj).indexOf(value as unknown as T)
  const key = Object.keys(enumObj)[indexOfValue]
  if (!value) return ""
  return key
}

export const dateToString = (date?: Date, stringFormat: string = "MM/dd/yyyy") => date && format(new Date(date), stringFormat)
