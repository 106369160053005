import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateCmlProductDocument,
  CmlProductsDocument,
  CmlProductWhereInput,
  ProductCategory,
  QueryMode,
  SalesCategory,
  SortOrder,
  Status,
  useCmlProductsQuery,
  useDeleteManyCmlProductMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { roundFloat } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const CmlProductsPage = ({ title = 'CML Products' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, statusQueryString, salesCategoryQueryString] =
    useGetQueryString(['search', 'status', 'salesCategory']);

  const where: CmlProductWhereInput = Object.assign(
    {},
    statusQueryString
      ? {
          status: { equals: statusQueryString as Status },
        }
      : {},
    salesCategoryQueryString
      ? {
          salesCategory: {
            equals: salesCategoryQueryString as SalesCategory,
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              donutMixAndCut: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { cmlProducts } = {},
    loading,
    error,
  } = useCmlProductsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyCmlProduct] = useDeleteManyCmlProductMutation({
    refetchQueries: [
      { query: AggregateCmlProductDocument, variables: countVariables },
      {
        query: CmlProductsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof cmlProducts>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Product Category',
      accessor: 'productCategory',
      Cell: (children) => getEnumKeyFromValue(ProductCategory, children),
    },
    {
      header: 'Donut Mix and Cut',
      accessor: 'donutMixAndCut.name',
    },
    {
      header: 'Sales Category',
      accessor: 'salesCategory',
      Cell: (children) => getEnumKeyFromValue(SalesCategory, children),
    },
    {
      header: 'Unit',
      accessor: 'unit.name',
    },
    {
      header: 'Status',
      accessor: 'status',
      Cell: (children) => getEnumKeyFromValue(Status, children),
    },
    {
      header: 'Ingredients',
      accessor: 'latestCmlProductIngredients',
      Cell: (children: {}[], index: number) => {
        const latestCmlProductIngredientColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof cmlProducts>
              >['latestCmlProductIngredients']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={latestCmlProductIngredientColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyCmlProduct({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <EnumSearchFilter
            label="Sales Category"
            field="salesCategory"
            enumObj={SalesCategory}
          />
          <EnumSearchFilter label="Status" field="status" enumObj={Status} />
          <Search />
        </>
      }
      dataTable={<DataTable data={cmlProducts} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateCmlProductDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default CmlProductsPage;
