import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateWarehouseInventoryDocument,
  PackagingCategory,
  QueryMode,
  RawMaterialCategory,
  SortOrder,
  useDeleteManyWarehouseInventoryMutation,
  useWarehouseInventoriesQuery,
  WarehouseInventoriesDocument,
  WarehouseInventoryWhereInput,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const WarehouseInventoriesPage = ({ title = 'Warehouse Inventories' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: WarehouseInventoryWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          dateTime: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              warehouseInventoryRawMaterialItems: {
                some: {
                  rawMaterialProduct: {
                    is: {
                      rawMaterial: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              warehouseInventoryPackagingItems: {
                some: {
                  packagingProduct: {
                    is: {
                      packaging: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        dateTime: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { warehouseInventories } = {},
    loading,
    error,
  } = useWarehouseInventoriesQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyWarehouseInventory] =
    useDeleteManyWarehouseInventoryMutation({
      refetchQueries: [
        {
          query: AggregateWarehouseInventoryDocument,
          variables: countVariables,
        },
        {
          query: WarehouseInventoriesDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Delete' + title.toLowerCase() + ' error! ' + error,
          severity: 'error',
        }),
    });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof warehouseInventories>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date Time',
      accessor: 'dateTime',
      Cell: (children) => dateTimeToString(children, 'MM/dd/yyyy h:mm:ss aaa'),
    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Remarks',
      accessor: 'remarks',
    },
    {
      header: 'Raw Material',
      accessor: 'warehouseInventoryRawMaterialItems',
      Cell: (children: {}[], index: number) => {
        const warehouseInventoryRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof warehouseInventories>
              >['warehouseInventoryRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Category',
            accessor: 'rawMaterialProduct.rawMaterial.rawMaterialCategory',
            Cell: (children) =>
              getEnumKeyFromValue(RawMaterialCategory, children),
          },
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={warehouseInventoryRawMaterialItemsColumns}
          />
        );
      },
    },
    {
      header: 'Packaging',
      accessor: 'warehouseInventoryPackagingItems',
      Cell: (children: {}[], index: number) => {
        const warehouseInventoryPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof warehouseInventories>
              >['warehouseInventoryPackagingItems']
            >
          >
        > = [
          {
            header: 'Category',
            accessor: 'packagingProduct.packaging.packagingCategory',
            Cell: (children) =>
              getEnumKeyFromValue(PackagingCategory, children),
          },
          {
            header: 'Packaging',
            accessor: 'packagingProduct.packaging.name',
          },
          {
            header: 'Unit',
            accessor: 'packagingProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={warehouseInventoryPackagingItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyWarehouseInventory({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={<DataTable data={warehouseInventories} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateWarehouseInventoryDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default WarehouseInventoriesPage;
