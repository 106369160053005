import { Box } from '@mui/material';
import background from '../../images/home/background.jpg';

const HomePage = () => {
  return (
    <>
      <Box
        component="img"
        src={background}
        alt="logo"
        sx={{
          maxWidth: '100%',
          objectFit: 'cover',
          m: 'auto',
        }}
      />
    </>
  );
};

export default HomePage;
