import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { convertToArray } from '../../utils/queryString';
import { set } from 'date-fns';

interface Props {
  field: string;
  label: string;
  arr: any[] | undefined;
  setAccessor: (value: any) => string;
  setValue?: (value: any) => any;
  multiple?: boolean;
}

const SearchFilter = ({
  field,
  arr,
  label,
  setAccessor,
  setValue = (value) => value,
  multiple = false,
}: Props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const parsed = queryString.parse(location.search);
  const initialQueryStringValue = multiple
    ? convertToArray(parsed[field]) || []
    : parsed[field] || undefined;

  const [selectValue, setSelectValue] = useState(initialQueryStringValue);

  const handleChange = (e: SelectChangeEvent<any>) => {
    let value = e.target.value;
    setSelectValue(value);
    if (!value) {
      delete parsed[field];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({ ...parsed }),
      });
    } else {
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({ ...parsed, [field]: value }),
      });
    }
  };

  return (
    <>
      <FormControl size="small">
        <InputLabel id={`${field}-label`}>{label}</InputLabel>
        <Select
          labelId={`${field}-label`}
          label={label}
          value={selectValue ?? ''}
          multiple={multiple}
          onChange={handleChange}
          {...(multiple && {
            renderValue: (selected: any) => {
              return arr
                ?.filter((item) => selected.includes(setValue(item).toString()))
                .map((item) => setAccessor(item))
                .join(', ');
            },
          })}
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            minWidth: '220px',
          }}
        >
          {!multiple && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {arr?.map((value, idx) => {
            return (
              <MenuItem key={idx} value={setValue(value).toString()}>
                {multiple && (
                  <Checkbox
                    checked={
                      ((selectValue as any[]) || [])
                        .map((item) => item.toString())
                        .indexOf(setValue(value).toString()) > -1
                    }
                  />
                )}
                {setAccessor(value)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default SearchFilter;
