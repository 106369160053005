import {
  DeliveryShiftSchedule,
  DonutEndingConsolidationWhereInput,
  RawMaterialCategory,
  useDailyRawMaterialKitchenVarianceReportQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateFilter from '../common/DateFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const DailyRawMaterialKitchenVarianceReportPage = ({
  title = 'Daily RM Kitchen Variance Report',
}) => {
  const [dateQueryString, deliveryShiftScheduleQueryString, searchQueryString] =
    useGetQueryString(['date', 'deliveryShiftSchedule', 'search']);

  const where: DonutEndingConsolidationWhereInput = Object.assign(
    {},
    deliveryShiftScheduleQueryString
      ? {
          deliveryShiftSchedule:
            deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
        }
      : {},
    typeof dateQueryString === 'string'
      ? {
          date: new Date(dateQueryString),
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          searchTerm: searchQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const {
    data: { dailyRawMaterialKitchenVarianceReport } = {},
    loading,
    error,
  } = useDailyRawMaterialKitchenVarianceReportQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !dateQueryString || !deliveryShiftScheduleQueryString,
  });

  const columns: Columns<
    Paths<
      ObjectFromArray<NonNullable<typeof dailyRawMaterialKitchenVarianceReport>>
    >
  > = [
    {
      header: 'Category',
      accessor: 'category',
      Cell: (children) => getEnumKeyFromValue(RawMaterialCategory, children),
    },
    {
      header: 'Raw Material',
      accessor: 'rawMaterial',
    },
    {
      header: 'Unit',
      accessor: 'unit',
    },
    {
      header: 'Beginning',
      accessor: 'beginning',
    },
    {
      header: 'From Store Room',
      accessor: 'fromStoreRoom',
    },
    {
      header: 'Throw Away',
      accessor: 'throwAway',
    },
    {
      header: 'Theoretical',
      accessor: 'theoretical',
    },
    {
      header: 'Should Be',
      accessor: 'shouldBe',
    },
    {
      header: 'Actual Ending',
      accessor: 'actualEnding',
    },
    {
      header: 'Variance',
      accessor: 'variance',
    },
  ];

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={
          <>
            <DateFilter dateField="date" label="Date" />
            <EnumSearchFilter
              label="Delivery Shift Schedule"
              field="deliveryShiftSchedule"
              enumObj={DeliveryShiftSchedule}
            />
            <Search />
          </>
        }
        dataTable={
          <DataTable
            data={dailyRawMaterialKitchenVarianceReport}
            columns={columns}
          />
        }
      />
    </>
  );
};

export default DailyRawMaterialKitchenVarianceReportPage;
