import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useDeliveryReceiptQuery,
  useUpdateDeliveryReceiptMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import DeliveryReceiptForm from './DeliveryReceiptForm';
import { FormData, schema } from './utils';

const UpdateDeliveryReceiptPage = ({ title = `Update Delivery Receipt` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        deliveryReceiptItems,
        outlet,
        receivedDeliveryReceipt,
        ...data
      }) => {
        await updateDeliveryReceipt({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              deliveryReceiptItems: {
                update: deliveryReceiptItems.map(({ id, quantity }) => {
                  return {
                    where: {
                      id,
                    },
                    data: {
                      ...prismaUpdate({ quantity }),
                    },
                  };
                }),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { deliveryReceipt } = {}, loading } = useDeliveryReceiptQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateDeliveryReceipt, { loading: mutationLoading }] =
    useUpdateDeliveryReceiptMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (deliveryReceipt) {
      const {
        deliveryReceiptItems,
        ydmFryingTime,
        yrcFryingTime,
        chmFryingTime,
        cdmFryingTime,
        pymFryingTime,
        ...data
      } = deliveryReceipt;
      if (data?.receivedByDriver) {
        handleSnackbarOpen({
          message: 'This form has been received and can no longer be updated.',
          severity: 'error',
        });
        navigate(-1);
      }
      methods.reset({
        ...cleanPayload({
          ...data,
          deliveryReceiptItems: deliveryReceiptItems.map(
            ({
              cmlProduct,

              ...cmlProductIngredient
            }) => {
              return {
                cmlProduct,
                ...cmlProductIngredient,
              };
            }
          ),
        }),
        ydmFryingTime: format(new Date(ydmFryingTime), 'HH:mm'),
        yrcFryingTime: format(new Date(yrcFryingTime), 'HH:mm'),
        chmFryingTime: format(new Date(chmFryingTime), 'HH:mm'),
        cdmFryingTime: format(new Date(cdmFryingTime), 'HH:mm'),
        pymFryingTime: format(new Date(pymFryingTime), 'HH:mm'),
      });
    }
  }, [deliveryReceipt, methods, handleSnackbarOpen, navigate]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <DeliveryReceiptForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateDeliveryReceiptPage;
