import { z } from 'zod';
import { DeliveryShiftSchedule } from '../../graphql/enums/DeliveryShiftSchedule';
import { PackagingCategory } from '../../graphql/enums/PackagingCategory';
import { RawMaterialCategory } from '../../graphql/enums/RawMaterialCategory';


export const schema = z.object({
  date: z.coerce.date(),
  destination: z.string().optional(),
  deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
  rawMaterialReleaseForBreakdowns: z.array(z.object({
    id: z.coerce.number().optional(),
    rawMaterial: z.object({ 
      id: z.coerce.number(),
      rawMaterialCategory: z.nativeEnum(RawMaterialCategory),
      name: z.string().trim().min(1),
      rawMaterialProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    referenceQuantity: z.coerce.number(),
    referenceUnit: z.string().trim().min(1),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  rawMaterialReleaseItems: z.array(z.object({
    id: z.coerce.number().optional(),
    rawMaterial: z.object({ 
      id: z.coerce.number(),
      rawMaterialCategory: z.nativeEnum(RawMaterialCategory),
      name: z.string().trim().min(1),
      rawMaterialProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    referenceQuantity: z.coerce.number(),
    referenceUnit: z.string().trim().min(1),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  rawMaterialReleaseItemsFromBreakdowns: z.array(z.object({
    id: z.coerce.number().optional(),
    rawMaterial: z.object({ 
      id: z.coerce.number(),
      rawMaterialCategory: z.nativeEnum(RawMaterialCategory),
      name: z.string().trim().min(1),
      rawMaterialProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    referenceQuantity: z.coerce.number(),
    referenceUnit: z.string().trim().min(1),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  rawMaterialReleasePackagingItems: z.array(z.object({
    id: z.coerce.number().optional(),
    packaging: z.object({ 
      id: z.coerce.number(),
      packagingCategory: z.nativeEnum(PackagingCategory),
      name: z.string().trim().min(1),
      packagingProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number())
  }))
})

export type FormData = z.infer<typeof schema>




