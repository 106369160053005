import KeyIcon from '@mui/icons-material/Key';
import { Checkbox, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateUserDocument,
  QueryMode,
  SortOrder,
  useDeleteManyUserMutation,
  UserGroup,
  UsersDocument,
  UserWhereInput,
  useUsersQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const UsersPage = ({ title = 'Users' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, userGroupQueryString] = useGetQueryString([
    'search',
    'userGroup',
  ]);

  const where: UserWhereInput = Object.assign(
    {},
    userGroupQueryString
      ? {
          userGroups: { has: userGroupQueryString as UserGroup },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              username: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        username: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { users } = {},
    loading,
    error,
  } = useUsersQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyUser] = useDeleteManyUserMutation({
    refetchQueries: [
      { query: AggregateUserDocument, variables: countVariables },
      {
        query: UsersDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<Paths<ObjectFromArray<NonNullable<typeof users>>>> = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Username',
      accessor: 'username',
    },
    {
      header: 'User Groups',
      accessor: 'userGroups',
      Cell: (children?: string[]) =>
        children
          ?.map((child) => getEnumKeyFromValue(UserGroup, child))
          .join(', '),
    },
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Outlets',
      accessor: 'outlets',
      Cell: (children: {}[], index: number) => {
        const outletColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<NonNullable<typeof users>>['outlets']
            >
          >
        > = [
          {
            header: 'Name',
            accessor: 'name',
          },
        ];
        return (
          <DataTableAccordion
            title="Names"
            data={children}
            index={index}
            columns={outletColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyUser({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        >
          {selected.length === 1 && (
            <MenuItem
              onClick={() =>
                navigate(`${location.pathname}/${selected[0]}/change-password`)
              }
            >
              <KeyIcon />
              Change Password
            </MenuItem>
          )}
        </ActionsMenu>
      }
      filters={
        <>
          <EnumSearchFilter
            label="User Group"
            field="userGroup"
            enumObj={UserGroup}
          />
          <Search />
        </>
      }
      dataTable={<DataTable data={users} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateUserDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default UsersPage;
