import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateCmlProductRecipeDocument,
  CmlProductRecipesDocument,
  CmlProductRecipeWhereInput,
  QueryMode,
  SortOrder,
  useCmlProductRecipesQuery,
  useDeleteManyCmlProductRecipeMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const CmlProductRecipesPage = ({ title = 'Cml Product Recipes' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: CmlProductRecipeWhereInput = Object.assign(
    {},

    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              cmlProduct: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              cmlProduct: {
                is: {
                  donutMixAndCut: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              cmlProductIngredients: {
                some: {
                  rawMaterialProduct: {
                    is: {
                      rawMaterial: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { cmlProductRecipes } = {},
    loading,
    error,
  } = useCmlProductRecipesQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyCmlProductRecipe] = useDeleteManyCmlProductRecipeMutation({
    refetchQueries: [
      { query: AggregateCmlProductRecipeDocument, variables: countVariables },
      {
        query: CmlProductRecipesDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof cmlProductRecipes>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Name',
      accessor: 'cmlProduct.name',
    },
    {
      header: 'Donut Mix and Cut',
      accessor: 'cmlProduct.donutMixAndCut.name',
    },
    {
      header: 'Unit',
      accessor: 'cmlProduct.unit.name',
    },
    {
      header: 'Ingredients',
      accessor: 'cmlProductIngredients',
      Cell: (children: {}[], index: number) => {
        const rawMaterialProductColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof cmlProductRecipes>
              >['cmlProductIngredients']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={rawMaterialProductColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyCmlProductRecipe({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <Search />
        </>
      }
      dataTable={<DataTable data={cmlProductRecipes} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateCmlProductRecipeDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default CmlProductRecipesPage;
