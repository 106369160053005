import { z } from 'zod';
import { DeliveryShiftSchedule } from '../../graphql/enums/DeliveryShiftSchedule';


export const schema = z.object({
  date: z.coerce.date(),
  deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
  controlNumber: z.string().nullish(),
  createdBy: z.string().nullish(),
  fromOutlet: z.object({ id: z.coerce.number() }).transform((val) => !val ? null : val),
  toOutlet: z.object({ id: z.coerce.number() }).transform((val) => !val ? null : val),
  transferCmlItems: z.array(z.object({
    id: z.coerce.number().optional(),
    cmlProduct: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),

      unit: z.object({ 
        id: z.coerce.number(),
        name: z.string().trim().min(1),
      }).transform((val) => !val ? null : val)
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  transferRawMaterialItems: z.array(z.object({
    id: z.coerce.number().optional(),
    rawMaterial: z.object({ 
      id: z.coerce.number(),

      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  transferPackagingItems: z.array(z.object({
    id: z.coerce.number().optional(),
    packaging: z.object({ 
      id: z.coerce.number(),

      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number())
  })),
})

export type FormData = z.infer<typeof schema>




