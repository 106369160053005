import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  DeliveryShiftSchedule,
  TransfersDocument,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import { FormData } from './utils';

const ReceivedTransferForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields: receivedTransferCmlItemsFields } = useFieldArray({
    control,
    name: 'receivedTransferCmlItems',
  });

  const { fields: receivedTransferRawMaterialItemsFields } = useFieldArray({
    control,
    name: 'receivedTransferRawMaterialItems',
  });

  const { fields: receivedTransferPackagingItemsFields } = useFieldArray({
    control,
    name: 'receivedTransferPackagingItems',
  });

  const [
    receivedTransferCmlItems,
    receivedTransferRawMaterialItems,
    receivedTransferPackagingItems,
    receivedBy,
    fromOutlet,
    toOutlet,
  ] = useWatch({
    control,
    name: [
      'receivedTransferCmlItems',
      'receivedTransferRawMaterialItems',
      'receivedTransferPackagingItems',
      'receivedBy',
      'fromOutlet',
      'toOutlet',
    ],
  });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          {type === 'update' && (
            <TextField
              label={`Received By`}
              fullWidth
              value={receivedBy ?? ''}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
            // disabled={type === 'update'}
          />
          <OutletAutocomplete
            name={`fromOutlet`}
            schema={schema}
            control={control}
            label="From Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />
          <OutletAutocomplete
            name={`toOutlet`}
            schema={schema}
            control={control}
            label="To Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />

          <Controller
            name="transfer"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Transfer No.',
                }}
                disabled={type === 'update' || !fromOutlet || !toOutlet}
                queryOptions={(value) => {
                  return {
                    query: TransfersDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            controlNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                        fromOutlet: {
                          is: {
                            id: {
                              equals: fromOutlet?.id,
                            },
                          },
                        },
                        toOutlet: {
                          is: {
                            id: {
                              equals: toOutlet?.id,
                            },
                          },
                        },
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.controlNumber}
                value={field.value}
                onChange={(e, value: any) => {
                  if (value) {
                    setValue(
                      `receivedTransferRawMaterialItems`,
                      value?.transferRawMaterialItems.map(
                        (transferRawMaterialItem: any) => {
                          return {
                            transferRawMaterialItem,
                          };
                        }
                      )
                    );
                    setValue(
                      `receivedTransferPackagingItems`,
                      value?.transferPackagingItems.map(
                        (transferPackagingItem: any) => {
                          return {
                            transferPackagingItem,
                          };
                        }
                      )
                    );
                    setValue(
                      `receivedTransferCmlItems`,
                      value?.transferCmlItems.map((transferCmlItem: any) => {
                        return {
                          transferCmlItem,
                        };
                      })
                    );
                    field.onChange(value);
                  } else {
                    setValue(`receivedTransferRawMaterialItems`, []);
                    setValue(`receivedTransferPackagingItems`, []);
                    setValue(`receivedTransferCmlItems`, []);
                    field.onChange(value);
                  }
                }}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          CML Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {receivedTransferCmlItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`CML ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferCmlItems &&
                        receivedTransferCmlItems[index]?.transferCmlItem
                          ?.cmlProduct?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Quantity ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferCmlItems &&
                        receivedTransferCmlItems[index]?.transferCmlItem
                          ?.quantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferCmlItems &&
                        receivedTransferCmlItems[index]?.transferCmlItem
                          ?.cmlProduct?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />

                  <Controller
                    name={`receivedTransferCmlItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Received"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {receivedTransferRawMaterialItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Raw Material ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferRawMaterialItems &&
                        receivedTransferRawMaterialItems[index]
                          ?.transferRawMaterialItem?.rawMaterialProduct
                          ?.rawMaterial?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Quantity ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferRawMaterialItems &&
                        receivedTransferRawMaterialItems[index]
                          ?.transferRawMaterialItem?.quantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferRawMaterialItems &&
                        receivedTransferRawMaterialItems[index]
                          ?.transferRawMaterialItem?.rawMaterialProduct?.unit
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />

                  <Controller
                    name={`receivedTransferRawMaterialItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Received"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {receivedTransferPackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Packaging ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferPackagingItems &&
                        receivedTransferPackagingItems[index]
                          ?.transferPackagingItem?.packagingProduct?.packaging
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Quantity ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferPackagingItems &&
                        receivedTransferPackagingItems[index]
                          ?.transferPackagingItem?.quantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (receivedTransferPackagingItems &&
                        receivedTransferPackagingItems[index]
                          ?.transferPackagingItem?.packagingProduct?.unit
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />

                  <Controller
                    name={`receivedTransferPackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Received"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ReceivedTransferForm;
