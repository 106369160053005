import { z } from 'zod';

import { UserGroup } from '../../graphql/generated/types';

export const schema = z.object({
  username: z.string().trim().min(1),
  password: z.string().trim().min(1),
  name: z.string().trim().min(1),
  userGroups: z.array(
    z.nativeEnum(UserGroup)
  ).min(1),
  outlets: z.array(
    z.object({
      id: z.coerce.number()
    })
  )
});



export type FormData = z.infer<typeof schema>