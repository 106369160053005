import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { DeliveryShiftSchedule } from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import CmlProductAutocomplete from '../shared/CmlProductAutocomplete';
import OutletAutocomplete from '../shared/OutletAutocomplete';
import { FormData } from './utils';

const OutletFinishingForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'outletFinishingItems',
  });
  const [createdBy, controlNumber, outletFinishingItems] = useWatch({
    control,
    name: ['createdBy', 'controlNumber', 'outletFinishingItems'],
  });

  const handleAddOutletFinishingItem = () => {
    append({
      cmlProduct: null,
      quantity: undefined as unknown as number,
    });
  };

  const handleRemoveOutletFinishingItem = (index: number) => {
    remove(index);
  };

  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      {type === 'update' && (
        <Typography variant="subtitle2">{controlNumber}</Typography>
      )}
      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
          />
          <OutletAutocomplete
            name={`outlet`}
            schema={schema}
            control={control}
            label="Outlet"
            defaultValue={null}
            disabled={type === 'update'}
          />
          {type === 'update' && (
            <TextField
              label="Created By"
              fullWidth
              value={createdBy ?? ''}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
                inputProps: { tabIndex: -1 },
              }}
            />
          )}
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <CmlProductAutocomplete
                    name={`outletFinishingItems.${index}.cmlProduct`}
                    schema={schema}
                    control={control}
                    label="CML Product"
                    defaultValue={null}
                  />

                  <TextField
                    label={`Unit ${index + 1}`}
                    fullWidth
                    value={
                      (outletFinishingItems &&
                        outletFinishingItems[index]?.cmlProduct?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`outletFinishingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>

                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveOutletFinishingItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddOutletFinishingItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default OutletFinishingForm;
