import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregatePurchaseOrderDocument,
  QueryMode,
  PurchaseOrdersDocument,
  PurchaseOrderWhereInput,
  SortOrder,
  useDeleteManyPurchaseOrderMutation,
  usePurchaseOrdersQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const PurchaseOrdersPage = ({ title = 'Purchase Orders' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: PurchaseOrderWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          dateArrived: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              salesInvoiceNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              purchaseOrderRawMaterialItems: {
                some: {
                  rawMaterialProduct: {
                    is: {
                      rawMaterial: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              purchaseOrderPackagingItems: {
                some: {
                  packagingProduct: {
                    is: {
                      packaging: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        dateArrived: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { purchaseOrders } = {},
    loading,
    error,
  } = usePurchaseOrdersQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyPurchaseOrder] = useDeleteManyPurchaseOrderMutation({
    refetchQueries: [
      {
        query: AggregatePurchaseOrderDocument,
        variables: countVariables,
      },
      {
        query: PurchaseOrdersDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof purchaseOrders>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date Arrived',
      accessor: 'dateArrived',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Sales Invoice Number',
      accessor: 'salesInvoiceNumber',
    },

    {
      header: 'Packaging',
      accessor: 'purchaseOrderPackagingItems',
      Cell: (children: {}[], index: number) => {
        const purchaseOrderPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof purchaseOrders>
              >['purchaseOrderPackagingItems']
            >
          >
        > = [
          {
            header: 'Packaging',
            accessor: 'packagingProduct.packaging.name',
          },
          {
            header: 'Unit',
            accessor: 'packagingProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={purchaseOrderPackagingItemsColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material',
      accessor: 'purchaseOrderRawMaterialItems',
      Cell: (children: {}[], index: number) => {
        const purchaseOrderRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof purchaseOrders>
              >['purchaseOrderRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={purchaseOrderRawMaterialItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyPurchaseOrder({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={<DataTable data={purchaseOrders} columns={columns} />}
      pagination={
        <Pagination
          query={AggregatePurchaseOrderDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default PurchaseOrdersPage;
