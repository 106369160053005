import PageviewIcon from '@mui/icons-material/Pageview';
import { Checkbox, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateCheatSheetDocument,
  CheatSheetsDocument,
  CheatSheetWhereInput,
  SortOrder,
  useCheatSheetsQuery,
  useDeleteManyCheatSheetMutation,
  UserGroup,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import ListView from '../templates/ListView';

const CheatSheetsPage = ({ title = 'Cheat Sheets' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [userGroupQueryString] = useGetQueryString(['userGroup']);

  const where: CheatSheetWhereInput = Object.assign(
    {},
    userGroupQueryString
      ? {
          userGroup: { equals: userGroupQueryString as UserGroup },
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        userGroup: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { cheatSheets } = {},
    loading,
    error,
  } = useCheatSheetsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !userGroupQueryString,
  });
  const [deleteManyCheatSheet] = useDeleteManyCheatSheetMutation({
    refetchQueries: [
      { query: AggregateCheatSheetDocument, variables: countVariables },
      {
        query: CheatSheetsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof cheatSheets>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },

    {
      header: 'User Group',
      accessor: 'userGroup',
      Cell: (children) => getEnumKeyFromValue(UserGroup, children),
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyCheatSheet({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        >
          {selected.length === 1 && (
            <MenuItem
              onClick={() => navigate(`${location.pathname}/${selected[0]}`)}
            >
              <PageviewIcon />
              View
            </MenuItem>
          )}
        </ActionsMenu>
      }
      filters={
        <>
          <EnumSearchFilter
            label="User Group"
            field="userGroup"
            enumObj={UserGroup}
          />
        </>
      }
      dataTable={<DataTable data={cheatSheets} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateCheatSheetDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
          skip={!userGroupQueryString}
        />
      }
    />
  );
};

export default CheatSheetsPage;
