import { isValid, parseISO } from "date-fns";
import { dateTimeToString } from "./dateTimeFunctions";


export const cleanPayload = (payload: {}) => JSON.parse(JSON.stringify(payload, (key, value) => {
  if (key === '__typename') {
      delete value[key];
  } else if (isNaN(+value) && isValid(parseISO(value)) && key.toLowerCase().includes('datetime')) {
    return dateTimeToString(parseISO(value), "yyyy-MM-dd'T'HH:mm")
  } else if (isNaN(+value) && isValid(parseISO(value))) {
    return dateTimeToString(parseISO(value), "yyyy-MM-dd")
  } else if (value == null) {
    return
  } else {
    return value;
  }
}));