import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useCmlProductRecipeQuery,
  useUpdateCmlProductRecipeMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import CmlProductRecipeForm from './CmlProductRecipeForm';
import { FormData, schema } from './utils';

const UpdateCmlProductRecipePage = ({
  title = `Update CML Product Recipe`,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ cmlProduct, cmlProductIngredients, ...data }) => {
        const cmlProductIngredientsToDelete = getItemsForDelete(
          cmlProductRecipe?.cmlProductIngredients,
          cmlProductIngredients
        );
        const cmlProductIngredientsToUpsert = getItemsForUpsert(
          cmlProductIngredients
        );
        await updateCmlProductRecipe({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              cmlProduct: {
                connect: {
                  id: cmlProduct?.id,
                },
              },
              cmlProductIngredients: {
                delete: cmlProductIngredientsToDelete,
                upsert: cmlProductIngredientsToUpsert.map(
                  ({ id, unit, rawMaterial, ...cmlProductIngredient }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(cmlProductIngredient),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              unitId: unit?.id || 0,
                              rawMaterialId: rawMaterial?.id || 0,
                            },
                          },
                        },
                      },
                      create: {
                        ...prismaCreate(cmlProductIngredient),
                        rawMaterialProduct: {
                          connect: {
                            unitId_rawMaterialId: {
                              unitId: unit?.id || 0,
                              rawMaterialId: rawMaterial?.id || 0,
                            },
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { cmlProductRecipe } = {}, loading } = useCmlProductRecipeQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    }
  );

  const [updateCmlProductRecipe, { loading: mutationLoading }] =
    useUpdateCmlProductRecipeMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (cmlProductRecipe) {
      const { cmlProductIngredients, ...data } = cmlProductRecipe;
      methods.reset({
        ...cleanPayload({
          ...data,
          cmlProductIngredients: cmlProductIngredients.map(
            ({ rawMaterialProduct, ...cmlProductIngredient }) => {
              return {
                rawMaterial: rawMaterialProduct.rawMaterial,
                unit: rawMaterialProduct.unit,
                ...cmlProductIngredient,
              };
            }
          ),
        }),
      });
    }
  }, [cmlProductRecipe, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <CmlProductRecipeForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateCmlProductRecipePage;
