import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateRawMaterialDocument,
  RawMaterialsDocument,
  RawMaterialWhereInput,
  QueryMode,
  SortOrder,
  Status,
  useDeleteManyRawMaterialMutation,
  useRawMaterialsQuery,
  RawMaterialCategory,
  RawMaterialProduct,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { booleanToString } from '../../utils/booleanFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const RawMaterialsPage = ({ title = 'Raw Materials' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, statusQueryString, rawMaterialCategoryQueryString] =
    useGetQueryString(['search', 'status', 'rawMaterialCategory']);

  const where: RawMaterialWhereInput = Object.assign(
    {},
    statusQueryString
      ? {
          status: { equals: statusQueryString as Status },
        }
      : {},
    rawMaterialCategoryQueryString
      ? {
          rawMaterialCategory: {
            equals: rawMaterialCategoryQueryString as RawMaterialCategory,
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { rawMaterials } = {},
    loading,
    error,
  } = useRawMaterialsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyRawMaterial] = useDeleteManyRawMaterialMutation({
    refetchQueries: [
      { query: AggregateRawMaterialDocument, variables: countVariables },
      {
        query: RawMaterialsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof rawMaterials>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Raw Material Category',
      accessor: 'rawMaterialCategory',
      Cell: (children) => getEnumKeyFromValue(RawMaterialCategory, children),
    },
    {
      header: 'Status',
      accessor: 'status',
      Cell: (children) => getEnumKeyFromValue(Status, children),
    },
    {
      header: 'Raw Material Products',
      accessor: 'rawMaterialProducts',
      Cell: (children: {}[], index: number) => {
        const rawMaterialProductColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof rawMaterials>
              >['rawMaterialProducts']
            >
          >
        > = [
          {
            header: 'Unit',
            accessor: 'unit.name',
          },
          {
            header: 'Conversion to Base Unit',
            accessor: 'conversionToBaseUnit',
            Cell: (children) => roundFloat(children),
          },
          {
            header: 'Base Unit',
            accessor: 'baseUnit',
            Cell: (children) => booleanToString(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={rawMaterialProductColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material Ingredients',
      accessor: 'rawMaterialProducts',
      Cell: (children: RawMaterialProduct[], index: number) => {
        const rawMaterialProductIngredients = children?.flatMap(
          ({ rawMaterialProductIngredients }) => rawMaterialProductIngredients
        );
        const rawMaterialProductIngredientsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                ObjectFromArray<
                  NonNullable<typeof rawMaterials>
                >['rawMaterialProducts']
              >['rawMaterialProductIngredients']
            >
          >
        > = [
          {
            header: 'Raw Material Product',
            accessor: 'rawMaterialProduct',
            Cell: (children: RawMaterialProduct) =>
              `${children.rawMaterial.name} - ${children.unit.name}`,
          },
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={rawMaterialProductIngredients}
            index={index}
            columns={rawMaterialProductIngredientsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyRawMaterial({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <EnumSearchFilter
            label="Raw Material Category"
            field="rawMaterialCategory"
            enumObj={RawMaterialCategory}
          />
          <EnumSearchFilter label="Status" field="status" enumObj={Status} />
          <Search />
        </>
      }
      dataTable={<DataTable data={rawMaterials} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateRawMaterialDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default RawMaterialsPage;
