export enum SalesCategory {
  "Bars" = "BARS",
  "Buns" = "BUNS",
  "Choco Bang Box" = "CHOCO_BANG_BOX",
  "Choco Bang Piece" = "CHOCO_BANG_PIECE",
  "Classic D1" = "CLASSIC_D1",
  "Classic Munchkin CM1" = "CLASSIC_MUNCHKIN_CM1",
  "Cookies" = "COOKIES",
  "Dunkin Yan Box" = "DUNKIN_YAN_BOX",
  "Dunkin Yan Piece" = "DUNKIN_YAN_PIECE",
  "Muffin" = "MUFFIN",
  "Premium Munchkin PM1" = "PREMIUM_MUNCHKIN_PM1",
  "Premium PD1" = "PREMIUM_PD1",
}