import { zodResolver } from '@hookform/resolvers/zod';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useCheatSheetQuery,
  useUpdateCheatSheetMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import CheatSheetForm from './CheatSheetForm';
import { FormData, schema } from './utils';
import Loading from '../common/Loading';

const UpdateCheatSheetPage = ({
  title = `Update Cheat Sheet`,
  readOnly = false,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async (data) =>
        await updateCheatSheet({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate({
                ...data,
                instructions: draftToHtml(
                  convertToRaw(data.instructions.getCurrentContent())
                ),
              }),
            },
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { cheatSheet } = {}, loading } = useCheatSheetQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateCheatSheet, { loading: mutationLoading }] =
    useUpdateCheatSheetMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (cheatSheet) {
      const contentBlock = htmlToDraft(cheatSheet?.instructions);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      methods.reset({
        ...cleanPayload({
          ...cheatSheet,
        }),
        instructions: EditorState.createWithContent(contentState),
      });
    }
  }, [cheatSheet, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <CheatSheetForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
          readOnly={readOnly}
        />
      </FormProvider>
    </>
  );
};

export default UpdateCheatSheetPage;
