import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  DeliveryShiftSchedule,
  RawMaterialCategory,
  useRawMaterialReleaseItemFromBreakdownReferencesLazyQuery,
  useUnitsQuery,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import Loading from '../common/Loading';
import Select from '../common/Select';
import PackagingAutocomplete from '../shared/PackagingAutocomplete';
import { FormData } from './utils';

const RawMaterialReleaseForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const [
    getRawMaterialReleaseItemFromBreakdownReferences,
    { loading: getRawMaterialReleaseItemFromBreakdownReferencesLoading },
  ] = useRawMaterialReleaseItemFromBreakdownReferencesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ rawMaterialReleaseItemFromBreakdownReferences }) => {
      setValue(
        `rawMaterialReleaseItemsFromBreakdowns`,
        rawMaterialReleaseItemFromBreakdownReferences.map((item) => {
          return {
            ...item,
            referenceUnit: item.referenceUnit.name,
            referenceQuantity: item.referenceQuantity,
            unit: item.referenceUnit,
            quantity: undefined as unknown as number,
          };
        })
      );
    },
  });
  const { fields: rawMaterialReleaseForBreakdownsFields } = useFieldArray({
    control,
    name: 'rawMaterialReleaseForBreakdowns',
  });

  const { fields: rawMaterialReleaseItemsFields } = useFieldArray({
    control,
    name: 'rawMaterialReleaseItems',
  });

  const { fields: rawMaterialReleaseItemsFromBreakdownsFields } = useFieldArray(
    {
      control,
      name: 'rawMaterialReleaseItemsFromBreakdowns',
    }
  );

  const {
    fields: rawMaterialReleasePackagingItemsFields,
    append: appendRawMaterialReleasePackagingItem,
    remove: removeRawMaterialReleasePackagingItem,
  } = useFieldArray({
    control,
    name: 'rawMaterialReleasePackagingItems',
  });

  const handleAddRawMaterialReleasePackagingItem = () => {
    appendRawMaterialReleasePackagingItem({
      packaging: null,
      unit: null,
      quantity: undefined as unknown as number,
    });
  };
  const handleRemoveRawMaterialReleasePackagingItem = (index: number) => {
    removeRawMaterialReleasePackagingItem(index);
  };

  const [
    rawMaterialReleaseForBreakdowns,
    rawMaterialReleaseItems,
    rawMaterialReleaseItemsFromBreakdowns,
    rawMaterialReleasePackagingItems,
  ] = useWatch({
    control,
    name: [
      'rawMaterialReleaseForBreakdowns',
      'rawMaterialReleaseItems',
      'rawMaterialReleaseItemsFromBreakdowns',
      'rawMaterialReleasePackagingItems',
    ],
  });
  const { data: { units } = {}, loading } = useUnitsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <FormContainer maxWidth="xl">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="deliveryShiftSchedule"
            label="Delivery Shift Schedule"
            schema={schema}
            enumObj={DeliveryShiftSchedule}
            control={control}
            defaultValue={'' as unknown as DeliveryShiftSchedule}
            disabled={type === 'update'}
          />
          <Controller
            name="destination"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="Destination"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Release for Breakdown
        </Typography>
        <Stack direction="column" spacing={1}>
          {rawMaterialReleaseForBreakdownsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Category ${index + 1}`}
                    fullWidth
                    value={
                      getEnumKeyFromValue(
                        RawMaterialCategory,
                        rawMaterialReleaseForBreakdowns &&
                          rawMaterialReleaseForBreakdowns[index]?.rawMaterial
                            ?.rawMaterialCategory
                      ) ?? ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Raw Material ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseForBreakdowns &&
                        rawMaterialReleaseForBreakdowns[index]?.rawMaterial
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Reference Qty ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseForBreakdowns &&
                        rawMaterialReleaseForBreakdowns[index]
                          ?.referenceQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Reference Unit ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseForBreakdowns &&
                        rawMaterialReleaseForBreakdowns[index]?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`rawMaterialReleaseForBreakdowns.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`rawMaterialReleaseForBreakdowns.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={
                      rawMaterialReleaseForBreakdowns?.[
                        index
                      ]?.rawMaterial?.rawMaterialProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        {rawMaterialReleaseForBreakdowns?.length > 0 && (
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={getRawMaterialReleaseItemFromBreakdownReferencesLoading}
              onClick={() => {
                getRawMaterialReleaseItemFromBreakdownReferences({
                  variables: {
                    data: rawMaterialReleaseForBreakdowns.map(
                      (rawMaterialReleaseForBreakdown) => {
                        return {
                          rawMaterialId: +(
                            rawMaterialReleaseForBreakdown?.rawMaterial?.id || 0
                          ),
                          unitId: +(
                            rawMaterialReleaseForBreakdown?.unit?.id || 0
                          ),
                          quantity: +rawMaterialReleaseForBreakdown.quantity,
                        };
                      }
                    ),
                  },
                });
              }}
            >
              Get Raw Material Release Items From Breakdown
            </Button>
          </Stack>
        )}
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Release Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {rawMaterialReleaseItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Category ${index + 1}`}
                    fullWidth
                    value={
                      getEnumKeyFromValue(
                        RawMaterialCategory,
                        rawMaterialReleaseItems &&
                          rawMaterialReleaseItems[index]?.rawMaterial
                            ?.rawMaterialCategory
                      ) ?? ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Raw Material ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseItems &&
                        rawMaterialReleaseItems[index]?.rawMaterial?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Reference Qty ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseItems &&
                        rawMaterialReleaseItems[index]?.referenceQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Reference Unit ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseItems &&
                        rawMaterialReleaseItems[index]?.unit?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`rawMaterialReleaseItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`rawMaterialReleaseItems.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={
                      rawMaterialReleaseItems?.[
                        index
                      ]?.rawMaterial?.rawMaterialProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Raw Material Release Items From Breakdown
        </Typography>
        <Stack direction="column" spacing={1}>
          {rawMaterialReleaseItemsFromBreakdownsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Category ${index + 1}`}
                    fullWidth
                    value={
                      getEnumKeyFromValue(
                        RawMaterialCategory,
                        rawMaterialReleaseItemsFromBreakdowns &&
                          rawMaterialReleaseItemsFromBreakdowns[index]
                            ?.rawMaterial?.rawMaterialCategory
                      ) ?? ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Raw Material ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseItemsFromBreakdowns &&
                        rawMaterialReleaseItemsFromBreakdowns[index]
                          ?.rawMaterial?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Reference Qty ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseItemsFromBreakdowns &&
                        rawMaterialReleaseItemsFromBreakdowns[index]
                          ?.referenceQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <TextField
                    label={`Reference Unit ${index + 1}`}
                    fullWidth
                    value={
                      (rawMaterialReleaseItemsFromBreakdowns &&
                        rawMaterialReleaseItemsFromBreakdowns[index]?.unit
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      inputProps: { tabIndex: -1 },
                    }}
                  />
                  <Controller
                    name={`rawMaterialReleaseItemsFromBreakdowns.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Select
                    name={`rawMaterialReleaseItemsFromBreakdowns.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={
                      rawMaterialReleaseItemsFromBreakdowns?.[
                        index
                      ]?.rawMaterial?.rawMaterialProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {rawMaterialReleasePackagingItemsFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <PackagingAutocomplete
                    name={`rawMaterialReleasePackagingItems.${index}.packaging`}
                    schema={schema}
                    control={control}
                    label={`Packaging ${index + 1}`}
                    defaultValue={null}
                  />
                  <Select
                    name={`rawMaterialReleasePackagingItems.${index}.unit`}
                    label={`Unit ${index + 1}`}
                    schema={schema}
                    control={control}
                    arr={
                      rawMaterialReleasePackagingItems?.[
                        index
                      ]?.packaging?.packagingProducts?.map(
                        ({ unit }) => unit
                      ) || []
                    }
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                  <Controller
                    name={`rawMaterialReleasePackagingItems.${index}.quantity`}
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Quantity"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleRemoveRawMaterialReleasePackagingItem(index)
                  }
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddRawMaterialReleasePackagingItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default RawMaterialReleaseForm;
