import { startOfMonth } from 'date-fns';
import {
  ProjectionToRawMaterialWhereInput,
  useProjectionToRawMaterialsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import DataTable from '../common/DataTable';
import DateFilter from '../common/DateFilter';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { roundFloat } from '../../utils/numberFunctions';

const ProjectionToRawMaterialsPage = ({
  title = 'Projection To Raw Materials',
}) => {
  const [dateQueryString, searchQueryString] = useGetQueryString([
    'date',
    'search',
  ]);

  const where: ProjectionToRawMaterialWhereInput = Object.assign(
    {},

    typeof dateQueryString === 'string'
      ? {
          date: new Date(dateQueryString),
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          searchTerm: searchQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const {
    data: { projectionToRawMaterials } = {},
    loading,
    error,
  } = useProjectionToRawMaterialsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !dateQueryString,
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof projectionToRawMaterials>>>
  > = [
    {
      header: 'Category',
      accessor: 'category',
    },
    {
      header: 'Raw Material',
      accessor: 'rawMaterial',
    },
    {
      header: 'Unit',
      accessor: 'unit',
    },
    {
      header: 'First Delivery',
      accessor: 'firstDelivery',
      Cell: (children) => roundFloat(children),
    },
    {
      header: 'Second Delivery',
      accessor: 'secondDelivery',
      Cell: (children) => roundFloat(children),
    },
    {
      header: 'Third Delivery',
      accessor: 'thirdDelivery',
      Cell: (children) => roundFloat(children),
    },
  ];

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={
          <>
            <DateFilter dateField="date" label="Date" />
            <Search />
          </>
        }
        dataTable={
          <DataTable data={projectionToRawMaterials} columns={columns} />
        }
      />
    </>
  );
};

export default ProjectionToRawMaterialsPage;
