import PrintIcon from '@mui/icons-material/Print';
import { Checkbox, MenuItem } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateBetweenEntityTransferDocument,
  QueryMode,
  BetweenEntityTransfersDocument,
  BetweenEntityTransferWhereInput,
  SortOrder,
  useDeleteManyBetweenEntityTransferMutation,
  useBetweenEntityTransfersQuery,
  TransferDirection,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const BetweenEntityTransfersPage = ({ title = 'Between Entity Transfers' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: BetweenEntityTransferWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          date: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              controlNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              origin: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              destination: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              createdBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              receivedBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              betweenEntityTransferRawMaterialItems: {
                some: {
                  rawMaterialProduct: {
                    is: {
                      rawMaterial: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              betweenEntityTransferPackagingItems: {
                some: {
                  packagingProduct: {
                    is: {
                      packaging: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { betweenEntityTransfers } = {},
    loading,
    error,
  } = useBetweenEntityTransfersQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyBetweenEntityTransfer] =
    useDeleteManyBetweenEntityTransferMutation({
      refetchQueries: [
        {
          query: AggregateBetweenEntityTransferDocument,
          variables: countVariables,
        },
        {
          query: BetweenEntityTransfersDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Delete' + title.toLowerCase() + ' error! ' + error,
          severity: 'error',
        }),
    });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof betweenEntityTransfers>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Transfer Direction',
      accessor: 'transferDirection',
      Cell: (children) => getEnumKeyFromValue(TransferDirection, children),
    },
    {
      header: 'Control Number',
      accessor: 'controlNumber',
    },
    {
      header: 'Origin',
      accessor: 'origin',
    },
    {
      header: 'Destination',
      accessor: 'destination',
    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Received By',
      accessor: 'receivedBy',
    },
    {
      header: 'Packaging',
      accessor: 'betweenEntityTransferPackagingItems',
      Cell: (children: {}[], index: number) => {
        const betweenEntityTransferPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof betweenEntityTransfers>
              >['betweenEntityTransferPackagingItems']
            >
          >
        > = [
          {
            header: 'Packaging',
            accessor: 'packagingProduct.packaging.name',
          },
          {
            header: 'Unit',
            accessor: 'packagingProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={betweenEntityTransferPackagingItemsColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material',
      accessor: 'betweenEntityTransferRawMaterialItems',
      Cell: (children: {}[], index: number) => {
        const betweenEntityTransferRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof betweenEntityTransfers>
              >['betweenEntityTransferRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={betweenEntityTransferRawMaterialItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyBetweenEntityTransfer({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        >
          {' '}
          {selected.length === 1 && (
            <MenuItem
              onClick={() =>
                navigate(`${location.pathname}/${selected[0]}/print`)
              }
            >
              <PrintIcon />
              Print
            </MenuItem>
          )}
        </ActionsMenu>
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={<DataTable data={betweenEntityTransfers} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateBetweenEntityTransferDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default BetweenEntityTransfersPage;
