import { SxProps, Theme } from '@mui/material';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { z } from 'zod';
import { PackagingsDropdownDocument, Status } from '../../graphql/generated/types';

import { RequireKeys } from '../../types/UtilityTypes';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';

interface Props {
  label: string;
  schema: z.ZodTypeAny;
  sx?: SxProps<Theme> | undefined;
  readOnly?: boolean;
  onChange?: (value: any) => void;
}

const PackagingAutocomplete = <T extends FieldValues>({
  name,
  label,
  schema,
  control,
  defaultValue,
  sx,
  onChange,
  readOnly = false,
}: Props & RequireKeys<UseControllerProps<T>, 'defaultValue' | 'control'>) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <SearchAsYouTypeAutocomplete
            sx={{ ...sx }}
            readOnly={readOnly}
            textFieldProps={{
              ...textFieldHelperProps(field, fieldState, schema),
              label,
            }}
            queryOptions={(value) => {
              return {
                query: PackagingsDropdownDocument,
                variables: {
                  where: {
                    OR: [
                      {
                        name: {
                          contains: value,
                          mode: 'insensitive',
                        },
                      },
                    ],
                  },
                  status: {
                    equals: Status.Active,
                  },
                },
              };
            }}
            getOptionLabel={(option: any) => option?.name}
            value={field.value}
            onChange={(e, value) => {
              onChange && onChange(value);
              field.onChange(value);
            }}
          />
        )}
      />
    </>
  );
};

export default PackagingAutocomplete;
