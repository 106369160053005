import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateRawMaterialProductRecipeMutation } from '../../graphql/generated/types';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import RawMaterialForm from './RawMaterialProductRecipeForm';
import { FormData, schema } from './utils';

const CreateRawMaterialProductRecipePage = ({
  title = `Create Raw Material Product Recipe`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ rawMaterialProduct, rawMaterialProductIngredients, ...data }) =>
        await createRawMaterialProductRecipe({
          variables: {
            data: {
              rawMaterialProduct: {
                connect: {
                  id: rawMaterialProduct?.id,
                },
              },
              rawMaterialProductIngredients: {
                create: rawMaterialProductIngredients.map(
                  ({ rawMaterial, unit, ...rawMaterialProductIngredient }) => {
                    return {
                      ...rawMaterialProductIngredient,
                      rawMaterialProduct: {
                        connect: {
                          unitId_rawMaterialId: {
                            rawMaterialId: rawMaterial?.id || 0,
                            unitId: unit?.id || 0,
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const [createRawMaterialProductRecipe, { loading: mutationLoading }] =
    useCreateRawMaterialProductRecipeMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <RawMaterialForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateRawMaterialProductRecipePage;
