import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  PackagingCategory,
  Status,
  useUnitsQuery,
} from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import Select from '../common/Select';
import { FormData } from './utils';

const PackagingForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { control } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packagingProducts',
  });

  const handleAddPackagingProduct = () => {
    append({
      id: 0,
      unit: null,
      conversionToBaseUnit: 0,
      baseUnit: false,
    });
  };

  const handleRemovePackagingProduct = (index: number) => {
    remove(index);
  };
  const [packagingProducts] = useWatch({
    control,
    name: ['packagingProducts'],
  });

  const { data: { units } = {} } = useUnitsQuery({
    fetchPolicy: 'network-only',
  });
  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {title}
      </Typography>

      <Box component="form" sx={{ mt: 2 }} onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                sx={{ gridColumn: 'span 3' }}
                label="Name"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="packagingCategory"
            label="Packaging Material Category"
            schema={schema}
            enumObj={PackagingCategory}
            control={control}
            defaultValue={'' as unknown as PackagingCategory}
            sx={{ gridColumn: 'span 2' }}
          />
          <EnumSelect
            name="status"
            label="Status"
            schema={schema}
            enumObj={Status}
            control={control}
            defaultValue={'' as unknown as Status}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Packaging Products
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <Select
                    name={`packagingProducts.${index}.unit`}
                    label="Unit"
                    schema={schema}
                    control={control}
                    arr={units || []}
                    defaultValue={null}
                    renderValueAs={(obj) => `${obj.name}`}
                  />
                  <Controller
                    name={`packagingProducts.${index}.conversionToBaseUnit`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Conversion to Base Unit"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        type="number"
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                      />
                    )}
                  />
                  <Controller
                    name={`packagingProducts.${index}.baseUnit`}
                    control={control}
                    defaultValue={false}
                    render={({ field, fieldState }) => (
                      <FormControlLabel
                        value="start"
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            size="small"
                            onChange={(e, value) => {
                              if (
                                packagingProducts.some(
                                  ({ baseUnit }) => baseUnit === true
                                ) &&
                                value === true
                              ) {
                                handleSnackbarOpen({
                                  message: 'Only one base unit allowed',
                                  severity: 'error',
                                });
                              } else {
                                field.onChange(value);
                              }
                            }}
                          />
                        }
                        label="Base Unit?"
                        labelPlacement="start"
                      />
                    )}
                  />
                </Box>

                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemovePackagingProduct(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddPackagingProduct}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={mutationLoading}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default PackagingForm;
