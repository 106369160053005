import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateOutletDocument,
  NumberOfShifts,
  OutletsDocument,
  OutletWhereInput,
  QueryMode,
  SortOrder,
  Status,
  useDeleteManyOutletMutation,
  useOutletsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const OutletsPage = ({ title = 'Outlets' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, statusQueryString] = useGetQueryString([
    'search',
    'status',
  ]);

  const where: OutletWhereInput = Object.assign(
    {},
    statusQueryString
      ? {
          status: { equals: statusQueryString as Status },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { outlets } = {},
    loading,
    error,
  } = useOutletsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [deleteManyOutlet] = useDeleteManyOutletMutation({
    refetchQueries: [
      { query: AggregateOutletDocument, variables: countVariables },
      {
        query: OutletsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<Paths<ObjectFromArray<NonNullable<typeof outlets>>>> =
    [
      {
        header: selected.length > 0 ? `${selected.length} selected` : 'Select',
        accessor: 'id',
        Cell: (children: ReactNode) =>
          children && (
            <Checkbox
              color="primary"
              checked={isSelected(+children)}
              onClick={() => handleSelect(+children)}
            />
          ),
      },
      {
        header: 'Name',
        accessor: 'name',
      },
      {
        header: 'Status',
        accessor: 'status',
        Cell: (children) => getEnumKeyFromValue(Status, children),
      },
      {
        header: 'NumberOfShifts',
        accessor: 'numberOfShifts',
        Cell: (children) => getEnumKeyFromValue(NumberOfShifts, children),
      },
    ];

  const handleDelete = (ids: number[]) => {
    deleteManyOutlet({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <EnumSearchFilter label="Status" field="status" enumObj={Status} />
          <Search />
        </>
      }
      dataTable={<DataTable data={outlets} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateOutletDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default OutletsPage;
