import { Button } from '@mui/material';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  CurrentUserDocument,
  useSignoutMutation,
} from '../../graphql/generated/types';

const SignoutButton = ({ callback = () => {}, ...props }) => {
  const { handleSnackbarOpen } = useSnackbarContext();

  const [signout] = useSignoutMutation({
    refetchQueries: [{ query: CurrentUserDocument }],
    onError: (error) => {
      handleSnackbarOpen({
        message: 'Sign out error! ' + error,
        severity: 'error',
      });
    },
    onCompleted: () => {
      callback();
    },
  });

  const handleClick = async () => {
    await signout();
  };
  return (
    <Button {...props} sx={{ mt: 1 }} fullWidth onClick={handleClick}>
      Log Out
    </Button>
  );
};

export default SignoutButton;
