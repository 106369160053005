import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useReceivedTransferQuery,
  useUpdateReceivedTransferMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import ReceivedTransferForm from './ReceivedTransferForm';
import { FormData, schema } from './utils';

const UpdateReceivedTransferPage = ({
  title = `Update SDS Delivery Receipt`,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        receivedTransferCmlItems,
        receivedTransferPackagingItems,
        receivedTransferRawMaterialItems,
        transfer,
        toOutlet,
        fromOutlet,
        ...data
      }) => {
        await updateReceivedTransfer({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              transfer: {
                connect: {
                  id: transfer?.id,
                },
              },
              receivedTransferCmlItems: {
                update: receivedTransferCmlItems.map(({ id, quantity }) => {
                  return {
                    where: {
                      id,
                    },
                    data: {
                      ...prismaUpdate({ quantity }),
                    },
                  };
                }),
              },
              receivedTransferRawMaterialItems: {
                update: receivedTransferRawMaterialItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
              receivedTransferPackagingItems: {
                update: receivedTransferPackagingItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { receivedTransfer } = {}, loading } = useReceivedTransferQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    }
  );

  const [updateReceivedTransfer, { loading: mutationLoading }] =
    useUpdateReceivedTransferMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (receivedTransfer) {
      const {
        receivedTransferRawMaterialItems,
        receivedTransferPackagingItems,
        receivedTransferCmlItems,
        ...data
      } = receivedTransfer;
      methods.reset({
        ...cleanPayload({
          ...data,
          toOutlet: data?.transfer?.toOutlet,
          fromOutlet: data?.transfer?.fromOutlet,
          receivedTransferCmlItems: receivedTransferCmlItems.map(
            ({ transferCmlItem, ...data }) => {
              return {
                transferCmlItem,
                ...data,
              };
            }
          ),
          receivedTransferRawMaterialItems:
            receivedTransferRawMaterialItems.map(
              ({ transferRawMaterialItem, ...data }) => {
                return {
                  transferRawMaterialItem,
                  ...data,
                };
              }
            ),
          receivedTransferPackagingItems: receivedTransferPackagingItems.map(
            ({ transferPackagingItem, ...data }) => {
              return {
                transferPackagingItem,
                ...data,
              };
            }
          ),
        }),
      });
    }
  }, [receivedTransfer, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ReceivedTransferForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateReceivedTransferPage;
