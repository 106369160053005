import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateCmlThrowAwayDocument,
  CmlThrowAwayLocation,
  CmlThrowAwaysDocument,
  CmlThrowAwayWhereInput,
  DeliveryShiftSchedule,
  QueryMode,
  SortOrder,
  useCmlThrowAwaysQuery,
  useDeleteManyCmlThrowAwayMutation,
  UserGroup,
  useUpdateCmlThrowAwayMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { roundFloat } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { useAuthContext } from '../../contexts/authContext';

const CmlThrowAwaysPage = ({ title = 'CML Throw Aways' }) => {
  const { currentUser } = useAuthContext();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);
  const canApprove =
    currentUser?.userGroups?.includes(UserGroup.Superuser) ||
    currentUser?.userGroups?.includes(UserGroup.Accounting) ||
    false;
  const where: CmlThrowAwayWhereInput = Object.assign(
    {},
    typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string'
      ? {
          date: {
            gte: startOfDay(new Date(startDateQueryString)),
            lte: endOfDay(new Date(endDateQueryString)),
          },
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              cmlThrowAwayCmlItems: {
                some: {
                  cmlProduct: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              cmlThrowAwayRawMaterialItems: {
                some: {
                  rawMaterialProduct: {
                    is: {
                      rawMaterial: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              cmlThrowAwayPackagingItems: {
                some: {
                  packagingProduct: {
                    is: {
                      packaging: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              controlNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              createdBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
            {
              checkedBy: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { cmlThrowAways } = {},
    loading,
    error,
  } = useCmlThrowAwaysQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [updateCmlThrowAway, { loading: mutationLoading }] =
    useUpdateCmlThrowAwayMutation({
      refetchQueries: [
        {
          query: AggregateCmlThrowAwayDocument,
          variables: countVariables,
        },
        {
          query: CmlThrowAwaysDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        setSelected([]);
        handleSnackbarOpen({
          message: 'Success!',
          severity: 'success',
        });
      },
    });
  const [deleteManyCmlThrowAway] = useDeleteManyCmlThrowAwayMutation({
    refetchQueries: [
      {
        query: AggregateCmlThrowAwayDocument,
        variables: countVariables,
      },
      {
        query: CmlThrowAwaysDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof cmlThrowAways>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Approved?',
      accessor: 'id',
      Cell: (children) => {
        const checkedBy = cmlThrowAways?.find(
          ({ id }) => id === +children
        )?.checkedBy;
        const isApproved = checkedBy != null;
        return (
          <Checkbox
            color="primary"
            checked={isApproved}
            onClick={() => {
              if (!isApproved && currentUser) {
                updateCmlThrowAway({
                  variables: {
                    where: {
                      id: +children,
                    },
                    data: {
                      checkedBy: {
                        set: currentUser.name,
                      },
                    },
                  },
                });
              }
            }}
            disabled={isApproved || mutationLoading || !canApprove}
          />
        );
      },
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children) => dateTimeToString(children),
    },
    {
      header: 'Delivery Shift Schedule',
      accessor: 'deliveryShiftSchedule',
      Cell: (children) => getEnumKeyFromValue(DeliveryShiftSchedule, children),
    },
    {
      header: 'Control Number',
      accessor: 'controlNumber',
    },
    {
      header: 'Location',
      accessor: 'cmlThrowAwayLocation',
      Cell: (children) => getEnumKeyFromValue(CmlThrowAwayLocation, children),
    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Checked By',
      accessor: 'checkedBy',
    },
    {
      header: 'CML',
      accessor: 'cmlThrowAwayCmlItems',
      Cell: (children: {}[], index: number) => {
        const cmlThrowAwayCmlItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof cmlThrowAways>
              >['cmlThrowAwayCmlItems']
            >
          >
        > = [
          {
            header: 'CML Product',
            accessor: 'cmlProduct.name',
          },

          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={cmlThrowAwayCmlItemsColumns}
          />
        );
      },
    },
    {
      header: 'Raw Material',
      accessor: 'cmlThrowAwayRawMaterialItems',
      Cell: (children: {}[], index: number) => {
        const cmlThrowAwayRawMaterialItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof cmlThrowAways>
              >['cmlThrowAwayRawMaterialItems']
            >
          >
        > = [
          {
            header: 'Raw Material',
            accessor: 'rawMaterialProduct.rawMaterial.name',
          },
          {
            header: 'Unit',
            accessor: 'rawMaterialProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            Cell: (children) => roundFloat(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={cmlThrowAwayRawMaterialItemsColumns}
          />
        );
      },
    },
    {
      header: 'Packaging',
      accessor: 'cmlThrowAwayPackagingItems',
      Cell: (children: {}[], index: number) => {
        const cmlThrowAwayPackagingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof cmlThrowAways>
              >['cmlThrowAwayPackagingItems']
            >
          >
        > = [
          {
            header: 'Packaging',
            accessor: 'packagingProduct.packaging.name',
          },
          {
            header: 'Unit',
            accessor: 'packagingProduct.unit.name',
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={cmlThrowAwayPackagingItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete = (ids: number[]) => {
    deleteManyCmlThrowAway({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <ListView
      loading={loading}
      error={error}
      title={title}
      actionsMenu={
        <ActionsMenu
          handleDelete={handleDelete}
          selected={selected}
          setSelected={setSelected}
        />
      }
      filters={
        <>
          <DateRangeFilter />
          <Search />
        </>
      }
      dataTable={<DataTable data={cmlThrowAways} columns={columns} />}
      pagination={
        <Pagination
          query={AggregateCmlThrowAwayDocument}
          perPage={perPage}
          page={Number(page)}
          variables={countVariables}
        />
      }
    />
  );
};

export default CmlThrowAwaysPage;
