import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreatePurchaseOrderMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import PurchaseOrderForm from './PurchaseOrderForm';
import { FormData, schema } from './utils';

const CreatePurchaseOrderPage = ({ title = `Create Purchase Order` }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();

    methods.handleSubmit(
      async ({
        purchaseOrderRawMaterialItems,
        purchaseOrderPackagingItems,
        ...data
      }) =>
        await createPurchaseOrder({
          variables: {
            data: {
              ...prismaCreate(data),

              purchaseOrderRawMaterialItems: {
                create: purchaseOrderRawMaterialItems.map(
                  ({
                    rawMaterial,
                    unit,
                    quantity,

                    ...purchaseOrderRawMaterialItem
                  }) => {
                    return {
                      ...prismaCreate({
                        ...purchaseOrderRawMaterialItem,
                        quantity,
                      }),
                      rawMaterialProduct: {
                        connect: {
                          unitId_rawMaterialId: {
                            rawMaterialId: rawMaterial?.id || 0,
                            unitId: unit?.id || 0,
                          },
                        },
                      },
                    };
                  }
                ),
              },
              purchaseOrderPackagingItems: {
                create: purchaseOrderPackagingItems.map(
                  ({
                    packaging,
                    unit,
                    quantity,

                    ...purchaseOrderPackagingItem
                  }) => {
                    return {
                      ...prismaCreate({
                        ...purchaseOrderPackagingItem,
                        quantity,
                      }),
                      packagingProduct: {
                        connect: {
                          unitId_packagingId: {
                            packagingId: packaging?.id || 0,
                            unitId: unit?.id || 0,
                          },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const [createPurchaseOrder, { loading: mutationLoading }] =
    useCreatePurchaseOrderMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <PurchaseOrderForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreatePurchaseOrderPage;
