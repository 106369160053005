import { z } from 'zod';


export const schema = z.object({
  dateTime: z.coerce.date(),
  receivedBy: z.string().nullish(),

  outlet: z.object({ 
    id: z.coerce.number(),
  }).transform((val) => !val ? null : val).nullish(),
  deliveryReceipt: z.object({ 
    id: z.coerce.number(),
    // deliveryReceiptItems: z.array(z.object({
    //   id: z.coerce.number().optional() 
    // })),
  }).transform((val) => !val ? null : val),
  receivedDeliveryReceiptItems: z.array(z.object({
    id: z.coerce.number().optional(),
    deliveryReceiptItem: z.object({
      id: z.coerce.number(),
      quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
      cmlProduct: z.object({ 
        id: z.coerce.number(),
        name: z.string().trim().min(1),
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
        donutMixAndCut: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }).transform((val) => !val ? null : val),
    }),
    receivedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    rejectedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),

})

export type FormData = z.infer<typeof schema>




