import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import Page from './components/page/Page';
import apolloClient from './libs/apolloClient';

import theme from './styles/theme';

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <HelmetProvider>
            <Router>
              <CssBaseline />
              <Page />
            </Router>
          </HelmetProvider>
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
