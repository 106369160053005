import { z } from 'zod';
import { UserGroup } from '../../graphql/generated/types';
import { EditorState } from 'draft-js';

export const schema = z.object({
  instructions: z.instanceof(EditorState),
  userGroup: z.nativeEnum(UserGroup),
}).required();



export type FormData = z.infer<typeof schema>