import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useBetweenEntityTransferQuery,
  useUpdateBetweenEntityTransferMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import BetweenEntityTransferForm from './BetweenEntityTransferForm';
import { FormData, schema } from './utils';

const UpdateBetweenEntityTransferPage = ({
  title = `Update Between Entity Transfer`,
  readOnly = false,
  type = 'update',
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        betweenEntityTransferRawMaterialItems,
        betweenEntityTransferPackagingItems,

        ...data
      }) => {
        await updateBetweenEntityTransfer({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),

              betweenEntityTransferRawMaterialItems: {
                update: betweenEntityTransferRawMaterialItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
              betweenEntityTransferPackagingItems: {
                update: betweenEntityTransferPackagingItems.map(
                  ({ id, quantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ quantity }),
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { betweenEntityTransfer } = {}, loading } =
    useBetweenEntityTransferQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    });

  const [updateBetweenEntityTransfer, { loading: mutationLoading }] =
    useUpdateBetweenEntityTransferMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (betweenEntityTransfer) {
      const {
        betweenEntityTransferRawMaterialItems,
        betweenEntityTransferPackagingItems,

        ...data
      } = betweenEntityTransfer;
      methods.reset({
        ...cleanPayload({
          ...data,
          betweenEntityTransferRawMaterialItems:
            betweenEntityTransferRawMaterialItems.map(
              ({ rawMaterialProduct, ...data }) => {
                return {
                  rawMaterial: rawMaterialProduct?.rawMaterial,
                  unit: rawMaterialProduct?.unit,
                  ...data,
                };
              }
            ),
          betweenEntityTransferPackagingItems:
            betweenEntityTransferPackagingItems.map(
              ({ packagingProduct, ...data }) => {
                return {
                  packaging: packagingProduct?.packaging,
                  unit: packagingProduct?.unit,
                  ...data,
                };
              }
            ),
        }),
      });
    }
  }, [betweenEntityTransfer, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <BetweenEntityTransferForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
          readOnly={readOnly}
        />
      </FormProvider>
    </>
  );
};

export default UpdateBetweenEntityTransferPage;
