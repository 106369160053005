import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import usePopper from '../../hooks/usePopper';
import logoHorizontal from '../../images/logos/logo-horizontal.png';
import SignoutButton from '../auth/SignoutButton';
import DrawerHeader from '../common/DrawerHeader';
import Signin from '../auth/Signin';
import Popper from '../common/Popper';
import Nav from './Nav';

const Drawer = ({ drawerWidth = 300 }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const { handleClick, setOpen, open, anchorEl, placement } = usePopper();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer =
    (drawerOpen: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event instanceof KeyboardEvent &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(drawerOpen);
    };
  return (
    <>
      <AppBar position="fixed" sx={{ displayPrint: 'none' }}>
        <Toolbar sx={{ gap: 1, lineHeight: 1 }}>
          {currentUser && (
            <IconButton
              color="secondary"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              edge="start"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
          <Link
            component="button"
            onClick={(e) => {
              navigate('/');
              toggleDrawer(false)(e);
            }}
            // sx={{ display: { xs: 'none', md: 'inline' } }}
          >
            <Box
              component="img"
              src={logoHorizontal}
              alt="logo"
              sx={{
                maxHeight: '50px',
                width: '100%',
                marginLeft: (theme) => theme.spacing(1),
                my: 'auto',
              }}
            />
          </Link>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              // '& :not(:last-child)': { mr: 1 },
            }}
          >
            {currentUser ? (
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div>
                  <IconButton
                    aria-label="user avatar"
                    onClick={handleClick('bottom-end')}
                    sx={{ p: 0 }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.secondary.main,
                      }}
                    >
                      {currentUser?.username.charAt(0).toUpperCase()}
                    </Avatar>
                  </IconButton>
                  <Popper open={open} anchorEl={anchorEl} placement={placement}>
                    <Stack alignItems="center" sx={{ mt: 2 }}>
                      <AccountCircleIcon fontSize="large" color="primary" />
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                      >
                        {currentUser?.username}
                      </Typography>
                      <SignoutButton
                        variant="contained"
                        callback={() => {
                          navigate('/');
                          setOpen(false);
                        }}
                      />
                    </Stack>
                  </Popper>
                </div>
              </ClickAwayListener>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setDialogOpen(true)}
                size={isSmallScreen ? 'small' : 'large'}
              >
                Log In
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <MuiDrawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          displayPrint: 'none',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <DrawerHeader>
          {/* <Link
            component="button"
            underline="none"
            variant="h6"
            color="primary"
            onClick={(e) => {
              navigate('/');
              toggleDrawer(false)(e);
            }}
            sx={{ fontWeight: 'bold', mx: 'auto', display: 'flex' }}
          >
            <Box
              component="img"
              src={logoGreen}
              alt="logo"
              sx={{
                height: '50px',
              }}
            />
          </Link> */}
          <IconButton onClick={toggleDrawer(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Nav handleDrawerClose={toggleDrawer(false)} />
      </MuiDrawer>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xs"
      >
        <Signin setDialogOpen={setDialogOpen} />
      </Dialog>
    </>
  );
};

export default Drawer;
