import { z } from 'zod';
import { DeliveryShiftSchedule } from '../../graphql/enums/DeliveryShiftSchedule';
import { PackagingCategory } from '../../graphql/enums/PackagingCategory';
import { RawMaterialCategory } from '../../graphql/enums/RawMaterialCategory';
import { SalesCategory } from '../../graphql/enums/SalesCategory';


export const schema = z.object({
  date: z.coerce.date(),
  deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
  outlet: z.object({ id: z.coerce.number() }).transform((val) => !val ? null : val),
  crtCmlItems: z.array(z.object({
    id: z.coerce.number().optional(),
    salesCategory: z.nativeEnum(SalesCategory),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  crtRawMaterialItems: z.array(z.object({
    id: z.coerce.number().optional(),
    rawMaterialProduct: z.object({ 
      id: z.coerce.number(),
      rawMaterial: z.object({ 
        id: z.coerce.number(),
        rawMaterialCategory: z.nativeEnum(RawMaterialCategory),
        name: z.string().trim().min(1),
      }).transform((val) => !val ? null : val),
      unit: z.object({ 
        id: z.coerce.number(),
        name: z.string().trim().min(1),
      }).transform((val) => !val ? null : val)
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
  crtPackagingItems: z.array(z.object({
    id: z.coerce.number().optional(),
    packagingProduct: z.object({ 
      id: z.coerce.number(),
      packaging: z.object({ 
        id: z.coerce.number(),
        packagingCategory: z.nativeEnum(PackagingCategory),
        name: z.string().trim().min(1),
      }).transform((val) => !val ? null : val),
      unit: z.object({ 
        id: z.coerce.number(),
        name: z.string().trim().min(1),
      }).transform((val) => !val ? null : val)
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number())
  })),
  crtBeverageItems: z.array(z.object({
    id: z.coerce.number().optional(),
    beverage: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    cupsSoldMedium: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    cupsSoldLarge: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    cupsSoldXl: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
  })),
})

export type FormData = z.infer<typeof schema>




