import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useReceivedSdsDeliveryReceiptQuery,
  useUpdateReceivedSdsDeliveryReceiptMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { dateTimeToString } from '../../utils/dateTimeFunctions';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import ReceivedSdsDeliveryReceiptForm from './ReceivedSdsDeliveryReceiptForm';
import { FormData, schema } from './utils';

const UpdateReceivedSdsDeliveryReceiptPage = ({
  title = `Update SDS Delivery Receipt`,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        receivedSdsDeliveryReceiptPackagingItems,
        receivedSdsDeliveryReceiptRawMaterialItems,
        sdsDeliveryReceipt,
        outlet,
        ...data
      }) => {
        await updateReceivedSdsDeliveryReceipt({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              sdsDeliveryReceipt: {
                connect: {
                  id: sdsDeliveryReceipt?.id,
                },
              },
              receivedSdsDeliveryReceiptRawMaterialItems: {
                update: receivedSdsDeliveryReceiptRawMaterialItems.map(
                  ({ id, rejectedQuantity, receivedQuantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ rejectedQuantity, receivedQuantity }),
                      },
                    };
                  }
                ),
              },
              receivedSdsDeliveryReceiptPackagingItems: {
                update: receivedSdsDeliveryReceiptPackagingItems.map(
                  ({ id, rejectedQuantity, receivedQuantity }) => {
                    return {
                      where: {
                        id,
                      },
                      data: {
                        ...prismaUpdate({ rejectedQuantity, receivedQuantity }),
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { receivedSdsDeliveryReceipt } = {}, loading } =
    useReceivedSdsDeliveryReceiptQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: { id },
      },
    });

  const [updateReceivedSdsDeliveryReceipt, { loading: mutationLoading }] =
    useUpdateReceivedSdsDeliveryReceiptMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (receivedSdsDeliveryReceipt) {
      const {
        receivedSdsDeliveryReceiptRawMaterialItems,
        receivedSdsDeliveryReceiptPackagingItems,

        ...data
      } = receivedSdsDeliveryReceipt;
      methods.reset({
        ...cleanPayload({
          ...data,
          outlet: data?.sdsDeliveryReceipt?.outlet,
          receivedSdsDeliveryReceiptRawMaterialItems:
            receivedSdsDeliveryReceiptRawMaterialItems.map(
              ({ sdsDeliveryReceiptRawMaterialItem, ...data }) => {
                return {
                  sdsDeliveryReceiptRawMaterialItem,
                  ...data,
                };
              }
            ),
          receivedSdsDeliveryReceiptPackagingItems:
            receivedSdsDeliveryReceiptPackagingItems.map(
              ({ sdsDeliveryReceiptPackagingItem, ...data }) => {
                return {
                  sdsDeliveryReceiptPackagingItem,
                  ...data,
                };
              }
            ),
        }),
      });
    }
  }, [receivedSdsDeliveryReceipt, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ReceivedSdsDeliveryReceiptForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateReceivedSdsDeliveryReceiptPage;
