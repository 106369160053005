import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateReceivedDeliveryReceiptMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useAuthContext } from '../../contexts/authContext';
import ReceivedDeliveryReceiptForm from './ReceivedDeliveryReceiptForm';
import { FormData, schema } from './utils';

const CreateReceivedDeliveryReceiptPage = ({
  title = `Create Received Delivery Receipt`,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({
          receivedDeliveryReceiptItems,
          deliveryReceipt,
          outlet,
          ...data
        }) =>
          await createReceivedDeliveryReceipt({
            variables: {
              data: {
                ...prismaCreate(data),
                deliveryReceipt: {
                  connect: {
                    id: deliveryReceipt?.id,
                  },
                },
                receivedBy: currentUser.name,
                receivedDeliveryReceiptItems: {
                  create: receivedDeliveryReceiptItems.map(
                    ({
                      deliveryReceiptItem,
                      ...receivedReceivedDeliveryReceiptItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...receivedReceivedDeliveryReceiptItem,
                        }),
                        deliveryReceiptItem: {
                          connect: {
                            id: deliveryReceiptItem?.id,
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createReceivedDeliveryReceipt, { loading: mutationLoading }] =
    useCreateReceivedDeliveryReceiptMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ReceivedDeliveryReceiptForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateReceivedDeliveryReceiptPage;
