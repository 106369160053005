import { ControllerFieldState, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form"
import { z } from "zod"
import { isFieldRequired } from "./zod"

export const textFieldHelperProps =
  <
    TFieldValues extends FieldValues = FieldValues, 
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >(
    field: ControllerRenderProps<TFieldValues, TName>, 
    fieldState: ControllerFieldState, 
    schema: z.ZodTypeAny,
    controllerName: string = field.name
  ) => {

  return {
    error:!!fieldState.error?.message,
    helperText:fieldState.error?.message,
    required: isFieldRequired(schema, controllerName),

  }
}