import { z } from 'zod';
import { CmlThrowAwayLocation } from '../../graphql/enums/CmlThrowAwayLocation';
import { DeliveryShiftSchedule } from '../../graphql/enums/DeliveryShiftSchedule';



export const schema = z.object({
  date: z.coerce.date(),
  controlNumber: z.string().optional(),
  createdBy: z.string().optional(),
  checkedBy: z.string().optional(),
  deliveryShiftSchedule: z.nativeEnum(DeliveryShiftSchedule),
  cmlThrowAwayLocation: z.nativeEnum(CmlThrowAwayLocation),
  cmlThrowAwayCmlItems: z.array(z.object({
    id: z.coerce.number().optional(),
    cmlProduct: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    checkedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()).nullish(),
  })),
  cmlThrowAwayRawMaterialItems: z.array(z.object({
    id: z.coerce.number().optional(),
    rawMaterial: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
      rawMaterialProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    checkedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()).nullish(),
  })),
  cmlThrowAwayPackagingItems: z.array(z.object({
    id: z.coerce.number().optional(),
    packaging: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
      packagingProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ 
      id: z.coerce.number(),
      name: z.string().trim().min(1),
    }).transform((val) => !val ? null : val),

    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()),
    checkedQuantity: z.preprocess((val) => val === "" ? null : Number(val), z.number()).nullish(),
  })),
})

export type FormData = z.infer<typeof schema>




