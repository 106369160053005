import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useUpdatePackagingMutation,
  usePackagingQuery,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import PackagingForm from './PackagingForm';
import { FormData, schema } from './utils';

const UpdatePackagingPage = ({ title = `Update Packaging` }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ packagingProducts, ...data }) => {
        const packagingProductsToDelete = getItemsForDelete(
          packaging?.packagingProducts,
          packagingProducts
        );
        const packagingProductsToUpsert = getItemsForUpsert(packagingProducts);
        await updatePackaging({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              packagingProducts: {
                delete: packagingProductsToDelete,
                upsert: packagingProductsToUpsert.map(
                  ({ id, ...packagingProduct }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(packagingProduct),
                        unit: { connect: { id: packagingProduct.unit.id } },
                      },
                      create: {
                        ...prismaCreate(packagingProduct),
                        unit: { connect: { id: packagingProduct.unit.id } },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const { data: { packaging } = {}, loading } = usePackagingQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updatePackaging, { loading: mutationLoading }] =
    useUpdatePackagingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (packaging) {
      methods.reset({ ...cleanPayload(packaging) });
    }
  }, [packaging, methods]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <PackagingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdatePackagingPage;
