import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  PackagingCategory,
  RawMaterialCategory,
  SalesCategory,
  SortOrder,
  Status,
  useBeveragesAllowedQuery,
  useCmlProductsAllowedQuery,
  useCreateCrtMutation,
  usePackagingProductsAllowedQuery,
  useRawMaterialProductsAllowedQuery,
} from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useEffect } from 'react';
import { cleanPayload } from '../../utils/apolloClient';
import Loading from '../common/Loading';
import CrtForm from './CrtForm';
import { FormData, schema } from './utils';

const CreateCrtPage = ({ title = `Create CRT Input` }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const { data: { cmlProducts } = {}, loading: cmlProductsLoading } =
    useCmlProductsAllowedQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          status: {
            equals: Status.Active,
          },
        },
        orderBy: [{ salesCategory: SortOrder.Asc }, { name: SortOrder.Asc }],
      },
    });

  const {
    data: { rawMaterialProducts } = {},
    loading: rawMaterialProductsLoading,
  } = useRawMaterialProductsAllowedQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        baseUnit: {
          equals: true,
        },
        rawMaterial: {
          is: {
            rawMaterialCategory: {
              in: [
                RawMaterialCategory['Drinks Sales Control'],
                RawMaterialCategory['Drink'],
                RawMaterialCategory['Raw Materials Control'],
              ],
            },
          },
        },
      },
      orderBy: [
        { rawMaterial: { rawMaterialCategory: SortOrder.Asc } },
        { rawMaterial: { name: SortOrder.Asc } },
      ],
    },
  });

  const {
    data: { packagingProducts } = {},
    loading: packagingProductsLoading,
  } = usePackagingProductsAllowedQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        baseUnit: {
          equals: true,
        },
        packaging: {
          is: {
            packagingCategory: {
              in: [
                PackagingCategory['Boxes'],
                PackagingCategory['Bags'],
                PackagingCategory['Cups'],
              ],
            },
          },
        },
      },
      orderBy: [
        { packaging: { packagingCategory: SortOrder.Asc } },
        { packaging: { name: SortOrder.Asc } },
      ],
    },
  });

  const { data: { beverages } = {}, loading: beveragesLoading } =
    useBeveragesAllowedQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          status: {
            equals: Status.Active,
          },
        },
        orderBy: [{ name: SortOrder.Asc }],
      },
    });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();

    methods.handleSubmit(
      async ({
        crtCmlItems,
        crtRawMaterialItems,
        crtPackagingItems,
        crtBeverageItems,
        outlet,
        ...data
      }) =>
        await createCrt({
          variables: {
            data: {
              ...prismaCreate(data),
              outlet: {
                connect: {
                  id: outlet?.id,
                },
              },
              crtCmlItems: {
                create: crtCmlItems.map(
                  ({ salesCategory, quantity, ...crtCmlItem }) => {
                    return {
                      ...prismaCreate({
                        ...crtCmlItem,
                        salesCategory,
                        quantity,
                      }),
                    };
                  }
                ),
              },
              crtRawMaterialItems: {
                create: crtRawMaterialItems.map(
                  ({ rawMaterialProduct, quantity, ...crtRawMaterialItem }) => {
                    return {
                      ...prismaCreate({
                        ...crtRawMaterialItem,
                        quantity,
                      }),
                      rawMaterialProduct: {
                        connect: {
                          id: rawMaterialProduct?.id,
                        },
                      },
                    };
                  }
                ),
              },
              crtPackagingItems: {
                create: crtPackagingItems.map(
                  ({ packagingProduct, quantity, ...crtPackagingItem }) => {
                    return {
                      ...prismaCreate({
                        ...crtPackagingItem,
                        quantity,
                      }),
                      packagingProduct: {
                        connect: {
                          id: packagingProduct?.id,
                        },
                      },
                    };
                  }
                ),
              },
              crtBeverageItems: {
                create: crtBeverageItems.map(
                  ({
                    beverage,
                    cupsSoldMedium,
                    cupsSoldLarge,
                    cupsSoldXl,
                    ...crtBeverageItem
                  }) => {
                    return {
                      ...prismaCreate({
                        ...crtBeverageItem,
                        cupsSoldMedium,
                        cupsSoldLarge,
                        cupsSoldXl,
                      }),
                      beverage: {
                        connect: {
                          id: beverage?.id,
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const [createCrt, { loading: mutationLoading }] = useCreateCrtMutation({
    onError: (error) =>
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' success!',
        severity: 'success',
      });
      navigate(location.pathname.replace('/create', ''));
    },
  });

  useEffect(() => {
    if (rawMaterialProducts && packagingProducts && cmlProducts && beverages) {
      methods.reset({
        ...cleanPayload({
          crtRawMaterialItems: rawMaterialProducts.map((rawMaterialProduct) => {
            return {
              rawMaterialProduct,
            };
          }),
          crtPackagingItems: packagingProducts.map((packagingProduct) => {
            return {
              packagingProduct,
            };
          }),
          crtCmlItems: Object.values(SalesCategory).map((salesCategory) => {
            return {
              salesCategory,
            };
          }),
          crtBeverageItems: beverages.map((beverage) => {
            return {
              beverage,
            };
          }),
        }),
      });
    }
  }, [beverages, cmlProducts, rawMaterialProducts, packagingProducts, methods]);

  if (
    rawMaterialProductsLoading ||
    packagingProductsLoading ||
    cmlProductsLoading ||
    beveragesLoading
  )
    return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <CrtForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateCrtPage;
