import React, { ReactNode } from 'react';
import {
  useCurrentUserQuery,
  CurrentUserQuery,
} from '../graphql/generated/types';

type CurrentUser = CurrentUserQuery['currentUser'] | undefined;
interface Props {
  children?: ReactNode;
}

interface ContextState {
  // set the type of state you want to handle with context e.g.
  currentUser: CurrentUser;
}

const AuthContext = React.createContext({} as ContextState);
AuthContext.displayName = 'AuthContext';

const AuthProvider = ({ children }: Props) => {
  const { data: { currentUser } = {}, loading } = useCurrentUserQuery({
    fetchPolicy: 'network-only',
  });
  if (loading) return null;
  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return React.useContext(AuthContext);
};
export { AuthProvider, useAuthContext };
