import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  Status,
  useCmlProductsAllowedQuery,
  useCreateProjectionMutation,
} from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';

import { useEffect } from 'react';
import { useAuthContext } from '../../contexts/authContext';
import { cleanPayload } from '../../utils/apolloClient';
import Loading from '../common/Loading';

import ProjectionForm from './ProjectionForm';
import { FormData, schema } from './utils';

const CreateProjectionPage = ({ title = `Create Projection` }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const { data: { cmlProducts } = {}, loading: cmlProductsLoading } =
    useCmlProductsAllowedQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          status: {
            equals: Status.Active,
          },
        },
        // orderBy: [{ salesCategory: SortOrder.Asc }, { name: SortOrder.Asc }],
      },
    });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (currentUser) {
      methods.handleSubmit(
        async ({ projectionItems, outlet, ...data }) =>
          await createProjection({
            variables: {
              data: {
                ...prismaCreate(data),
                controlNumber: '',
                outlet: {
                  connect: {
                    id: outlet?.id,
                  },
                },
                createdBy: currentUser.name,
                projectionItems: {
                  create: projectionItems.map(
                    ({
                      cmlProduct,
                      quantity,
                      ...projectionRawMaterialItem
                    }) => {
                      return {
                        ...prismaCreate({
                          ...projectionRawMaterialItem,
                          quantity,
                        }),
                        cmlProduct: {
                          connect: {
                            id: cmlProduct?.id,
                          },
                        },
                      };
                    }
                  ),
                },
              },
            },
          }),
        (error) => {
          handleSnackbarOpen({
            message: 'Form validation error!',
            severity: 'error',
          });
          console.log(error);
        }
      )(e);
    }
  };

  const [createProjection, { loading: mutationLoading }] =
    useCreateProjectionMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  useEffect(() => {
    if (cmlProducts) {
      methods.reset({
        ...cleanPayload({
          projectionItems: cmlProducts
            .sort((a, b) => {
              if (a.salesCategory < b.salesCategory) return -1;
              if (a.salesCategory > b.salesCategory) return 1;
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            })
            .map((cmlProduct) => {
              return {
                cmlProduct,
              };
            }),
        }),
      });
    }
  }, [cmlProducts, methods]);

  if (cmlProductsLoading) return <Loading />;
  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ProjectionForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateProjectionPage;
