import { z } from "zod";

//https://github.com/colinhacks/zod/discussions/2134
export const zodOptionalKeys = <T extends z.ZodTypeAny>(schema: T): string[] => {
	// make sure schema is not null or undefined
	if (schema === null || schema === undefined) return [];
	// check if schema is nullable or optional
	if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional) return zodOptionalKeys(schema.unwrap());
	// check if schema is an array
	if (schema instanceof z.ZodArray) return zodOptionalKeys(schema.element);
	// check if schema is an object
	if (schema instanceof z.ZodObject) {
		// get key/value pairs from schema
		const entries = Object.entries(schema.shape);
		// loop through key/value pairs
    
		return entries.flatMap(([key, value]) => {
    
			// get nested keys
			const nested = value instanceof z.ZodType ? zodOptionalKeys(value).map(subKey => `${key}.${subKey}`) : [];
      if (value instanceof z.ZodOptional) {
        return key
      } else {
        return nested
      }
     
		});
	}
	// return empty array
	return [];
};

export const isFieldRequired = (schema: z.ZodTypeAny , controllerName: string) => {
  const keys = zodOptionalKeys(schema)
  
  return !keys.includes(controllerName.replace(/[0-9]+/g, "").replace(/\.{2,}/g, "."))
}