import { z } from 'zod';






export const schema = z.object({
  rawMaterialProduct: z.object({ id: z.coerce.number() }).transform((val) => !val ? null : val),
  rawMaterialProductIngredients: z.array(z.object({
    id: z.number().optional(),
    rawMaterial: z.object({ 
      id: z.coerce.number(),
      rawMaterialProducts: z.array(z.object({
        unit: z.object({ 
          id: z.coerce.number(),
          name: z.string().trim().min(1),
        }).transform((val) => !val ? null : val),
      }))
    }).transform((val) => !val ? null : val),
    unit: z.object({ id: z.coerce.number() }).transform((val) => !val ? null : val),
    quantity: z.preprocess((val) => val === "" ? null : Number(val), z.number())
  }))
})

export type FormData = z.infer<typeof schema>




