import { zodResolver } from '@hookform/resolvers/zod';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  CurrentUserDocument,
  useSigninMutation,
} from '../../graphql/generated/types';
import logoVertical from '../../images/logos/logo-vertical.png';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { isFieldRequired } from '../../utils/zod';

interface Props {
  title?: string;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const schema = z.object({
  username: z.string().trim().min(1),
  password: z.string().trim().min(1),
});

export type FormData = z.infer<typeof schema>;

const Login = ({ setDialogOpen, title = 'Login' }: Props) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const { handleSnackbarOpen } = useSnackbarContext();

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    handleSubmit(
      async (data) =>
        await signin({
          variables: {
            ...data,
          },
        }),
      (error) => {
        handleSnackbarOpen({
          message: 'Form validate error! ',
          severity: 'error',
        });
        console.log(error);
      }
    )(e);
  };

  const [signin, { loading: mutationLoading }] = useSigninMutation({
    refetchQueries: [{ query: CurrentUserDocument }],
    awaitRefetchQueries: true,
    onError: (error) =>
      handleSnackbarOpen({
        message:
          title.charAt(0).toUpperCase() +
          title.slice(1).toLowerCase() +
          ' error! ' +
          error,
        severity: 'error',
      }),
    onCompleted: () => {
      setDialogOpen(false);
      navigate('/');
    },
  });
  return (
    <>
      <Paper>
        <Box
          component="form"
          onSubmit={onSubmit}
          sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
          noValidate
        >
          <Box
            component="img"
            src={logoVertical}
            alt="logo"
            sx={{
              height: '80px',
              width: '100%',
              mb: 2,
            }}
          />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    label="Username"
                    fullWidth
                    autoComplete="username"
                    {...textFieldHelperProps(field, fieldState, schema)}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <FormControl
                    fullWidth
                    error={!!fieldState.error?.message}
                    required={isFieldRequired(schema, field.name)}
                  >
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      label="Password*"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...field}
                    />
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
            disabled={mutationLoading}
          >
            Log In
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default Login;
